import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base';
import { Operator } from './Operator';
import { ProductionRequest } from './ProductionRequest';
import { User } from './User';
import { BillOfMaterialItem } from  './BillOfMaterialItem';
import { Step } from './Step';


export const REASON_END_OPTIONS = [
  'finished_order', 'clocked_out', 'idle'
]

export class OperatorTimeLog extends Model {
  static backendResourceName = 'operator_time_log'
  static omitFields = ['runtimeSeconds']


  @observable id = null
  @observable startAt = null
  @observable renewedAt = null
  @observable endAt = null
  @observable reasonEnd = ''

  // annotations
  @observable runtimeSeconds = null


  relations() {
    return {
      operator: Operator,
      productionRequest: ProductionRequest,
      manuallyAdjustedBy: User,
      task: BillOfMaterialItem,
      step: Step,
    }
  }

  casts() {
    return {
      startAt: Casts.datetime,
      renewedAt: Casts.datetime,
      endAt: Casts.datetime,
    }
  }

  toBackend(...args) {
    const res = super.toBackend(...args)

    if (res.reason_end === null) {
      res.reason_end = ''
    }

    return res
  }
}

export class OperatorTimeLogStore extends Store {
  static backendResourceName = 'operator_time_log'
  Model = OperatorTimeLog
}
