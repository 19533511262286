import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { Button } from 'semantic-ui-react'
import { TargetNumberInput } from '@code-yellow/spider'
import Decimal from 'decimal.js'
import { QUANTITY_DECIMAL_PLACES } from 'helpers/decimal';
import { isFeatureFlagEnabled } from 'helpers/featureFlags'


const QuantityFieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
`

const FittedTargetNumberInput = styled(TargetNumberInput)`
    input {
        min-width: max-content;
    }
    margin: 0.25rem !important;
`

@observer
export default class QuantityField extends Component {
    static propTypes = {
        quantity: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        suffix: PropTypes.string,
        maxQuantity: PropTypes.instanceOf(Decimal),
        onEnter: PropTypes.func,
        targetProps: PropTypes.any,
    }

    static defaultProps = {
        onEnter: () => { },
        maxQuantity: Infinity,
        targetProps: {},
    }

    render() {
        const { suffix, quantity, onChange, onEnter, maxQuantity, targetProps, ...props } = this.props

        return (
            <QuantityFieldContainer {...props}>
                <Button data-test-quantity-minus
                    type='button' // this is needed to avoid clicking the button on Enter
                    icon='minus'
                    onClick={() => {
                        let value = Decimal(quantity ?? 0)
                        if (value.sub(Decimal(1)).gt(Decimal(-1))) {
                            value--
                            onChange(value)
                        }
                    }}
                />
                <FittedTargetNumberInput allowDecimal fluid data-test-form-field-input-quantity
                    decimalLimit={QUANTITY_DECIMAL_PLACES}
                    value={quantity?.toString() ?? ''}
                    onChange={(value) => onChange(value)}
                    onKeyPress={onEnter()}
                    suffix={suffix}
                    {...targetProps}
                />
                <Button data-test-quantity-add
                    type='button' // this is needed to avoid clicking the button on Enter
                    icon='plus'
                    onClick={() => {
                        let value = Decimal(quantity ?? 0)
                        if (
                            value.plus(Decimal(1)).lte(maxQuantity) ||
                            isFeatureFlagEnabled('allow_receipt_of_more_than_ordered')
                        ) {
                            value++
                            onChange(value)
                        }
                    }}
                />
            </QuantityFieldContainer>
        )
    }
}
