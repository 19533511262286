export default {
  pageNotFoundMessage: 'Website niet gevonden, is de URL juist?',
  brandingMessage: 'by Code Yellow',
  flowmastersBranding: 'Ondersteund door FlowMasters',
  nav: {
    main: {
      operations: 'Activiteiten',
      production: 'Productie',
      warehouse: 'Magazijn',
      planning: 'Planning',
      personnel: 'Personeel',
      assets: 'Stamgegevens',
      demand: 'Vraag',
    },
    operations: {
      progress: 'Werkvoortgang',
      batches: 'Batch Geschiedenis',
      orderHistory: 'Bestellingen geschiedenis',
      workStationSessions: 'Werkpleksessies',
    },
    planning: {
      productionLines: 'Productieomgeving',
      productionRequests: 'Werkdruk',
      planner: 'Planner',
      rampUps: 'Scenariotool',
      workStations: 'Dagplanning',
      day: 'Dagoverzicht',
      nest: 'Nesting',
      capacity: 'Capaciteit',
      timeLogs: 'Tijd Logs',
    },
    warehouse: {
      stock: 'Voorraad',
      inbound: 'Ontvangstorders',
      purchaseOrderLines: 'Inkooporderregels',
      warehouses: 'Magazijnen',
      warehouseTransfer: 'Voorraadverplaatsingen',
    },
    personnel: {
      users: 'Gebruikers',
      operators: 'Operators',
      leaveCalendars: 'Verlofkalenders',
      leaveSlots: 'Verlofaanvragen',
    },
    assets: {
      processes: 'Processen',
      articleTypes: 'Artikeltypes',
      exactItems: 'Exact Artikelen',
      productionLines: 'Productielijnen',
      capabilities: 'Vaardigheden',
      dataSources: 'Databronnen',
      loadCarriers: 'Ladingdragers',
      processPhases: 'Procesfasen',
      globalValues: 'Instellingen',
      globalFiles: 'Bijzondere Bestanden',
      templates: 'Templates',
      integrations: 'Integraties',
      projects: 'Projecten',
      factories: 'Fabrieken',
      syncrequest: 'Synchronisatieverzoeken',
      metafields: 'Metavelden',
      classifications: 'Artikelgroepen',
      nests: 'Nesttypes',
      emailTemplates: 'Email sjablonen',
      packStations: 'Packstations',
      packStationBoxes: 'PackStation Boxes',
    },
    account: {
      clockInTime: 'Ingeklokt om {{clockInTime}}',
      notClockedIn: 'Niet ingeklokt',
      clockIn: 'Inklokken',
      clockOut: 'Uitklokken',
      profile: 'Profiel',
      logout: 'Uitloggen',
      changelog: 'Changelog',
      clockOutModal: {
        title: 'Je bent nog ingeklokt.',
        message: 'Wil je naast uitloggen ook uitklokken?',
      },
    },
    sales: {
      salesOrders: 'Verkooporders en Projecten',
    },
  },
  unit4requestoverview: {
    field: {
      id: { label: 'ID' },
      createdAt: { label: 'Aangemaakt Op' },
      message: { label: 'Inhoud' }

    }
  },
  syncrequestoverview: {
    field: {
      id: { label: 'ID' },
      order: { label: 'Order' },
      createdAt: { label: 'Aangemaakt Op' },
      performedAt: { label: 'Afgehandeld Op' },
      failedAt: { label: 'Gefaald op' },
      message: { label: 'Inhoud' },
      status: { label: 'Status' },
    }
  },
  integrations: {
    field: {
      syncRequest: { label: 'Synchronisatieverzoeken' },
      isOpen: { label: 'Alleen openstaande taken tonen?' },
      unit4: {
        message: { value: 'Verhoog voorraad voor product: {{productID}} met aantal: {{quantity}}' }
      },
      navision: {
        message: {
          value: 'Purchase order with id "{{orderID}}" can be marked as completed',
          buy: 'Purchase order with id "{{orderID}}" and article type "{{itemCode}}" with quantity "{{quantity}}" can be completed',
          sell: 'Sales order with id "{{orderID}}" and article type "{{itemCode}}" with quantity "{{quantity}}" can be marked as completed',
        }
      }
    },
    overview: {
      empty: 'Er zijn geen synchronisatieverzoeken die voldoen aan de criteria.'
    }

  },
  unit: {
    overview: {
      title: 'Eenheden'
    },
    field: {
      name: {
        label: 'Naam'
      }
    }
  },
  plc: {
    field: {
      name: { label: 'Naam' },
      type: { label: 'Type' },
    },
    overview: {
      title: 'Machineoverzicht',
      addButton: 'Machine'
    }
  },
  profile: {
    title: 'Profiel',
    changePassword: {
      label: 'Wachtwoord Veranderen',
      passwordOld: 'Huidig Wachtwoord',
      passwordNew: 'Nieuw Wachtwoord',
    },
  },
  user: {
    overview: {
      title: 'Gebruikers',
      empty: 'Er zijn geen gebruikers die voldoen aan uw zoekopdracht.',
      addButton: 'Gebruiker',
      convertToOperator: 'Converteren naar operator',
    },
    create: {
      title: '{{fullName}} Aanmaken',
    },
    edit: {
      title: '{{fullName}} Aanpassen',
      saveBeforeLeaveSlot: 'Je moet de gebruiker eerst opslaan voordat je verlof toe kunt voegen.',
      tab: {
        info: 'Gegevens',
        availability: 'Beschikbaarheid',
        leaveRequestsHistory: 'Verlof geschiedenis',
      },
    },
    field: {
      leaveBalances: { label: 'Verlofoverzicht' },
      avatar: { label: 'Profielfoto' },
      fullName: { label: 'Naam', unknown: 'Gebruiker' },
      firstName: { label: 'Voornaam' },
      lastName: { label: 'Achternaam' },
      email: { label: 'Email' },
      password: { label: 'Wachtwoord' },
      language: { label: 'Taal' },
      dateJoined: { label: 'Toegevoegd Op' },
      manager: { label: 'Manager' },
      badgeId: { label: 'Badge ID', help: 'Als je dit invult, dan kan deze gebruiker ook als operator inloggen met de ingevulde Badge ID.' },
      clockInRoundOff: { label: 'Inklok afronding (in minuten)' },
      clockOutRoundOff: { label: 'Uitklok afronding (in minuten)' },
      groups: {
        label: 'Groepen',
        value: {
          superuser: 'Supergebruiker',
          admin: 'Admin',
          manager: 'Manager',
          planner: 'Planner',
          work_station: 'Werkplek',
          hr: 'HR',
        },
        empty: 'Geen groepen toegekend.',
      },
      availability: { label: 'Beschikbaarheid' },
      workSchedules: { label: 'Roosters' },
      leaveCalendars: { label: 'Verlofkalenders' },
      extraLeaveBalances: {
        label: 'Resterend saldo',
        empty: 'Er is geen resterend saldo ingevoerd.',
      },
      extraTimeBalances: {
        label: 'Resterende tijdsbalans',
        empty: 'Geen resterend tijdsaldo toegevoegd.',
      },
      isLoggedInAsOperator: { label: 'Is Operator' },
      editOpenProductionOrder: {
        label: 'Kan Open Productieorder Aanpassen',
        value: {
          all: 'Allemaal',
          some: 'Artikeltype Specifiek',
          none: 'Geen',
        },
      },
      releaseProductionOrder: {
        label: 'Kan Open Productieorder Vrijgeven',
        value: {
          all: 'Allemaal',
          some: 'Artikeltype Specifiek',
          none: 'Geen',
        },
      },
      editReleasedProductionOrder: {
        label: 'Kan Vrijgegeven Productieorder Aanpassen',
        value: {
          all: 'Allemaal',
          some: 'Artikeltype Specifiek',
          none: 'Geen',
        },
      },
      editPerformanceDetails: {
        label: 'Kan prestatiedetails bewerken',
        value: {
          all: 'Allemaal',
          some: 'Artikeltype Specifiek',
          none: 'Geen',
        },
      },
      showAddOperatorAllocationInCapacityView: {
        label: 'Toon toevoegen OperatorAllocation in Capaciteit Overzicht',
        help: 'Als aangevinkt, dan wordt deze operator getoond in het Capaciteit Overzicht.',
      },
    },
    login: {
      title: 'Inloggen',
      reloginMessage: 'U bent uitgelogd. Log opnieuw in.',
      forgotPasswordLink: 'Wachtwoord vergeten?',
      errors: {
        invalidCredentials: 'Email of wachtwoord is niet correct',
        unknown: 'Onbekende fout, status code: {{status}}.',
      },
      loginButton: 'Inloggen',
      workStationStartButton: 'Start Werkplek',
      operatorStartButton: 'Inloggen als Operator',
      or: 'Of',
      backButton: 'Ga Terug',
    },
    passwordForgot: {
      title: 'Wachtwoord Vergeten',
      requestButton: 'Verstuur Email',
      requestedEmailText: 'Er is een email met een wachtwoord reset code verzonden naar uw email adres.',
    },
    resetPassword: {
      title: 'Wachtwoord Reset',
      resetButton: 'Reset',
      saveSuccess: 'Uw wachtwoord is succesvol gereset.',
    },
    activate: {
      title: 'Account Activeren',
      intro: 'Welkom bij Tracy! U hoeft alleen nog een wachtwoord in te stellen om uw account te activeren.',
      activateButton: 'Activeer Account',
      saveSuccess: 'Uw account is succesvol geactiveerd.',
    },
    changePassword: {
      title: 'Wachtwoord Wijzigen',
      field: {
        passwordOld: {
          label: 'Huidig Wachtwoord',
        },
        passwordNew: {
          label: 'Nieuw Wachtwoord',
        },
      },
      saveButton: 'Opslaan',
    },
    account: {
      title: 'Profiel',
      logoutButton: 'Uitloggen',
      info: {
        title: 'Gegevens',
      },
      password: {
        title: 'Wachtwoord Veranderen',
      },
      logout: {
        title: 'Uitloggen',
        confirm: 'Weet u zeker dat u wilt uitloggen?',
      },
    },
  },
  exactItem: {
    overview: {
      title: 'Artikelen',
      empty: 'Er zijn geen artikelen die voldoen aan uw zoekopdracht.',
      viewLink: '(bekijken)',
      actions: {
        label: 'Koppelingen Aanpassen ({{count}})',
        action: {
          linkArticleTypes_one: 'Koppel Artikeltype ({{count}})',
          linkArticleTypes_other: 'Koppel Artikeltypes ({{count}})',
          unlinkArticleTypes_one: 'Ontkoppel Artikeltype ({{count}})',
          unlinkArticleTypes_other: 'Ontkoppel Artikeltypes ({{count}})',
        },
      },
    },
    edit: {
      title: 'Artikel Aanpassen',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      classification: { label: 'Artikelgroep' },
      isMakeItem: { label: 'Maak' },
      isBuyItem: { label: 'Koop' },
      name: { label: 'Naam' },
      articleType: { label: 'Artikeltype' },
      componentType: { label: 'Componenttype' },
    },
  },
  operator: {
    home: {
      welcome: 'Welkom, {{firstName}}!',
      clockInTime: 'Je bent ingeklokt om {{clockInTime}}.',
      notClockedIn: 'Je bent op het moment niet ingeklokt.',
      availability: 'Beschikbaarheid',
      leaveRequestsHistory: 'Geschiedenis van verlofaanvragen',
      scheduled: {
        notScheduled: 'Je bent vandaag niet ingepland op een werkstation.',
        title: 'Vandaag ben je ingepland op de volgende werkstations:',
        times: '{{startAt}} - {{endAt}}',
      },
    },
    availability: {
      title: 'Beschikbaarheid',
    },
    logoutModal: {
      title: 'Uitloggen',
      content: 'Weet je zeker dat je wilt uitloggen?',
      logoutButton: 'Uitloggen',
    },
    start: {
      title: 'Inloggen als Operator',
      startButton: 'Inloggen',
    },
    overview: {
      title: 'Operators',
      empty: 'Er zijn geen operators die voldoen aan uw zoekopdracht.',
      addButton: 'Operator',
    },
    create: {
      title: '{{fullName}} Aanmaken',
    },
    edit: {
      title: '{{fullName}} Aanpassen',
      saveBeforeLeaveSlot: 'Je moet de operator eerst opslaan voordat je verlof toe kan voegen.',
      tab: {
        info: 'Gegevens',
        capabilities: 'Vaardigheden',
        availability: 'Beschikbaarheid',
        leaveRequestsHistory: 'Verlof geschiedenis',
      },
      workScheduleDescription: 'Rooster vanaf {{startDate}}',
    },
    field: {
      leaveBalances: { label: 'Verlofoverzicht' },
      avatar: { label: 'Profielfoto' },
      fullName: { label: 'Naam', unknown: 'Operator' },
      email: { label: 'Email' },
      firstName: { label: 'Voornaam' },
      lastName: { label: 'Achternaam' },
      operatorCapabilities: {
        label: 'Vaardigheden',
        empty: 'Geen vaardigheden toegekend',
        none: 'Er zijn nog geen vaardigheden geconfigureerd in het systeem.',
      },
      createdAt: { label: 'Toegevoegd Op' },
      badgeId: {
        label: 'Badge ID',
        badgeIdPlaceHolder: 'Voer badge ID in ...',
      },
      workSchedules: {
        label: 'Roosters',
        empty: 'Er zijn nog geen roosters toegevoegd.',
      },
      availability: { label: 'Beschikbaarheid' },
      leaveCalendars: { label: 'Verlofkalenders' },
      manager: { label: 'Manager' },
      hasPendingLeave: { label: 'Openstaande Verlofaanvragen' },
      extraLeaveBalances: {
        label: 'Resterend saldo',
        empty: 'Er is geen resterend saldo toegevoegd.',
      },
      extraTimeBalances: {
        label: 'Resterende tijdsbalans',
        empty: 'Geen resterend tijdsaldo toegevoegd.',
      },
      showInCapacityView: { label: 'Toon in capacity pagina?' },
      deleted: { label: 'Verwijderd' },
    },
  },
  operatorTimeLog: {
    field: {
      startAt: { label: 'Gestart om' },
      renewedAt: { label: 'Vernieuwd om' },
      endAt: { label: 'Gestopt om' },
      operator: { label: 'Operator' },
      reasonEnd: {
        label: 'End reason',
        options: {
          idle: 'Afwezig',
          finished_order: 'Order Afgewerkt',
          clocked_out: 'Gestopt',
        },
      },
      hasEnded: { label: 'Gestopt' },
      runtimeSeconds: { label: 'Werktijd' },
      manuallyAdjusted: { label: 'Handmatig Aangepast' },
      productionRequest: {
        orders: 'Orders',
        label: 'Werk Order ID',
        labelOrder: 'Productie Order ID',
        labelErp: 'ERP ID',
      },
      task: { label: 'Taak' },
    },
    overview: {
      title: 'Tijd Logs',
      empty: 'Geen tijd logs gevonden',
      stat: {
        time_minutes_logged_per_operator: 'Totale Tijd per Operator',
        total_started_per_operator: 'Logs per Operator',
        total_ended: 'Logs zijn Beëindigd',
        total_time_minutes: 'Totale Tijd',
      },
    },
    edit: {
      title: 'Bewerk tijd log',
      lastEdited: 'Laatst bewerkt door: {{editor}}',
    },
  },
  articleTypeWarehouse: {
    overview: {
      empty: 'Geen voorraad gevonden.',
    },
    field: {
      stock: { label: 'Voorraad', filterDifferenceWithErp: 'Verschil met ERP' },
      erpStock: { label: 'ERP', },
    },
    articleType: {
      id: 'ID',
      code: 'Code',
      classification: 'Groep',
      name: 'Naam',
    },
    warehouse: {
      name: 'Magazijn',
    },
    storageLocations: {
      field: {
        id: { label: 'ID' },
        lines: { label: 'Opslaglocaties' },
        code: { label: 'Code' },
        default: { label: 'Standaard' },
        name: { label: 'Naam' },
        stock: { label: 'Voorraad' },
        deleted: { label: 'Verwijderd?' },
        batchStock: { label: 'Batchvoorraad' },
        usage: { label: 'Capaciteitsgebruik' },
      },
      noStorageLocation: 'Geen opslaglocatie gespecificeerd.',
      noArticleTypeStorageLocation: 'Geen opslaglocaties om weer te geven.',
      noBatch: 'Er zijn geen batches om weer te geven.',
      erpDifference: {
        title: 'ERP-verschil',
        explanation: 'Verschil tussen Tracy en ERP voorraad. Negatieve nummers betekenen dat de ERP minder voorraad heeft.',
      },
    },
  },
  salesOrder: {
    basicInfo: 'Details',
    overview: {
      empty: 'Er zijn geen Verkooporders die voldoen aan uw zoekopdracht.',
      title: 'Verkooporders',
      responsibilityModal: {
        title: {
          edit: 'Verantwoordelijkheid Aanpassen',
          add: 'Verantwoordelijkheid Aanmaken',
        },
      },
      allocated: {
        label: 'Gealloceerd',
        value: 'Gealloceerd: {{count}}/{{total}}',
      },
      ready: {
        label: 'Gealloceerde Order(s)',
        value: 'Gealloceerde Order(s): {{count}}/{{total}}',
      },
      currentStock: {
        label: 'Huidige Voorraad',
        value: 'Huidige Voorraad: {{count}}/{{total}}',
      },
      planned: {
        label: 'Gepland',
        value: 'Gepland: {{count}}/{{total}}',
      },
      picked: {
        label: 'Verzameld',
        value: 'Verzameld: {{count}}/{{total}}',
      },
      shipped: {
        label: 'Verzonden',
        value: 'Verzonden: {{count}}/{{total}}',
        noCarrierInformation: 'Geen verzendinformatie beschikbaar',
        reprintAll: 'Print alle shipping labels opnieuw',
        reprint: 'Print shipping label opnieuw',
      },
      delivered: {
        label: 'ERP Afgeleverd',
        value: 'Afgeleverd: {{status}}',
      },
      invoiced: {
        label: 'ERP Gefactureerd',
        value: 'Gefactureerd: {{status}}',
      },
      exactReturned: {
        label: 'Teruggestuurd',
        value: 'Teruggestuurd: {{count}}/{{total}}',
      },
      batchTable: {
        hidden_one: '{{count}} regel verborgen',
        hidden_other: '{{count}} regels verborgen',
      },
      showMore_one: 'Laat meer zien ({{count}} regel)',
      showMore_other: 'Laat meer zien ({{count}} regels)',
      showLess: 'Laat minder zien',
      quantities: {
        warehouse: '# magazijn',
        projected: '# geprojecteerd',
        planned: '# gepland',
        required: '# nodig',
        allocated: '# gealloceerd',
        expected: '# verwacht',
      },
      amountDelivered: 'Bedrag geleverd',
      autoAllocateButton: 'Alloceer uit Huidige Voorraad',
      autoUnallocateButton: 'Dealloceer uit Huidige Voorraad',
      unallocatedDeficit: {
        message: 'Er is momenteel minder voorraad dan gealloceerd voor deze artikeltype magazijn combinatie. Een deel van de huidige stock moet gedealloceerd worden om verder te gaan met de betrokken orders.',
      },
      allocateAll: 'Alloceer alle verkooporderregels',
      createPickOrders: 'Maak pickorders',
      syncErrorInfo: 'Er is een sync error voor een van de verkooporderregels',
    },
    edit: {
      title: 'Verkooporder Aanpassen',
      allocateButton: 'Alloceren',
      stage: {
        progress: { label: 'Progressie' },
        general: { label: 'Algemeen' },
        customer: { label: 'Klant' },
        dates: { label: 'Data' },
        salesOrderLines: { label: 'Verkooporderregels' },
        pickOrders: {
          label: 'Verzendorders',
          addToExisting: 'Voeg toe aan open verzendorder',
          createNew: 'Maak nieuwe verzendorder',
        },
        deliveryAddress: { label: 'Afleveradres' },
        invoiceAddress: { label: 'Factuuradres' },
        remarks: { label: 'Interne Notities' },
        responsibilities: { label: 'Verantwoordelijkheden' },
      },
      allocateModal: {
        title: 'Alloceer {{articleType}} ({{allocated}}/{{required}}) ',
        allocate: 'Alloceer',
        source: {
          quantity: 'Aantal',
          unallocated: 'Ongealloceerd',
          allocated: 'Al Gealloceerd',
          date: 'Verwachtingsdatum',
          quantityStatus: {
            label: 'Status',
            value: {
              open: 'Open',
              in_progress: 'In Behandeling',
              done: 'Klaar',
            },
          },
          hasAvailable: { label: 'Beschikbaar' },
          hasAllocated: { label: 'Gealloceerd' },
        },
      },
      allocatedFromStock: '{{count}} uit stock gealloceerd.',
      allocatedFromStockAssembly: '{{count}} onderdelen uit stock gealloceerd.',
    },
    confirmDelete: 'Weet u zeker dat u deze Verkooporder wil verwijderen?',
    field: {
      id: { label: 'ID' },
      erpId: { label: 'ERP ID' },
      status: {
        label: 'Status',
        value: {
          no_stock: 'Open',
          open: 'Open',
          partial: 'Gedeeltelijk',
          complete: 'Gereed',
          canceled: 'Geannuleerd',
          null: 'Status niet beschikbaar',
        },
      },
      information: { label: 'Informatie' },
      customer: { label: 'Klant' },
      reference: { label: 'Referentie' },
      orderNumberErp: { label: 'ERP Nummer' },
      orderDate: { label: 'Orderdatum' },
      deliveryDate: { label: 'Leverdatum' },
      deliveryAddress: { label: 'Leveradres' },
      remarks: { label: 'Opmerkingen' },
      creatorFullName: { label: 'Auteur' },
      description: { label: 'Omschrijving' },
      outShipments: {
        label: 'Verzamelorder',
        empty: 'Nog geen Verzamelorders toegevoegd.',
        value: 'Verzamelorder {{id}}',
      },
      lines: { label: 'Verkooporderregels' },
      quantity: { label: 'Aantal' },
      exactSalesOrder: { label: 'Verkooporder' },
      warehouse: { label: 'Magazijn' },
      allocationStatus: {
        label: 'Status',
        value: {
          unallocated: 'Ongealloceerd (volledig of deels)',
          not_ready: 'Volledig gealloceerd maar nog niet klaar',
          not_shipped: 'Volledig klaar maar nog niet verzonden',
          all_shipped: 'Volledig verzonden',
        },
      },
      progressStatus: {
        values: {
          no_stock: 'Geen voorraad {{statusHead}}',
          partial: 'Gedeeltelijke voorraad {{statusHead}}',
          complete: 'Volledige voorraad {{statusHead}}',
        },
        stockStatus: {
          label: 'Voorraadstatus',
        },
        stockStatusAllocation: {
          label: 'Toewijzing Voorraadstatus',
        },
        allocationOrderStatus: {
          label: 'Toewijzingorder Voorraadstatus',
        },
        plannedStatus: {
          label: 'Gepland Status',
        },
        pickedStatus: {
          label: 'Verzameld Status',
        },
        shippedStatus: {
          label: 'Verzonden Status',
        },
        deliveryStatus: {
          label: 'Afgeleverd Status',
        },
        invoiceStatus: {
          label: 'Gefactureerd Status',
        },
      },

      priority: {
        label: 'Prioriteit',
        value: {
          high: '1 - Hoog',
          medium: '2 - Gemiddeld',
          low: '3 - Laag',
        },
      },
      responsibilities: { empty: 'Er zijn nog geen verantwoordelijkheden toegevoegd.' },
      shippingMethod: { label: 'Verzendmethode' },
      mostRecentlyPickedDate: { label: 'Verzamel Datum' },
      articleChangeWarning: { label: 'Let op! De artikelen in de verkooporderregel en de pickorderregel zijn verschillend!' }
    },
    filters: {
      todo: 'Te doen',
      inProgress: 'Bezig',
      complete: 'Klaar',
      overdueReceipts: 'Te laat (zonder werkorders)',
      overdueRequests: 'Te laat (met werkorders)',
      receiptsThisWeek: 'Gepland deze week',
      futureReceipts: 'Gepland toekomst',
      deleted: 'Verwijderd',
    },
  },
  salesOrderLine: {
    addToOutShipmentButton: 'Toevoegen',
    consumedLine: 'Geen beschikbare hoeveelheid meer voor de geselecteerde verkooporderregel(s).',
    field: {
      id: { label: 'ID' },
      number: { label: 'Regel' },
      project: { label: 'Project' },
      productionDueDate: { label: 'Productie Einddatum' },
      deliveryDate: { label: 'Leverdatum' },
      quantity: { label: 'Aantal' },
      _toReceive: { label: 'Te ontvangen' },
      unitPrice: { label: 'Stukprijs' },
      netPrice: { label: 'Nettoprijs' },
      code: { label: 'Artikelcode' },
      salesOrder: { label: 'Verkooporder' },
      erpLines: { label: 'ERP Geleverd' },
      description: { label: 'Beschrijving' },
      allocated: { label: 'Gealloceerd' },
      deliveryStatus: { label: 'Leverstatus' },
    },
  },
  exactPurchaseOrder: {
    field: {
      id: { label: 'ID' },
      number: { label: 'Nummer' },
      status: {
        label: 'Status',
        value: {
          10: 'Open',
          20: 'Gedeeltelijk',
          30: 'Gereed',
          40: 'Afgebroken',
        },
      },
    },
  },
  purchaseOrder: {
    overview: {
      empty: 'Er zijn geen Inkooporders die voldoen aan uw zoekopdracht.',
      erpNumber: 'Inkooporder Nummer',
    },
    filters: {
      status: 'Status',
      requestStatus: 'Werkorderstatus',
      ordersDueAndExpected: 'Orders te laat & verwacht',
      number: 'Nummer',
      todo: 'Te doen',
      inProgress: 'Bezig',
      complete: 'Klaar',
      overdueReceipts: 'Te laat (zonder werkorders)',
      overdueRequests: 'Te laat (met werkorders)',
      receiptsThisWeek: 'Gepland deze week',
      futureReceipts: 'Gepland toekomst',
      deleted: 'Verwijderd',
    },
    confirmDelete: 'Weet u zeker dat u deze Inkooporder wil verwijderen?',
    field: {
      notes: { label: 'Notities' },
      id: { label: 'Inkooporder ID' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          partial: 'Gedeeltelijk',
          complete: 'Gereed',
          canceled: 'Geannuleerd',
        },
      },
      source: { label: 'Source' },
      supplier: { label: 'Leverancier' },
      quantity: { label: 'Aantal' },
      reference: { label: 'Referentie' },
      orderNumberErp: { label: 'ERP Nummer' },
      orderDate: { label: 'Orderdatum' },
      receiptDate: { label: 'Ontvangstdatum', error: 'Ontvangstdatum mag niet leeg zijn.' },
      trackingCode: { label: 'Tracking Code' },
      licensePlate: { label: 'Kenteken' },
      lines: { label: 'Inkooporderregels' },
      inShipments: {
        label: 'Ontvangstorder',
        empty: 'Nog geen Ontvangstorders toegevoegd.',
        value: 'Ontvangstorder {{id}}',
      },
      exactPurchaseOrder: { label: 'Inkooporder' },
      warehouse: { label: 'Magazijn' },
      linkedSalesOrder: { label: 'Gekoppelde Verkooporder ERP' },
      shippingMethod: { label: 'Verzendmethode' },
      linkedProductionOrder: { label: 'Gekoppelde productieorder ERP' },
    },
  },
  purchaseOrderLine: {
    overview: {
      empty: 'Er zijn geen inkooporderregels gevonden.',
      addInShipments: 'Ontvangstbestellingen toevoegen',
    },
    noBatchTypeBuy: 'Geen proces gedefinieerd',
    addToInShipmentButton: 'Toevoegen',
    consumedLine: 'Geen beschikbare hoeveelheid meer voor de geselecteerde inkooporderregel(s).',
    field: {
      id: { label: 'ID' },
      number: { label: 'Regel' },
      productionRequest: { label: 'Werkorder' },
      project: { label: 'Project' },
      receiptDate: { label: 'Ontvangstdatum' },
      quantity: { label: 'Aantal' },
      code: { label: 'Artikel Code' },
      _toReceive: { label: 'Te ontvangen' },
      unitPrice: { label: 'Stuk Prijs' },
      netPrice: { label: 'Netto Prijs' },
      purchaseOrder: { label: 'Inkooporder' },
      purchaseUnit: { label: 'Bestelgrootte' },
      erpLines: { label: 'ERP Ontvangen' },
      description: { label: 'Description' },
      allocatedTo: { label: 'Toegewezen' },
      linkedTo: { label: 'Verbonden' },
      notes: { label: 'Notities' },
      line: {
        label: 'Regel',
        tooltip: 'Verbonden Inkooporder Regel'
      },
    },
  },
  inShipment: {
    disabledDelete: 'Kan Ontvangstorder niet verwijderen omdat er actieve werkorders zijn',
    field: {
      id: { label: 'ID' },
      receivedAt: { label: 'Ontvangstdatum' },
      trackingCode: { label: 'Tracking Code' },
      licensePlate: { label: 'Kenteken' },
      shipperName: { label: 'Chauffeur' },
    },
    edit: {
      title: 'Ontvangstorder Bewerken',
    },
    missingReceiveProcessCreate: 'Missend Ontvangstproces aanmaken',
  },
  inShipmentLine: {
    disabledDelete: 'Kan regel niet verwijderen omdat werkorder actief of afgerond is',
    field: {
      id: { label: 'ID' },
      inShipment: { label: 'Ontvangst ID' },
      autoPerformedProductionRequest: { info: 'Deze regel is automatisch toegevoegd omdat er meer artikelen ontvangen zijn dan aangegeven op de Inkooporder.' },
    },
    create: {
      title: 'Ontvangstorderregel Toevoegen',
    },
    edit: {
      title: 'Ontvangstorderregel Bewerken',
    },
    missingBuyProcessWarning: 'Geen Ontvangstproces gevonden voor dit artikeltype.',
    missingBuyProcessCreate: 'Missend Ontvangstproces aanmaken',
  },
  outShipment: {
    disabledDelete: 'Kan Verzamelorder niet verwijderen omdat er actieve werkorders zijn',
    autoMoveStartAt: 'De startdatum van de hoofd verzamelorder is veranderd omdat je de startdatum van een van de verzamelorders lijnen hebt aangepast.',
    field: {
      id: { label: 'ID' },
      shipperPickupDate: { label: 'Ophaaldatum' },
      shipperName: { label: 'Chauffeur' },
      shipperLicensePlate: { label: 'Kenteken' },
      freightType: { label: 'Verzendsoort' },
      freightCompany: { label: 'Transporteur' },
      freightInfo: { label: 'Ladinginfo' },
      outShipmentTemplate: { label: 'Verzenddemplate' },
      packStation: { label: 'Pack Station' },
    },
    missingPickProcessCreate: 'Missend Verzamelproces aanmaken',
  },
  outShipmentLine: {
    disabledDelete: 'Kan regel niet verwijderen omdat werkorder actief of afgerond is',
    field: {
      id: { label: 'ID' },
      outShipment: { label: 'Verzamel ID' },
    },
    create: {
      title: 'Verzamelorder Toevoegen',
    },
    edit: {
      title: 'Verzamelorder Bewerken',
    },
    missingSellProcessWarning: 'Geen Verzendproces gevonden voor dit artikeltype.',
    missingSellProcessCreate: 'Missend Verzendproces aanmaken',
  },
  workSchedule: {
    field: {
      employer: { label: 'Werkgever', placeholder: 'Begin met typen om werkgevers te zoeken of toe te voegen' },
      startDate: { label: 'Ingangsdatum' },
      workSlots: {
        label: 'Werktijden',
        empty: 'Er zijn nog geen werktijden toegevoegd.',
      },
      allowedLegalLeaveMinutes: { label: 'Wettelijke Vakantie' },
      allowedExtraLeaveMinutes: { label: 'Bovenwettelijke Vakantie' },
      allowedRwhMinutes: { label: 'ADV' },
    },
  },
  capability: {
    overview: {
      title: 'Vaardigheden',
      empty: 'Er zijn geen vaardigheden die voldoen aan uw zoekopdracht.',
      addButton: 'Vaardigheid',
    },
    create: {
      title: 'Vaardigheid Aanmaken',
    },
    edit: {
      title: 'Vaardigheid Aanpassen',
    },
    field: {
      name: { label: 'Naam' },
      operatorCapabilities: {
        label: 'Operators',
        none: 'Er zijn nog geen operators geconfigureerd in het systeem.',
      },
    },
  },
  processPhase: {
    overview: {
      title: 'Procesfases',
      empty: 'Er zijn geen Procesfases die voldoen aan uw zoekopdracht.',
      addButton: 'Procesfase',
    },
    create: {
      title: 'Procesfase Aanmaken',
    },
    edit: {
      title: 'Procesfase Aanpassen',
    },
    field: {
      icon: { label: 'Icoon' },
      name: { label: 'Naam' },
    },
  },
  batchType: {
    overview: {
      title: 'Processen',
      empty: 'Geen processen gevonden.',
    },
    create: {
      title: 'Proces Aanmaken',
    },
    onTheFly: {
      title: 'Flexibele Registratieinstellingen',
      description: 'Door het instellen van \'Flexibele Registratie\' kan Tracy automatisch een batch aanmaken op het moment dat een serienummer gescand wordt dat nog niet bekend is. Hierbij wordt wel gecontroleerd of het ingevoerde serienummer aan het format voldoet.',
    },
    edit: {
      title: 'Proces Aanpassen',
      bulkTemplateButton: 'Template Bulk Toevoegen',
      bulkTemplateModal: {
        title: 'Template aan artikelen toevoegen',
        selectButton_one: 'Voeg aan {{count}} artikel toe',
        selectButton_other: 'Voeg aan {{count}} artikelen toe',
        selectAllButton: 'Voeg aan alle {{count}} artikelen toe',
      },
      bulkTemplateProgressModal: {
        title: 'Template Bulk Toevoegen Voortgang',
        progress: '{{count}} van {{total}} toegevoegd.',
        timeRemaining: '{{time}} te gaan.',
      },
      finalizeProgressModal: {
        title: 'Voortgang processjabloon afronden',
      },
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Omschrijving' },
      quantity: { label: 'Batchgrootte' },
      name: { label: 'Batchgrootte' },
      articleType: { label: 'Artikeltype' },
      onTheFlySerialNumberFormat: { label: 'Flexibele Registratieserienummerformaat' },
      type: {
        label: 'Type',
        value: {
          make: 'Maak',
          buy: 'Ontvangen',
          sell: 'Verzenden',
          transfer_line: 'Voorraadverplaatsingsregel',
          component: 'Component',
          subassembly: 'Subassemblage',
          stock_count: 'Voorraadtelling',
          stock_count_template: 'Template Voorraadtelling',
          transfer: 'Voorraadverplaatsing',
          on_the_fly: 'Flexibele Registratie',
          pick_order: 'Verzamelorder',
          receive_order: 'Ontvangstorder',
          make_template: 'Template Artikel Maken',
          buy_template: 'Template Artikel Ontvangen',
          sell_template: 'Template Artikel Verzenden',
          transfer_line_template: 'Template Voorraadverplaatsingsregel',
          on_the_fly_template: 'Template Flexibele Registratie',
        },
      },
      serialNumberFormat: { label: 'Serienummerformaat' },
      default: {
        label: 'Standaard',
        help: 'Als de configuratie `autocreate_receive_orders` / `autocreate_pick_orders` aan staat, dan zal de standaard process geselecteerd worden wanneer een `receive order` of een `pick order` automatisch wordt aangemaakt.',
      },
      source: { label: 'Template' },
      targets: {
        label: 'Artikeltypes',
        value_one: 'Gebruikt in {{count}} artikeltype',
        value_other: 'Gebruikt in {{count}} artikeltypes'
      },
      onTheFlyType: {
        label: 'Flexibele Registratiebatch Grootte',
        info: 'Deze instelling bepaalt welke grootte een batch krijgt als deze tussentijds wordt aangemaakt',
        value: {
          none: 'Geen',
          fixed: 'Vast',
          flexible: 'Flexibel',
        },
        tooltip: {
          none: 'Als je deze optie selecteert, negeren we de echte grootte van de aangemaakte batch en kan deze oneindig gebruikt worden.',
          fixed: 'Als je deze optie selecteert, moet je een vaste grootte voor de aangemaakte batches invullen.',
          flexible: 'Als je deze optie selecteert, moet de operator de batch grootte invullen zodra een nieuwe batch wordt aangemaakt.',
        },
      },
      onTheFlySource: {
        label: 'Flexibele Registratiebron',
        info: 'Deze instelling bepaalt waar vanuit de nieuwe batch aangemaakt wordt.',
        value: {
          new: 'Nieuw',
          existing: 'Bestaand',
        },
        tooltip: {
          new: 'Er wordt een nieuwe batch gemaakt.',
          existing: 'Het systeem zal vragen om een bestaande batch te scannen waar de nieuwe batch uitgehaald zal worden als een subbatch.',
        },
      },
      autoAddProcessToNewArticles: { label: 'Proces automatisch toevoegen aan artikeltypen', with: 'wanneer' },
      classification: { label: 'Groep' },
      itemAssortmentValue: { label: 'Assortiment' },
      deleted: { label: 'Verwijderd' },
    },
  },
  articleType: {
    overview: {
      title: 'Artikeltypes',
      empty: 'Er zijn geen artikeltypes die voldoen aan uw zoekopdracht.',
      addButton: 'Artikeltype',
      viewBOMButton: 'Bekijk Stuklijst',
      viewAssemblyBOMButton: 'Bekijk Assemblagestuklijst',
      copyBatchTypeButton: 'Kopieer Batchtypes',
      unlinkAndDeleteTemplateProcessButton: 'Ontkoppel en Verwijder Proces "{{processName}}"',
    },
    importArticleModal: {
      title: 'Import Artikeltypes',
      fileNamePlaceholder: 'Upload .csv',
      explanation: 'Upload Artikeltypes in een CSV met kolommen met de namen: "Code", "Name", "Description", "Group Name", "Project Code", waar de codes uniek zijn. Het veld scheidingsteken moet ; zijn en het text scheidingsteken ". Het bestand moet gedecodeerd zijn in UTF-8.',
      crud: {
        created: '{{content}} gecreerd',
        updated: '{{content}} geupdate',
        deleted: '{{content}} verwijdered',
        articleType: '{{count}} artikeltype(s), ',
        group: '{{count}} groep(en), ',
        project: '{{count}} project(en), ',
        metavalue: '{{count}} metawaarde(n), ',
        metavalueNotFound: 'Metaveld(en) met naam(en) "{{names}}" en invoerniveau artikeltype niet gevonden, maar de kolom was in uw csv.',
        metavalueNotEnabled: 'Metaveld(en) "{{metafield}}" zijn aangezet voor artikelgroep "{{classification}}".',
        metavalueInvalid: 'Metaveld {{field}} met waarde "{{value}}" kon niet toegevoed worden omdate "{{error}}".',
      },
      options: {
        includeMetavalues: {
          label: 'Metawaarden toevoegen op basis van kolomnamen',
          warning: 'Zorg ervoor dat de kolomnamen hetzelfde zijn als de namen van de metavelden. Dit is spatie- en hoofdlettergevoelig!',
        },
        deleteOldArticles: 'Verwijder alle artikeltypes die niet in het bestand staan',
        deleteOldArticleGroups: 'Verwijder alle groepen die niet in het bestand staan',
        deleteOldProjects: 'Verwijder alle projecten die niet in het bestand staan',
      },
    },
    printShelfLabelButton: 'Magazijnlocatielabel',
    selectModal: {
      title: 'Kies een Artikeltype',
    },
    create: {
      title: 'Artikeltype Aanmaken',
    },
    edit: {
      baseFields: {
        label: 'Basisvelden',
        info: 'Deze velden behoren niet tot een procestype maar zijn toepasselijk op het gehele artikeltype.',
      },
      title: 'Artikeltypeaanpassen',
      version: 'Versie {{version}}',
      draft: 'Concept',
      finalizeButton: 'Afronden',
      nextVersionButton: 'Volgende Versie',
      noStepSelected: 'Geen stap geselecteerd',
      workStationsModal: {
        title: 'Selecteer Werkplekken',
      },
      copyPaste: {
        copyButton: 'Kopieer',
        pasteButton: 'Plak',
        copied: 'Succesvol gekopieerd',
        pasted: 'Succesvol geplakt',
        pasteError: 'Er kan niks geplakt worden',
      },
      bomFieldsModal: {
        title: 'Stuklijst Velden',
        content: 'Dit proces bevat velden van het type \'Stuklijst\'. Wisselen naar een ander batch type dan \'maak\' zal er voor zorgen dat deze velden worden verwijderd.',
      },
      endWithVariableQuantity: 'Dit proces kan niet eindigen met een batch die variabel is. Voeg een veld "grootte" toe om de daadwerkelijke grootte van de batch te bepalen.',
      splitWithVariableQuantity: 'Een batch stap kan niet gebruikt worden op een variabele batch.',
      quantityFieldWithoutVariableQuantity: 'Een grootte veld kan niet worden gebruikt zonder een variabele batch.',
      sidebar: {
        header: {
          articleTypeMetafields: 'Artikeltype metavelden',
          articleTypeSettings: 'Artikeltype instellingen',
        },
      },
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      name: { label: 'Naam' },
      line: { label: 'Productieomgeving' },
      size: {
        label: 'Grootte',
        explanation:
          `Gebruikt om te bepalen hoe vol een opslaglocatie is. De meeteenheid
          kan alles zijn wat u wenst, zorg ervoor dat u consistent blijft.`
      },
      preferredLocations: { label: 'Voorkeurslocaties' },
      versions: { label: 'Versies' },
      batchTypes: { label: 'Processen' },
      articleType: { label: 'Artikeltype' },
      componentType: { label: 'Componenttype' },
      classification: { label: 'Groep' },
      startDate: { label: 'Startdatum' },
      endDate: { label: 'Einddatum' },
      isAssembly: { label: 'Assemblage', filter: 'Is Assemblage?' },
      isExplode: { label: 'Ontploffen bij levering', filter: 'Is Ontploffen?' },
      isMake: { label: 'Maak', filter: 'Is Maak?' },
      isBuy: { label: 'Koop', filter: 'Is Koop?' },
      isSerial: { label: 'Serieel' },
      isMakeOrBuy: { label: 'Maak/Koop' },
      storageLocations: { short: 'Opslaglocatie(s)', long: 'Opslaglocatie(s) - Hoeveelheid' },
      availableBatches: {
        label: 'Beschikbare Batch(es)',
        value_one: '{{count}} beschikbare batch ',
        value_other: '{{count}} beschikbare batches',
        noBatch: 'Geen beschikbare batch',
      },
      totalStock: { label: 'Totale Voorraad' },
      storageLocationStock: { label: 'Opslaglocatie Voorraad' },
      deleted: { label: 'Verwijderd' },
      endDateBeforeCurrDate: { label: 'Einddatum vóór vandaag weergeven' },
      myTrackBatchUsage: { label: 'Batchgebruik bijhouden' },
      trackBatchUsage: {
        label: 'Batchgebruik bijhouden',
        followClassificationCheckbox: 'Volg groep',
        followClassificationHelpLine1: 'Indien aangevinkt, zal het volgen wat is geconfigureerd voor de verbonden groep',
        followClassificationHelpLine2: 'Het is momenteel geconfigureerd als'
      },
      unit: { label: 'Unit' },
      extraDescription: { label: 'Extra Beschrijving' },
      searchCode: { label: 'Zoekcode' },
      barcode: { label: 'Barcode' },
      volume: { label: 'Volume' },
      volumeFactor: { label: 'Volume Factor' },
      properties: { label: 'Properties' },
    },
  },
  articleTypeVersion: {
    field: {
      articleType: { label: 'Artikeltype' },
      productionLineVersion: { label: 'Productieomgevingversie' },
    },
  },
  productionLine: {
    overview: {
      title: 'Productieomgevingen',
      empty: 'Er zijn geen productieomgevingen die voldoen aan uw zoekopdracht.',
      addButton: 'Productieomgeving',
    },
    planning: {
      title: 'Productieomgevingen',
      empty: 'Geen productieomgevingen gevonden',
      productionLine: 'Productieomgeving',
      week: 'Week',
      days: {
        monday: 'Maandag',
        tuesday: 'Dinsdag',
        wednesday: 'Woensdag',
        thursday: 'Donderdag',
        friday: 'Vrijdag',
        saturday: 'Zaterdag',
        sunday: 'Zondag',
      },
      parts: {
        night: 'Nacht (00:00 - 06:00)',
        morning: 'Ochtend (06:00 - 12:00)',
        afternoon: 'Middag (12:00 - 18:00)',
        evening: 'Avond (18:00 - 00:00)',
      },
    },
    create: {
      title: 'Productieomgeving Aanmaken',
    },
    edit: {
      baseFields: {
        label: 'Globale instellingen',
        info: 'Deze velden behoren niet tot een versie maar zijn toepasselijk op elke versie.',
      },
      title: 'Productieomgeving Aanpassen',
      version: 'Versie {{version}}',
      draft: 'Concept',
      finalizeButton: 'Afronden',
      nextVersionButton: 'Volgende Versie',
      tabs: {
        map: 'Kaart',
        codes: 'Codes',
        availability: 'Beschikbaarheid',
      },
    },
    selectModal: {
      title: 'Kies een Productie',
    },
    codesModal: {
      title: 'Codes Overzicht',
      noPrintersAvailable: 'Er zijn geen printers beschikbaar.',
    },
    field: {
      name: { label: 'Naam' },
      fullName: { label: 'Naam' },
      versions: { label: 'Versies' },
      timezone: { label: 'Tijdzone' },
      color: {
        label: 'Kleur',
        value: {
          red: 'Rood',
          orange: 'Oranje',
          yellow: 'Geel',
          olive: 'Olijfgroen',
          green: 'Groen',
          teal: 'Turquoise',
          blue: 'Blauw',
          violet: 'Violet',
          purple: 'Paars',
          pink: 'Roze',
          brown: 'Bruin',
          grey: 'Grijs',
          black: 'Zwart',
        },
      },
      workSchedules: {
        label: 'Roosters',
        errors: {
          missing: 'Productielijn moet een geldig rooster hebben.',
        }
      },
      leaveCalendars: { label: 'Verlofkalendars' },
      factory: { label: 'Fabriek' },
    },
  },
  productionLineVersion: {
    field: {
      version: { label: 'Versie' },
      draft: { label: 'Concept' },
      floorPlan: { label: 'Plattegrond' },
      workStations: {
        label: 'Werkplekken',
        addButton: 'Werkplek',
        error: {
          missing: 'Ten minste een werkplek is vereist.',
        }
      },
      productionLine: { label: 'Productieomgeving' },
    },
  },
  workloadPlanner: {
    title: 'Planner',
    empty: 'Er zijn geen geplande orders voor de geselecteerde periode.',
    toPlanEmpty: 'Er zijn geen bestellingen om te plannen voor de geselecteerde periode.',
    relation: 'Relation',
    importMaterialPlanTaskModal: {
      button: 'Importeer Taken',
      title: 'Importeer Taken voor ',
      fileNamePlaceholder: 'Upload .csv',
      explanation: 'Upload material plan tasks in a CSV with columns named: "Line Number", "Article Type Code", "Quantity", waar elk lijn nummer uniek is. De volgende velden zijn optioneel: "Description" and "Workstation Name". Als "Workstation Name" gespecificeerd is, moet ook de "Production Area Name" gespecificeerd worden, dan wordt deze taak enkel weergegeven voor dat werkstation. Het veld scheidingsteken moet ; zijn en het text scheidingsteken ". Het bestand moet gedecodeerd zijn in UTF-8.',
      crud: {
        created: '{{countPlan}} material plannen en {{countTask}} taken gemaakt  ',
        updated: '{{countPlan}} material plannen en {{countTask}} taken geupdate',
        deleted: '{{countTask}} taken verwijderd ',
        article_not_found: 'Artikeltype(n) met code "{{names}}" zijn niet gevonden.',
        workstation_not_found: 'Werkstation(en) met naam "{{names}}" zijn niet gevonden.',
        production_area_not_found: 'Productieomgeving(en) met naam "{{names}}" zijn niet gevonden.',
      },
    },
    processType: {
      label: 'Process Type',
      value: {
        make: 'Production Orders {{count}}',
        buy: 'Sub Inbound',
        sell: 'Sub Outbound',
        transfer_line: 'Sub Transfer line',
        stock_count: 'Stock Count {{count}}',
        transfer: 'Transfer Orders {{count}}',
        pick_order: 'Pick Orders {{count}}',
        receive_order: 'Receive Orders {{count}}',
      },
    },
    status: {
      value: {
        open: 'Open',
        needsPreparation: 'Heeft voorbereiding nodig',
        ready: 'Bereid',
        inProgress: 'Bezig',
        done: 'Klaar',
        released: 'Vrijgegeven',
      },
    },
    fields: {
      orderTags: { label: 'Order tags' },
      highestReceiptDate: { label: 'Hoogste ontvangstdatum' },
      allStock: { label: 'Alle voorraad' },
      earliestDeliveryDate: { label: 'Vroegste leverdatum' },
      noAllocation: { label: 'Geen toegewezen/gekoppelde verkooporders.' },
      noPurchaseOrderLines: { label: 'Geen inkooporderregels gekoppeld aan de stuklijst.' },
      articleGroup: { label: 'Article Group' },
    },
    bulkAction: {
      moveToPrevious: 'Verplaats naar vorige week',
      moveToNext: 'Verplaats naar volgende week',
      unreleaseFromFloor: 'Orders terugzetten',
      releaseToFloor: 'Vrijgeven om orders te starten',
    },
    releaseWarning: {
      title: 'Waarschuwing: onvoldoende materiaalvoorraad',
      currentStockInsufficient: 'De huidige voorraad PR{{id}} materialen is onvoldoende. Weet u zeker dat u wilt doorgaan?',
      futureStockInsufficient: 'De toekomstige voorraad PR{{id}} materialen is onvoldoende. Weet u zeker dat u wilt doorgaan?'
    },
    parentShopOrder: 'Ouder Werkorder: {{number}}',
  },
  workStation: {
    field: {
      name: { label: 'Naam' },
      code: { label: 'Code' },
      dataSources: { label: 'Databronnen' },
      defaultFte: { label: 'Standaard FTE' },
      isAdministration: { label: 'Administratief werkstation' },
      view: { label: 'Weergave' },
      singleUser: {
        label: 'Aantal Logins',
        value: {
          true: 'Enkele Login',
          false: 'MultiLogin',
        },
        tooltip: {
          true:
            'Wanneer ingesteld als \'Enkele Login\' zal het werkstation eenmalig de badge scan vragen aan de operator. Na standaard 4u zal er automatisch worden uitgelogd. Deze tijd kun je aanpassen via de variabele single_user_work_station_timeout_minutes in de algemene instellingen.',
          false:
            'Wanneer ingesteld als \'Multi Login\' zal het werkstation bij elke nieuwe stap vragen om de badge scan.',
        },
      },
      productionStrategy: {
        label: 'Productiestrategie',
        value: {
          together: 'Samen',
          seperate_spread: 'Gescheiden (Verdeeld)',
          seperate_planned: 'Gescheiden (Gepland)',
        },
        tooltip: {
          together: 'Wanneer ingesteld als \'Samen\' zullen de verschillende locaties samen werken als één locatie.',
          seperate_spread:
            'Wanneer ingesteld als \'Gescheiden (Verdeeld)\' zullen de verschillende locaties gescheiden werken aan dezelfde productie opdrachten.',
          seperate_planned:
            'Wanneer ingesteld als \'Gescheiden (Gepland)\' zullen de verschillende locaties gescheiden werken aan productie opdrachten die per locatie worden ingepland.',
        },
      },
      maintenanceStrategy: {
        label: 'Onderhoudsstrategie',
        value: {
          together: 'Samen',
          seperate: 'Gescheiden',
        },
        tooltip: {
          together:
            'Wanneer ingesteld als \'Samen\' zal onderhoud aan de verschillende locaties samen uitgevoerd en gepland worden voor alle locaties.',
          seperate:
            'Wanneer ingesteld als \'Gescheiden\' zal onderhoud aan de verschillende locaties gescheiden uitgevoerd en gepland worden per locatie.',
        },
      },
      steps: { label: 'Stappen' },
      workAssigned: { label: 'Gepland' },
      workUnassigned: { label: 'Ongepland' },
      work: { label: 'Werk' },
      productionRequests: { label: 'Werkorders' },
      automationDate: { label: 'Automatiseringsdatum' },
      externalWorkStations: { label: 'External WorkStations' },
    },
    planning: {
      title: 'Dagplanning',
      empty: 'Er is niks om in te plannen voor deze dag.',
      assignmentsModal: {
        title: 'Dagplanning: {{name}}',
      },
      assignmentTimeModal: {
        title: 'Tijden Aanpassen',
        overlap: {
          label: 'Deze tijden overlappen met al geplande tijdsvakken voor deze operator.',
          assignment: '{{workStation}} van {{start}} tot {{end}}.',
        },
      },
      day: {
        today: 'Vandaag',
        tomorrow: 'Morgen',
        other: 'Anders',
      },
      stats: {
        work: 'Uren werk',
        workAssigned: 'Geplande uren',
        workUnassigned: 'Ongeplande uren',
        month: {
          january: 'JANUARI',
          february: 'FEBRUARI',
          march: 'MAART',
          april: 'APRIL',
          may: 'MEI',
          june: 'JUNI',
          july: 'JULI',
          august: 'AUGUSTUS',
          september: 'SEPTEMBER',
          october: 'OKTOBER',
          november: 'NOVEMBER',
          december: 'DECEMBER',
        },
      },
    },
    start: {
      title: 'Werkplek Starten',
      startButton: 'Starten',
    },
    production: {
      title: 'Productie',
      empty: 'Er zijn geen productieopdrachten die voldoen aan uw zoekopdracht.',
      stopButton: 'Stoppen',
      selectButton: 'Produceren',
      version: 'Versie {{version}}',
      operatorModal: {
        title: 'Scan Badge ID',
        confirmButton: 'Bevestigen',
        error: {
          noResults: 'Geen operators gevonden met het gegeven Badge ID.',
          emptyField: 'Geef de Badge ID van de operator in.',
          multipleResults: 'Meerdere operators gevonden met het gegeven Badge ID.',
          unexpected: 'Er is een onverwachte fout opgetreden.',
          missingCapabilities: 'De gevonden operator mist een benodigde vaardigheid: {{capabilities}}.',
        },
      },
      batchModal: {
        title: 'Scan Product',
        confirmButton: 'Bevestigen',
        loadCarriers: 'Scan Load carrier',
        error: {
          notFound: 'Geen product gevonden met het gegeven serienummer.',
          loadCarrierNoBatch: 'Ladingdrager bevat geen batch'
        },
      },
      performModal: {
        generalErrors: 'Er zijn wat fouten voorgekomen',
        title: 'Voer Stap Uit',
        confirmButton: 'Bevestigen & volgende stap [{{count}}]',
        confirmAndOverviewButton: 'Bevestigen & naar overzicht [{{count}}]',
        confirmAndNextBatchButton: 'Bevestigen & naar volgende batch [{{count}}]',
        stillToDo: 'Still to do',
        goToMain: 'Ga naar volgende regels',
        formStep: {
          label: 'Serienummer',
          subject: {
            article_type: 'Artikel',
          },
          saveProgress: 'Opslaan',
          printProgress: 'Etiket opslaan en afdrukken',
        },
        tasksCheck: {
          header: 'Tasks/Material unfinished!',
          content: 'Are you sure you want to finish this step? You did not finish all tasks and/or materials.',
          closeButton: 'Go back',
          confirmButton: 'Finish step',
        },
        carrierStep: {
          title: 'Ontvangst input',
          deliveryAddress: 'Adres geadresseerde',
          total: 'totaal',
          length: 'lengte',
          width: 'breedte',
          height: 'hoogte',
          volume: 'volume',
          weigth: 'gewicht',
          trackTrace: 'Track en trace',
          Retrieve: 'haal track en trace op',
          send_request: {
            label: 'Verzend order',
          },
          printButton: 'print',
          email: 'E-mailadres',
          phoneNumber: 'Telefoonnummer',
          reference: 'referentie',
          amount: 'aantal',
          country: 'landcode',
          extraServices: 'extra diensten',
          upsServices: 'UPS Service',
          closeButton: 'sluiten',
          confirmButton: 'versturen',
          link: 'Selecteer om verzendregel toe te voegen.',
          scanToLink: 'Scannen om verzendregel toe te voegen.',
          confirm: {
            header: 'Weet u het zeker?',
            content: 'Weet u zeker dat u dit wilt uitvoeren?',
          },
          pickShippingUnit: 'Type',
          documentHeader: 'Voeg documenten toe aan verzending',
          skipPrint: 'Printen overslaan',
          printContentLabels: 'Printen inhoudslabels',
        },
        printStep: {
          selectPrinter: 'Printer Selecteren',
          noPrintersAvailable: 'Geen Printers Beschikbaar',
          printButton: 'Printen',
          skipPrintButton: 'Printen uitstellen',
          printedSuccesfully: 'Succesvol geprint',
          printSkipped: 'Print uitgesteld',
        },
        splitStep: {
          printButton: 'Bevestig & print',
          printAllButton: 'Alles printen ({{count}})',
          skipPrintButton: 'Bevestig & printen uitstellen',
          reprintButton_one: 'Opnieuw Printen',
          reprintButton_other: 'Opnieuw Printen ({{count}})',
          bulkEntrySerialNumbersDetail: 'U kunt meerdere serienummers tegelijk scannen.',
          wrongFormat: 'Het serienummer {{serialNumber}} komt niet overeen met het vooraf bepaalde formaat.',
          expectedQuantity: 'U heeft het verwachte aantal serienummers al gescand.',
          integrationNotActive: 'Exact integratie is niet geactiveerd of bestaat niet.',
          alreadySyncedToExact: 'Het serienummer {{serialNumber}} is al gescand.',
        },
        quantityType: {
          quantity: 'Grootte',
          weight: 'Gewicht',
          error: {
            tooMuch: 'De ingevulde hoeveelheid is groter dan de hoeveelheid die voor deze order nog over is.',
          }
        },
        scan: {
          serialNumber: 'Serienummer',
          quantity: 'Aantal',
          error: {
            notFound: 'Er is geen batch gevonden met het opgegeven serienummer.',
            alreadyScanned: 'Een batch met dit serienummer is al gescanned.',
            wrongArticleType: 'De batch, gevonden met het opgegeven serienummer, heeft niet het verwachtte artikeltype.',
            wrongBatchType: 'De batch, gevonden met het opgegeven serienummer, is niet van een batchtype dat gescand kan worden.',
            batchIsBlocked: 'Gescande batch is geblokkeerd voor scannen',
            batchIsNotBlocked: 'Gescande batch wordt niet geblokkeerd, terwijl dit veld alleen geblokkeerde batches accepteert',
            fullyUsed: 'De batch, gevonden met het opgegeven serienummer, heeft niet genoeg beschikbare voorraad.',
            constraint: {
              noValue: 'De batch, gevonden met het opgegeven serienummer, heeft geen waarde voor {{field}}.',
              doesNotHold: {
                eq: 'De batch, gevonden met het opgegeven serienummer, heeft {{leftField}} {{left}} wat niet gelijk is aan {{right}}.',
                neq: 'De batch, gevonden met het opgegeven serienummer, heeft {{leftField}} {{left}} wat gelijk is aan {{right}}.',
                lt: 'De batch, gevonden met het opgegeven serienummer, heeft {{leftField}} {{left}} wat niet lager is dan {{right}}.',
                lte: 'De batch, gevonden met het opgegeven serienummer, heeft {{leftField}} {{left}} wat niet lager is dan of gelijk is aan {{right}}.',
                gt: 'De batch, gevonden met het opgegeven serienummer, heeft {{leftField}} {{left}} wat niet hoger is dan {{right}}.',
                gte: 'De batch, gevonden met het opgegeven serienummer, heeft {{leftField}} {{left}} wat niet hoger is dan of gelijk is aan {{right}}.',
              },
            },
          },
        },
        instructions: {
          title: 'Instructies',
          noInstructions: 'Er zijn geen stapinstructies gedefinieerd.',
          doneBtn: 'Klaar',
          document: {
            id: 'ID',
            description: 'Beschrijving',
            subject: 'Onderwerp',
            date: 'Datum',
          }
        }
      },
      quantityModal: {
        title: 'Vul Batchgrootte In',
        quantity: 'Grootte',
        confirmButton: 'Bevestigen',
        quantityWarning: 'The quantity inputted is higher than the ordered quantity ({{quantity}})!',
      },
      infoModal: {
        title: 'Info',
      },
      operator: {
        expiresAt: 'Verloopt om {{at}}',
      },
      onTheFlyQuantityModal: {
        title: 'Flexibele Registratieaantallen',
        description: 'Een aantal van de gescande batches bestaat nog niet in het systeem. Om deze middels Flexibele Registratie nu tussentijds aan te maken moet het systeem weten hoe groot deze batches zijn.',
        placeholder: 'Aantal',
      },
      onTheFlySourceModal: {
        title: 'Flexibele Registratiebron',
        description: 'De gescande batch bestaat nog niet in het systeem. Om deze batch aan te maken moet het systeem weten waar deze batch uitgehaald is.',
        placeholder: 'Serienummer',
        error: {
          notFound: 'Er is geen batch gevonden met het opgegeven serienummer.',
          wrongArticleType: 'De batch, gevonden met het opgegeven serienummber, heeft niet het verwachtte artikeltype.',
          notEnough: 'De batch, gevonden met het opgegeven serienummber, heeft niet genoeg beschikbare voorraad.',
        },
      },
      fastForwardButton: 'Vooruitspoelen',
      fastForwardModal: {
        title: 'Vooruitspoelen',
        days: 'Dagen',
        confirmButton: 'Bevestigen',
      },
      pickFromListModal: {
        title: 'Verzamel: {{articleType}}',
      },
      scanToPerformModal: {
        title: 'Scan barcode / serienummer om uit te voeren',
        confirmBtn: 'Begin',
        infoBtn: 'Batch-informatie',
        cancelBtn: 'Annuleren',
        error: {
          notFound: 'Geen product gevonden met dit serienummer.',
          missingSkills: 'Je hebt niet de vaardigheden om dit proces uit te voeren. Benodigde vaardigheden: {{skills}}',
          wrongWorkstation: 'Gescand product hoort bij een ander werkstation. Juiste werkstation is {{workstation}}'
        },
      },
    },
    editModal: {
      editTitle: 'Werkplek Configureren',
      viewTitle: 'Werkplek Configuratie',
    },
    stopModal: {
      title: 'Uitloggen Stoppen',
      content: 'Weet u zeker dat u wilt stoppen op deze werkplek?',
      stopButton: 'Uitloggen',
    },
    operatorPauseWorkModal: {
      title: 'Operator Stop met Werken',
      clockOutButton: 'Stop met werken aan orders als operator',
    },
    day: {
      today: 'Vandaag',
      tomorrow: 'Morgen',
    },
    views: {
      default: 'Normaal',
      export: 'Importeren / Exporteren'
    }
  },
  productionRequest: {
    reduceQuantityModal: {
      header: 'Verlaag aantal',
      content: 'Here we can decide to produce less than the original production request quantity. When applied, the quantity will be reduced to {{quantityReduced}}.',
    },
    finishModal: {
      header: 'Manually finish Production Request',
    },
    registerExistingButton: 'VOORRAADTELLING',
    nest: {
      makeNest: 'Nest aanmaken'
    },
    overview: {
      title: 'Werkorders',
      empty: 'Er zijn geen Productieorders die voldoen aan uw zoekopdracht.',
      addButton: 'Productieorder',
      createWarehouseTransferButton: {
        fromMissing: 'Overboeking Maken van Ontbrekende Artikelen',
        fromMaterials: 'Overboeking Maken Vanuit Materiaalplan',
        multipleWarehouses: 'Meer dan 1 magazijn geselecteerd'
      },
      bulkChangePlanned: {
        button: 'Plan orders',
        title: 'Bulk Plan orders',
        confirm: 'Plan orders ({{date}})',
      },
      assemblyAtDelivery: 'Montage bij aflevering component voor{{article}}',
      bulkCreateOrders: {
        trigger: 'Maak Orders',
        title_one: 'Maak {{count}} order',
        title_other: 'Maak {{count}} orders',
        same: 'Vul in voor alle orders',
        different: 'Selecteer artikelen en vul details per order in',
        selectButton_one: 'Maak {{count}} order',
        selectButton_other: 'Maak {{count}} orders',
        metavalues: 'Metawaardes',
        errors: {
          metavalue_one: '{{count}} metawaarde niet goed ingevuld',
          metavalue_other: '{{count}} metawaardes niet goed ingevuld',
        },
      },
      productionRequestID: 'Werkorder ID',
      inShipmentId: 'Ontvangst ID',
      outShipmentId: 'Verzamel ID',
      exactSalesOrder: 'Open Verkooporder {{order}} in Exact',
      exactPurchaseOrder: 'Open Inkooporder {{order}} in Exact',
      exactShopOrder: 'Open Productieorder {{order}} in Exact',
      purchaseOrderNumber: 'Inkooporder ID',
      stockCountNumber: 'Voorraadtelling ID',
      salesOrderNumber: 'Verkooporder ID',
      linkedSalesOrder: 'Gekoppelde Verkooporder ERP {{order}}',
      warehouseTransferID: 'Magazijnoverdracht ID',
      warehouseTransferLineID: 'Magazijnoverdrachtregel ID',
      productionOrderNumber: 'Productieorder ID',
      articleTypeCode: 'Articletype CODE',
      orders: 'Orders',
      salesOrders: 'Verkooporders',
      done_one: '{{count}} product klaar',
      done_other: '{{count}} producten klaar',
      progress: '{{percentage}} v.h. werk klaar',
      extra_one: '+ {{count}} extra',
      extra_other: '+ {{count}} extra',
      diff: {
        planned: 'Gepland',
        actual: 'Actueel',
        advance: 'Voortgang',
        delay: 'Vertraging',
      },
      information: 'Informatie',
      order: 'Order',
      processSteps: 'Processtappen',
      warehouse: 'Magazijn',
      removeFromPlanning: 'Remove from current planning',
      unrelease: 'Terugzetten',
      createdBy: 'Gemaakt door {{creator}}',
      plannedOn: 'Gepland voor',
      groupBy: {
        label: 'Groupeer bij Oplopend/Aflopend',
        ungrouped: 'Niet Gegroepeerd',
        options: {
          start_at: 'Geplande Start Datum',
          article_type__name: 'Articletype Naam',
          project__code: 'Project Code',
        }
      },
      quantity: {
        empty: 'Geen afgeronde batches voor deze order',
        warning: {
          producedTooMuch: 'Order is afgerond, maar er is {{count}} meer gemaakt dan verwacht',
          producedTooLittle: 'Order is afgerond, maar er is {{count}} minder gemaakt dan verwacht',
          explanation: 'Afgeronde hoeveelheid is de hoeveelheid in batches die afgerond zijn. Als een batch hieronder rood is, is het afgewezen of nog niet afgerond.',
        },
        info: '{{done}} van {{total}} afgerond',
      },
      processingTime: {
        label: 'Bezetting',
        notFound: 'Geen gegevens gevonden',
        warning: {
          startedLate: '{{delay}} later begonnen dan gepland'
        },
        info: '{{done}} van {{total}} gebruikt',
      },
      workedTime: {
        empty: {
          step: 'Geen afgeronde stappen hier',
          task: 'Geen taken voor deze order',
        },
        label: 'Gewerkte Tijd',
        total: 'Totaal Gewerkte Tijd',
        notEnabled: {
          label: 'Tijdregistratie staat uit voor deze order',
          info: 'Zet tijdregistratie aan in de proces bewerker',
        },
        notFound: 'Geen gegevens gevonden',
        warning: {
          overtime: '{{time}} overwerk geklokt voor deze order',
        },
        info: 'Operators hebben {{done}} van de {{total}} geplande uren gewerkt',
      },
      productionMessage: {
        empty: 'Geen berichten voor deze order',
      },
    },
    edit: {
      title: 'Werkorder Aanpassen',
      bom: 'Stuklijst',
      storageLocations: 'OPSLAGLOCATIES',
      releaseButton: 'Vrijgeven om order te starten',
      unreleaseButton: 'Order terugzetten',
      stage: {
        articleType: {
          label: 'Artikeltype Info',
          bom: {
            notEnoughStock: 'Niet genoeg voorraad beschikbaar om dit aantal te produceren',
          },
          showBomBtn: 'Laat stuklijst zien',
          missingMakeProcess: 'Ontbrekend standaard voltooid maakproces',
        },
        warehouse: {
          label: 'Magazijn Info',
        },
        planning: {
          label: 'Planning',
          planned: 'Gepland',
          actual: 'Gerealiseerd',
          duration: 'Looptijd',
          start: 'Start',
          end: 'Eind',
        },
        bom: {
          label: 'BOM Lijst',
          articleCode: 'Artikeltype Code',
          articleName: 'Artikeltype Naam',
          taskCode: 'code',
          taskName: 'Naam',
          duration: 'Tijd',
          workStation: 'Werkstation',
          unit: 'Unit',
          stock: 'Stock',
          plannedIn: 'Planned In',
          plannedOut: 'Planned Out',
        },
        materialPlan: {
          label: 'Materiaalplan'
        },
        tasks: {
          label: 'Geplande Taken'
        },
        byProducts: {
          label: 'Bijproducten',
        },
        productionLines: {
          label: 'Productieomgevingen',
        },
        metafields: {
          label: 'Metavelden',
        },
      },
      saveButton: 'Opslaan en Terug',
    },
    field: {
      id: { label: 'Werkorder ID' },
      articleType: { label: 'Artikeltype' },
      articleTypeDescription: { label: 'Articletype Beschrijving' },
      processVersion: { label: 'Versie' },
      week: { label: 'Week' },
      quantity: { label: 'Hoeveelheid' },
      progress: { label: 'Vordering' },
      quantityDone: { label: 'Hoeveelheid Afgerond' },
      startAt: { label: 'Verwacht Begin' },
      endAt: { label: 'Verwacht Eind' },
      duration: { label: 'Verwachte Looptijd' },
      project: { label: 'Project' },
      finished: { label: 'Afgerond' },
      productionOrder: { label: 'Productieorder' },
      totalProcessTime: { label: 'Totale Processtijd' },
      flagged: { label: 'Gemarkeerd' },
      producedQuantity: { label: 'Geproduceerde hoeveelheid' },
      inboundTrafficLight: { label: 'Inkooporderstoplicht' },
      outboundTrafficLight: { label: 'Verkooporderstoplicht' },
      material_planTrafficLight: { label: 'Voorraadstoplicht' },
      capacityTrafficLight: { label: 'Capaciteitsstoplicht' },
      erpNumber: { label: 'ERP ID' },
      erpStartDate: { label: 'ERP Start Datum' },
      manualFinishedReason: { label: 'Reden voor handmatig afgeronden' },
      workedTime: { label: 'Daadwerkelijk Gewerkte Tijd' },
      plannedStart: { label: 'Geplande Start' },
      plannedEnd: { label: 'Gepland Einde' },
      actualStart: { label: 'Daadwerkelijke Start' },
      actualEnd: { label: 'Daadwerkelijk Einde' },
    },
    filter: {
      itemGroup: 'Artikelgroep',
      requestStatus: 'Werkorder Status',
      inShipmentId: 'Ontvangst ID',
      outShipmentId: 'Verzamel ID',
      warehouseTransferID: 'Overdracht ID',
      warehouses: 'Magazijnen',
      projectCode: 'Project Code',
      workingOperators: 'Werkende Operators',
      purchaseOrderNumberERP: 'Inkooporder ERP',
      salesOrderNumberERP: 'Verkooporder ERP',
      shippingMethod: 'Verzendmethode',
      orderType: 'Ordertype',
      erpId: 'ERP ID',
      id: 'WO ID',
      articleCode: 'Artikel Code',
      erpStartDate: 'ERP Start Datum',
      tracyStartAt: 'Geplande Start Datum',
      tracyEndAt: 'Geplande Eind Datum',
      filterByStatus: 'Filteren Op Status',
      nestId: 'Nest ID',
      subProductionRequestMetavalues: 'Sub-Werkorder Metawaardes',
      markedColors: 'Gemarkeerde Kleuren',
      linkedSalesOrder: 'Gekoppelde Verkooporder ERP',
      deliveryDate: 'Verkooporder Bezorg Datum',
      today: 'Vandaag',
      days_3: 'Na 3 dagen',
      days_7: 'Na 7 dagen',
      past: 'De startdag is voorbij',
      productionLine: 'Productielijn',
      hasProductionMessages: 'Heeft Berichten',
    },
    addModal: {
      title: 'Productieopdrachten Toevoegen',
      step: {
        pickWeek: 'Kies de week',
        pickArticleTypes: 'Kies de artikeltypes',
        setQuantities: 'Kies de hoeveelheden',
      },
      quantityWarning:
        'Een productieopdracht opslaan met hoeveelheid 0 staat gelijk aan deze productieopdracht verwijderen.',
      noArticleTypes: 'U hebt nog geen artikeltypes geselecteerd.',
      notFullyDeleted: 'Productieopdracht kon niet volledig verwijderd worden omdat de opdracht al gestart is.',
    },
    editModal: {
      title_one: 'Productieopdracht Aanpassen',
      title_other: 'Productieopdrachten Aanpassen',
    },
    notStartedYet: 'Nog niet gestart',
    erpEnd: 'ERP einde'
  },
  productionMessage: {
    field: {
      category: { label: 'Categorie' },
      message: { label: 'Bericht' },
      operator: { label: 'Operator' },
    },
    modal: {
      empty: 'Geen berichten',
      title: 'Productie Berichten',
      subject: {
        label: 'Onderwerp',
        order: 'Order',
        article: 'Artikel'
      },
    },
  },
  orderHistory: {
    overview: {
      title: 'Bestellingen geschiedenis',
      empty: 'Er zijn geen bestellingen gevonden.',
      productionRequestID: 'Werkorder ID',
      purchaseOrderNumber: 'Inkooporder ID',
      salesOrderNumber: 'Verkooporder ID',
      productionOrderNumber: 'Productieorder ID',
      orders: 'Orders',
      plannedOn: 'Gepland voor'
    },
    field: {
      id: { label: 'Werkorder ID' },
      articleType: { label: 'Artikeltype' },
      quantity: { label: 'Hoeveelheid' },
      progress: { label: 'Vordering' },
      startAt: { label: 'Verwacht Begin' },
      endAt: { label: 'Verwacht Eind' },
      finished: { label: 'Afgerond' },
      erpNumber: { label: 'ERP ID' },
    },
    filter: {
      released: 'Vrijgegeven',
      itemGroup: 'Artikelgroep',
      warehouseTransferID: 'Overdracht ID',
      shippingMethod: 'Verzendmethode',
      erpId: 'ERP ID',
      articleCode: 'Artikel Code',
      nestId: 'Nest ID',
    },
  },
  globalValue: {
    edit: {
      explodeAtDeliveryBehaviour: {
        field: { label: 'Globale instelling' },
        groupExceptions: {
          divider: 'Groepsuitzonderingen',
          label: 'Bewerkingsgedrag van een andere artikelgroep',
        },
        options: {
          asGlobal: 'Als globaal',
          never: 'Nooit',
          onlyExploded: 'Alleen ontploffend',
          onlyUnexploded: 'Alleen niet-ontploffend',
          always: 'Altijd',
        }
      },
      unsetButton: 'Leegmaken',
      title: '{{key}} Aanpassen',
      operator_badge_print: {
        key: {
          operator_name: 'Naam van de operator.',
          badge_id: 'Badge ID van de operator.',
        },
      },
      workstation_code_print: {
        key: {
          workstation_name: 'Naam van het werkstation.',
          workstation_code: 'Code van het werkstation.',
        },
      },
      stock_location_print: {
        key: {
          article_type_code: 'Code van het artikeltype.',
          article_type_name: 'Naam van het artikeltype.',
          warehouse_code: 'Naam van het magazijn.',
          warehouse_locations: 'Magazijnlocaties.',
          erp_order_id: 'Het ID van de ERP order.',
          date: 'De datum van printen.',
          time: 'De tijd van printen.',
          best_before_date: 'Ten minste houdbaar tot datum',
        },
      },
      loading_carrier_print: {
        key: {
          loading_carrier_serial: 'Serienummer van de ladingdrager',
          loading_carrier_name: 'Naam van de ladingdrager',
        },
      },
      login_url_print: {
        key: {
          slug: 'De slug van je Tracy instantie. ({{value}})',
          app_url: 'De basis url van tracy. ({{value}})',
        },
      },
      progress_scope: {
        week: 'Week',
        month: 'Maand',
        day: 'Dag',
        none: 'Geen',
      },
      tracy_tag_size: {
        'normal': 'Normal (icon & text)',
        'mini': 'Mini (only icon)'
      },
      tracy_app_image_quality: {
        low: 'Low: 640x640',
        medium: 'Medium: 1024x1024',
        high: 'High: 2048x2048',
      },
      shipping_label: {
        key: {
          delivery_address: 'The delivery address of the order.',
          delivery_name: 'The delivery name of the order',
          serial_number: 'The batch\'s serial number.',
          erp_order_id: 'The order\'s ERP ID.',
          carrier_barcode: 'The barcode of the carrier.',
          carrier_depot: 'The depot of the carrier.',
          carrier_order: 'The carrier\'s request ID.',
          carrier_numberofcollo: 'The carrier\'s number of packages.',
          carrier_totalweight: 'The carrier\'s total weight.',
          carrier_collodimensions: 'The carrier\'s packages dimensions.',
          carrier_collonumber: 'The package\'s number.',
          customer_name: 'The customer\'s name.'
        },
      },
      sub_batches_print_label: {
        key: {
          operator_badge_id: 'De badge ID van de operator die de stap uitvoert waarbij dit label wordt afgedrukt.',
          operator_name: 'De volledige naam van de operator die de stap uitvoert waarbij dit label wordt afgedrukt.',
          serial_number: 'Het serial nummer van de batch.',
          erp_order_id: 'Het ID van de ERP order.',
          article_type_code: 'De code van get geneste artikel type.',
          article_type_name: 'De naam van het geneste artikel type.',
          batch_size: 'De hoeveelheid van de batch.',
          quantity: 'De hoeveelheid van de productieorder.',
          date: 'De datum van printen.',
          time: 'De tijd van printen.',
          order_id: 'Het ID van de productie order van de batch.',
          material_plan_line_description: 'De material plan line beschrijving',
          material_plan_line_progress: 'De voortgang van de materiaalplantaak'
        },
      },
      rework_label: {
        key: {
          date: 'De datum van printen.',
          time: 'De tijd van printen.',
          serial_number: 'Het serial nummer van de batch.',
          article_type_code: 'De code van get geneste artikel type.',
          article_type_name: 'De naam van het geneste artikel type.',
          erp_order_id: 'Het ID van de ERP order.',
          quantity: 'De hoeveelheid van de batch.',
          order_id: 'Het ID van de productie order van de batch.',
        },
      },
      clockInOutRoundOff: {
        label: 'Round off clock in / out times ?',
        useGlobal: 'Gebruik global setting `clock_in_out_round_off`',
        0: 'Niet afronden',
        15: 'Afronden naar volgend kwartier',
        30: 'Afronden naar volgend half uur',
        '-15': 'Afronden naar vorig kwartier',
        '-30': 'Afronden naar vorig half uur',
      },
    },
    overview: {
      title: 'Algemene instellingen',
      template: {
        empty: 'Template is leeg.',
        moreLines_one: '{{count}} regel niet getoond, hover om te tonen.',
        moreLines_other: '{{count}} regels niet getoond, hover om te tonen.',
      },
    },
    field: {
      key: {
        label: 'Naam Instelling',
      },
      value: {
        label: 'Waarde',
      },
      writable: {
        label: 'Schrijfbaar?',
      },
      readable: {
        label: 'Leesbaar?',
      },
    },
  },
  template: {
    field: {
      name: { label: 'Naam' },
      file: { label: 'PDF' },
      type: {
        label: 'Type',
        value: {
          out_shipment: 'Pakbon',
        },
      },
      isDefault: { label: 'Standaard' },
    },
    overview: {
      title: 'Templates',
      empty: 'Er zijn nog geen templates toegevoegd.',
      addButton: 'Template',
    },
    create: {
      title: 'Template Aanmaken',
    },
    edit: {
      title: 'Template Aanpassen',
    },
    info:
      'In een Template kun je waardes die betrekking hebben tot het product terug laten komen. Je kunt aangeven waar een waarde moet komen door {{ te typen op de juiste plek in de template.',
  },
  globalFile: {
    edit: {
      title: 'Bijzonder Bestand Bewerken',
    },
    overview: {
      title: 'Bijzondere Bestanden',
    },
    field: {
      key: {
        label: 'Naam Voor Bestand',
      },
      value: {
        label: 'Bestand',
      },
      writable: {
        label: 'Schrijfbaar?',
      },
      readable: {
        label: 'Leesbaar?',
      },
    },
  },
  myFilter: {
    custom: 'Nieuw',
    action: {
      unsetDefault: 'Gebruik niet als standaard',
      setDefault: 'Gebruik als standaard',
      setCurrent: 'Sla huidige filters op',
      delete: 'Verwijderen',
    },
  },
  tooltips: {
    masquerade: 'Voordoen als',
    edit: 'Aanpassen',
    delete: 'Verwijderen',
    restore: 'Terugzetten',
    view: 'Bekijken',
    select: 'Selecteren',
    finish: 'Finish',
    unfinish: 'Unfinish',
  },
  form: {
    // Model
    edit: {
      addFieldButton: 'Veld Toevoegen',
    },
    field: {
      fields: {
        label: 'Velden',
        empty: 'Dit formulier is nog leeg.',
      },
    },
    // Form stuff
    yes: 'Ja',
    no: 'Nee',
    either: 'Beide',
    all: 'Alles',
    draftJs: {
      style: {
        'header-one': { label: 'H1' },
        'header-two': { label: 'H2' },
        'header-three': { label: 'H3' },
        'header-four': { label: 'H4' },
        'header-five': { label: 'H5' },
        'header-six': { label: 'H6' },
        blockquote: { label: 'Blockquote' },
        'unordered-list-item': { label: 'UL' },
        'ordered-list-item': { label: 'OL' },
        'code-block': { label: 'Code Block' },
        BOLD: { label: 'Bold' },
        ITALIC: { label: 'Italic' },
        UNDERLINE: { label: 'Underline' },
        CODE: { label: 'Monospace' },
      },
    },
    iconInput: {
      noneSelected: 'Geen icoon geselecteerd',
    },
    cancelButton: 'Annuleren',
    closeButton: 'Sluiten',
    deleteButton: 'Verwijderen',
    restoreButton: 'Herstellen',
    backButton: 'Terug',
    nextButton: 'Volgende',
    submitButton: 'Opslaan',
    saveButton: 'Opslaan',
    saveAndCopyButton: 'Opslaan and Kopiëren',
    copyButton: 'Kopiëren',
    addButton: 'Toevoegen',
    printButton: 'Printen',
    downloadButton: 'Downloaden',
    applyButton: 'Toepassen',
    bulkActionButton: 'Actie ({{count}})',
    fileType: {
      pdf: 'PDF',
      image: 'IMG',
      xls: 'XLS',
      any: 'Anders',
      none: 'Nog geen bestand geüpload.',
    },
    multiPick: {
      searchPlaceholder: 'Selecteer…',
      selectedText: '$1 uit $2 geselecteerd',
      noneSelectedText: 'Geen geselecteerd',
      selectAllButton: 'Alle',
      selectNoneButton: 'Geen',
    },
    notifications: {
      saveSuccess: 'Succesvol opgeslagen',
      deleteSuccess: 'Succesvol verwijderd',
      saveError: 'Fout met opslaan ({{status}})',
      saveValError: 'Niet alle informatie is correct ingevuld',
      saveAuthError: 'Niet geauthorizeerd',
      newAppVersion: 'Nieuwe versie beschikbaar, klik om de pagina te verversen',
    },
    startDate: 'Startdatum',
    endDate: 'Einddatum',
    deleteConfirmation: 'Weet u zeker dat u "{{name}}" wilt verwijderen?',
    restoreConfirmation: 'Weet u zeker dat u "{{name}}" wilt terugzetten?',
    repeat: 'Herhaal',
    greaterThan: 'Hoger dan',
    lowerThan: 'Lager dan',
    greaterThanOrEqual: '>=',
    lowerThanOrEqual: '<=',
    greaterThanSign: '>',
    lowerThanSign: '<',
    equal: '=',
    duration: {
      hours: 'Uren',
      minutes: 'Minuten',
      seconds: 'Seconden',
    },
    cap: {
      remaining: '{{count}} over',
      tooMany: '{{count}} te veel',
      tooLittle: '{{count}} te weinig',
    },
  },
  crash: {
    heading: 'Applicatie gecrasht',
    subHeading1: 'Onze excuses, maar de applicatie is gecrasht.',
    subHeading2: 'Ons team is op de hoogte gesteld.',
    reload: 'Herlaad pagina',
  },
  daycy: {
    week: {
      label: 'Wk',
      number: '{{week}}',
      value: 'W{{week}} - {{year}}',
    },
    weekDay: {
      monday: 'Ma',
      tuesday: 'Di',
      wednesday: 'Wo',
      thursday: 'Do',
      friday: 'Vr',
      saturday: 'Za',
      sunday: 'Zo',
    },
    month: {
      January: 'Januari',
      February: 'Februari',
      March: 'Maart',
      April: 'April',
      May: 'Mei',
      June: 'Juni',
      July: 'Juli',
      August: 'Augustus',
      September: 'September',
      October: 'Oktober',
      November: 'November',
      December: 'December',
      january: 'Januari',
      february: 'Februari',
      march: 'Maart',
      april: 'April',
      may: 'Mei',
      june: 'Juni',
      july: 'Juli',
      august: 'Augustus',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'December',
    },
    period: {
      am: 'AM',
      pm: 'PM',
    },
  },
  iconModal: {
    header: 'Icoon Uitkiezen',
    filterPlaceholder: 'Zoeken...',
    category: {
      favorites: 'Favorieten',
      accessibility: 'Toegangelijkheid',
      arrows: 'Pijlen',
      audioVideo: 'Audio/Video',
      business: 'Business',
      chess: 'Schaken',
      code: 'Code',
      communication: 'Communicatie',
      computers: 'Computers',
      currency: 'Valuta',
      dateTime: 'Datum/Tijd',
      design: 'Design',
      editors: 'Tekstverwerking',
      files: 'Bestanden',
      genders: 'Geslachten',
      handsGestures: 'Handgebaren',
      health: 'Gezondheid',
      images: 'Afbeeldingen',
      interfaces: 'Interfaces',
      logistics: 'Logistiek',
      maps: 'Kaarten',
      medical: 'Medisch',
      objects: 'Objecten',
      paymentsShopping: 'Betalingen/Shoppen',
      shapes: 'Vormen',
      sports: 'Sport',
      status: 'Status',
      usersPeople: 'Mensen',
      vehicles: 'Voertuigen',
      writing: 'Schrijven',
    },
  },
  exact: {
    ok: {
      title: 'Exact Koppeling Succesvol',
      body: 'Exact Online is nu succesvol gekoppeld met het systeem.',
    },
    error: {
      title: 'Exact Koppeling Onsuccesvol',
      body: {
        invalid_request: 'Onjuiste request verstuurd naar Exact Online.',
        invalid_client: 'Onjuiste client verstuurd naar Exact Online.',
        invalid_grant: 'Onjuiste grant verstuurd naar Exact Online.',
        invalid_scope: 'Onjuiste scope verstuurd naar Exact Online. ',
        unauthorized_client: 'Ongeauthorizeerde client verstuurd naar Exact Online.',
        unsupported_grant_type: 'Niet ondersteund grant type verstuurd naar Exact Online.',
      },
    },
  },
  unit4: {
    ok: {
      title: 'Unit 4 Koppeling Succesvol',
      body: 'Unit 4 is nu succesvol gekoppeld met het systeem.',
    },
    error: {
      title: 'Unit 4 Koppeling Onsuccesvol',
      body: {
        invalid_request: 'Onjuiste request verstuurd naar Unit 4.',
        invalid_client: 'Onjuiste client verstuurd naar Unit 4.',
        invalid_grant: 'Onjuiste grant verstuurd naar Unit 4.',
        invalid_scope: 'Onjuiste scope verstuurd naar Unit 4. ',
        unauthorized_client: 'Ongeauthorizeerde client verstuurd naar Unit 4.',
        unsupported_grant_type: 'Niet ondersteund grant type verstuurd naar Unit 4.',
      },
    },
  },
  warehouse: {
    stock: 'Voorraad',
    inbound: 'Inbound',
    outbound: 'Outbound',
    edit: { title: 'Magazijnen' },
    overview: {
      title: 'Magazijnenoverzicht',
      empty: 'Er zijn geen magazijnen gevonden.',
    },
    field: {
      id: { label: 'ID ' },
      code: { label: 'Code' },
      name: { label: 'Naam' },
      useStorageLocations: { label: 'Gebruikt opslaglocaties?' },
      storageLocations: { label: 'Opslaglocaties' },
      syncLocations: { label: 'Synchroniseer locaties?' },
      defaultFromTransferLocation: { label: 'tandaard \'van\' voorraadverplaatsing locatie' },
      defaultToTransferLocation: { label: 'Standaard \'naar\' voorraadverplaatsing locatie' },
    },
  },
  step: {
    edit: {
      step: 'Stap {{index}}',
      start: 'Begin',
      end: 'Eind',
      addSectionButton: 'Onderdeel Toevoegen',
    },
    field: {
      newBatchSerialNumberFormat: { label: 'Formaat nieuw serienummer' },
      newBatchIsBlocked: {
        label: 'Nieuwe batch blokkeren?',
        info: 'De nieuwe batch wordt onmiddellijk geblokkeerd en kan in de meeste velden niet worden gescand, tenzij anders gespecificeerd',
      },
      label: { label: 'Label' },
      type: {
        value: {
          print: 'Printstap',
          form: 'Formulierstap',
          split: 'Batchstap',
          multiplier: 'Meerdere-keren-stap ',
          subprocesses: 'Subprocessen',
          carrier: 'Inpakken en verzenden stap',
          byproduct: 'Bijproductstap',
          nest: 'Neststap',
          export: 'Exportstap',
          import: 'Importstap',
        },
        valueStep: {
          print: 'Printstap',
          form: 'Formulierstap',
          split: 'Batchstap',
          multiplier: 'Meerdere-keren-stap ',
          subprocesses: 'Subprocessen',
          carrier: 'Inpakken en verzenden stap',
          byproduct: 'Bijproductstap',
          nest: 'Neststap'
        },
      },
      sections: {
        label: 'Beschrijving',
        empty: 'Deze beschrijving is nog leeg.',
      },
      workStation: { label: 'Werkplek' },

      capabilities: { label: 'Benodigde Vaardigheden' },
      operatorSupervision: {
        label: 'Operatorsupervisie Vereist',
      },
      setupMinutes: {
        label: 'Setuptijd ',
        info: 'Schatting van de tijd die nodig is voor de voorbereidingen van deze stap.',
        infoElaborate: 'Deze setuptijd wordt eens per order geteld, en wordt niet beïnvloed door de grote van de order. Een order met een grote van 1 heeft dezelfde wachttijd als een order met een grote van 20'
      },
      delayMinutes: {
        label: 'Wachttijd',
        info: 'Schatting van de tijd tussen het afronden van de vorige stap en het starten van deze stap.',
        infoElaborate: 'Deze wachttijd wordt eens per order geteld, en wordt niet beïnvloed door de grote van de order. Een order met een grote van 1 heeft dezelfde wachttijd als een order met een grote van 20'
      },
      workMinutes: {
        label: 'Werktijd',
        info: 'Schatting van de tijd van het uitvoeren van de stap.',
        infoElaborate: 'Voor de stapsduur wordt de werktijd vermenigvuldigd met de ordergrote. Een order met een grote van 20 duurt 20 maal zo lang als een order met grote van 20'
      },
      operatorIdleMinutes: {
        label: 'Operator Inactieve Tijd',
        info: 'Tijd waarin een operator niks hoeft te produceren, waarna hij uitgelogd wordt.',
      },
      orderSize: {
        label: 'Ordergrote',
        explanation: 'Expirimenteer hier met verschillende ordergrotes om te kijken hoe lang deze stap zou duren'
      },
      isLeadingBatch: {
        label: 'Leidende batch?',
        info: 'Indien geselecteerd als leidende batch, wordt bij de volgende stap geen nieuwe batch gemaakt, maar wordt deze in plaats daarvan gebruikt'
      },
      isTimed: {
        label: 'Registreer tijd?',
        info: 'Indien geselecteerd, registreer tijd voor deze stap. Tijdsregistratie is mogelijk voor stappen of materialplantaken.'
      },
    },
  },
  printStep: {
    field: {
      type: {
        label: 'Printtype',
        value: {
          label: 'Label',
          document: 'Document',
        },
      },
      copies: { label: 'Standaard Aantal Kopieën' },
      labelPrinter: {
        label: 'Printer',
        value: {
          altec: 'Altec',
          zebra: 'Zebra/Toshiba',
        },
      },
      labelTemplate: {
        label: 'Template',
        tracyKeys: {
          qr: 'Batch QR code.',
          serial_number: 'Het serienummer van het product.',
          batch_size: 'Batch grootte van het product.',
          date: 'De datum op het moment van printen.',
          time: 'De tijd op het moment van printen.',
          article_type_name: 'De naam van het artikel.',
          article_type_code: 'De code van het artikel.',
          article_type_gross_weight: 'Het brutogewicht van het artikel.',
          article_type_search_code: 'De zoekcode van het artikel.',
          article_type_barcode: 'De barcode van het artikel.',
          article_type_extra_description: 'De extra beschrijving van het artikel.',
          warehouse_name: 'De naam van het magazijn.',
          warehouse_code: 'De code van het magazijn.',
          warehouse_locations: 'De locaties van het magazijn.',
          quantity: 'Order hoeveelheid.',
          order_id: 'Order ID.',
          operator_badge_id: 'Operatorbadge-ID.',
          operator_name: 'Operatornaam.',
          sales_order_description: 'Verkooporder Beschrijving',
          purchase_order_description: 'Inkooporder Beschrijving',
          transfer_warehouse_from_code: 'Van Opslaglocatie van Voorraadverplaatsing',
          transfer_warehouse_to_code: 'Naar Opslaglocatie van Voorraadverplaatsing',
          transfer_description: 'Beschrijving van Voorraadverplaatsing',
          stock_count_default_storage_location_code: 'Voorraadtelling van de locaties van het magazijn.',
        },
        erpSlugKeys: {
          erp_order_id: 'ERP Order ID.',
          customer_name: 'Order klant naam.',
          customer_code: 'Order klant code.',
          customer_VAT_number: 'Order klant VAT nummer.',
          supplier_name: 'Order leverancier naam.',
          supplier_code: 'Order leverancier code.',
          reference: 'Order referentie.',
          remarks: 'Order opmerkingen.',
          delivery_address: 'Order verzendadres.',
          delivery_date: 'Order verzenddatum.',
          delivery_name: 'Order ontvanger naam.',
          delivery_code: 'Order ontvanger code.',
          receipt_date: 'Order ontvangstdatum.',
          sales_order_line_description: 'Verkooporderlijn beschrijving.',
          purchase_order_line_description: 'Inkooporderlijn beschrijving.',
          amount_ex_vat: 'Bedrag Exclusief btw.',
          shipping_method_description: 'Verzendmethode Beschrijving.',
          linked_erp_sales_order_id: 'ERP-nummer van gekoppelde verkooporder.',
          linked_erp_production_order_id: 'Gekoppeld productieorder ERP-nummer.',
          linked_erp_purchase_orders_ids: 'Gekoppelde ERP-nummers voor inkooporders.',
          linked_erp_purchase_order_lines_ids: 'Gekoppelde inkooporderregels ERP-nummers.',
          linked_erp_sales_order_reference: 'ERP-nummer van gekoppelde Referentie.',
        },
        keys: {
          // others
          sscc: 'SSCC gestandaardiseerde code.',
          shipment_lines: {
            description: 'Overzicht van pakbon. De eerste optie bepaalt de details per lijn, de tweede optie bepaalt de details per batch.',
            headerOption: {
              quantity: 'Hoeveelheid',
              description: 'Pakbon beschrijving',
              loading_carrier_serial: 'Ladingdragers serienummers',
              article_code: 'Artikel type code',
              article_name: 'Artikel type naam',
              article_size: 'Artikel type grootte',
              article_gross_weight: 'Artikel type brutogewicht',
              total_weight: 'Brutogewicht maal hoeveelheid',
              article_unit: 'Artikel type eenheid',
              article_search_code: 'Artikel type zoekcode',
              article_description: 'Artikel type beschrijving',
              serial_numbers: 'Serienummers van de pakbon lijn batch',
              line_notes: 'Notities op de in/verkooporderlijn',
            },
            detailOption: {
              serial_number: 'Batch serienummer',
              pick_detail: 'Batch verzamel locatie en hoeveelheid',
              loading_carrier_serial: 'Batch lading drager serienummer',
              best_before_date: 'Batch ten minste houdbaar tot datum',
              lotcode: 'Batch slug lotcode',
              all_metavalues: 'Alle metawaardes van deze batch',
            },
          },
          shipment_lines_with_loading_carrier: 'Overzicht van pakbon met loading carrier.',
          shipment_lines_without_meta: 'Overzicht van pakbon zonder metaveld informatie.',
        },
      },
      documentBackground: { label: 'Achtergrond' },
      documentOverlays: {
        label: 'Template',
        template: 'Template',
        addPage: 'Pagina',
        font_size: 'Font size',
        font_type: 'Font type',
      },
      defaultPrinter: {
        label: 'Standaardprinter',
        info: 'Wanneer er geprint wordt, wordt deze printer als standaard geselecteerd, als hij beschikbaar is',
      },
    },
  },
  multiplierStep: {
    field: {
      multiplier: { label: 'Aantal Keer' },
    },
  },
  formStep: {
    field: {
      form: { label: 'Formulier' },
      icon: { label: 'Icoon' },
      optional: { label: ' (Optioneel)' },
    },
    perform: {
      between: 'Tussen {{min}} en {{max}}',
      actualQuantity: 'Actual ERP quantity: {{quantity}}',
      bestBeforePeriod: 'Ten minste houdbaar voor (standaard): {{best_before_period}} dagen',
    },
  },
  splitStep: {
    field: {
      form: { label: 'Formulier' },
      icon: { label: 'Icoon' },
      newBatchQuantity: { label: 'Nieuwe Batchgrootte' },
      newBatchVariableQuantity: {
        label: 'Variabel',
        info: 'Grootte is een verwachte grootte, de daadwerkelijke grootte kan variëren.',
      },
      newBatchVariableQuantityPredetermined: {
        label: 'Variabel Voorbepaald',
        info: 'De variable grootte is voorbepaald, dit maakt het mogelijk voor de grootte om de stap zelf te beinvloeden, bijvoorbeeld voor sommige formulier velden of hoeveel je moet scannen.',
      },
      newBatchVariableUseOrderSize: {
        label: 'Bestelgrootte gebruiken?',
        info: 'De verwachte hoeveelheid is variabel, hierdoor kan het hoeveelheidveld een standaardwaarde van de ordergrootte hebben.',
      },
      printer: { label: 'Printer' },
      template: { label: 'Template' },
      copies: { label: 'Standaard Aantal Kopieën' },
      type: {
        label: 'Identificatietype',
        value: {
          scan: 'Scan',
          print: 'Print',
          article: 'Artikel',
          provided: 'Opgegeven serienummer',
        },
        tooltip: {
          scan:
            'Bij het identificatietype \'Scan\' wordt de batch in een ladingdrager geladen die vervolgens gescand wordt door de operator.',
          print: 'Bij het identificatietype \'Print\' wordt er een label geprint met het serienummer van de batch. Wanneer je geen formuliervelden toevoegt aan deze batch stap, kun je via de printall functionaliteit printen.',
          article:
            'Bij het identificatietype \'Artikel\' moet er tenminste één artikel gekoppeld worden waarvan het serienummer gebruikt kan worden.',
          provided: 'Bij identificatie door middel van \'Opgegeven serienummer\' wordt door de operator het serienummer verstrekt waarmee de partij dient te worden geïdentificeerd. Dit moet nog steeds overeenkomen met het nieuwe serienummerformaat dat hierboven is gedefinieerd.',
        },
      },
      defaultPrinter: {
        label: 'Standaardprinter',
        info: 'Wanneer er geprint wordt, wordt deze printer als standaard geselecteerd, als hij beschikbaar is',
      },
    },
  },
  formStepField: {
    field: {
      label: { label: 'Label' },
      type: {
        label: 'Type',
        value: {
          text: 'Text',
          check: 'Check',
          choice: 'Keuze',
          article_type: 'Artikeltype',
          measure: 'Meting',
          format: 'Format',
          variable: 'Variabele',
          image: 'Afbeelding',
          bom: 'Stuklijst',
          assembly_bom: 'Assemblagestuklijst',
          quantity: 'Grootte',
          storage_location: 'Opslaglocatie',
          metafield: 'Metaveld',
          material_plan_material: 'Materiaalplanmateriaal',
          material_plan_task: 'Materiaalplantaak',
          sub_batches: 'Subbatches',
          best_before_date: 'Ten minste houdbaar tot datum',
        },
      },
      target: { label: 'Details' },
      articleType: { label: 'Artikeltype' },
      materialPlan: {
        stockWarning: 'Waarschuwing! De voorraad in magazijn {{warehouse}} bedraagt slechts {{stock}}',
        tasks: 'Taken',
        materials: 'Materialen',
        notAvailable: 'Niet beschikbaar',
        printLabel: { label: 'Print label' },
        code: { label: 'Code' },
        description: { label: 'Beschrijving' },
        checklist: { label: 'Checklist' },
        requiredQuantity: { label: 'Benodigde hoeveelheid' },
        required: { label: 'Vereist' },
        finished: { label: 'Finished' },
        backflush: { label: 'Backflush' },
        batchScan: { label: 'Batchscan' },
        number: { label: 'No.' },
        requiredForThisBatch: { label: 'Vereist voor deze batch' },
        issued: { label: 'Uitgegeven' },
        noWorkstationLinked: 'Niet gekoppeld aan een werkstation',
        noTasks: 'Geen gekoppelde taken',
        noMaterials: 'Geen gekoppelde materialen',
        defaultLocation: 'Standaardlocatie: {{locationCode}}',
        confirmDeletion: {
          header: 'U probeert gescande batches voor stuklijstartikelen te verwijderen {{description}}!',
          content: 'Weet u zeker dat u door wilt gaan met deze actie?\n Zo ja, dan moet u uitgegeven materialen ook handmatig terugzetten in Exact!',
          confirmButton: 'VERWIJDEREN',
        },
        startStopTime: {
          label: 'Start of stop met werken',
          taskAlreadyFinished: 'Taak al voltooid!!!',
          workedTime: 'Je hebt al {{time}} aan deze taak gewerkt',
        },
      },
      subBatches: {
        image: 'Afbeelding',
        articleCode: 'Artikel Code',
        articleName: 'Artikel Beschrijving',
        planned: '# gepland',
        nested: '# genest',
        collected: '# gecollecteerd',
        superProductionRequest: 'PR order ID',
      },
      slug: {
        label: 'Printreferentie',
        info:
          'Deze waarde wordt gebruikt om te refereren naar dit veld vanuit print templates. Kan enkel de tekens A-Z, a-z, 0-9 en _ bevatten en mag niet beginnen met een cijfer.',
      },
      storageLocations: { label: 'Opslaglocatie(s) (Hoeveelheid)' },
    },
  },
  batch: {
    batchInfoModal: {
      trigger: 'BATCHINFO',
      noBatches: 'Geen batches beschikbaar',
    },
    scanToPerformModal: {
      trigger: 'SCAN OM UIT TE VOEREN',
    },
    overview: {
      title: 'Batch Geschiedenis',
      empty: 'Er zijn geen batches die voldoen aan uw zoekopdracht.',
      moreMetavalues: '{{count}} meer metawaarde(s)',
    },
    field: {
      batchType: { label: 'Type' },
      productionRequest: { label: 'Werkorder ID' },
      productionOrder: { label: 'Productieorder ID' },
      inShipment: { label: 'Ontvangstorder ID' },
      outShipment: { label: 'Verzamelorder ID' },
      warehouseTransfer: { label: 'Voorraadverplaatsing ID' },
      purchaseOrderNumberERP: { label: 'Inkooporder ERP' },
      salesOrderNumberERP: { label: 'Verkooporder ERP' },
      productionOrderNumberERP: { label: 'Productieorder ERP' },
      orderNumberERP: { label: 'ERP-Order' },
      blocked: {
        label: 'Geblokkeerd?',
        title: 'Batchblokkering',
        info: 'This batch is blocked',
        blocked: 'Deze batch is geblokkeerd',
        unblocked: 'Niet geblokkeerd',
      },
      blockReason: {
        label: 'Reden',
      },
      storageLocation: {
        label: 'Opslaglocatie',
        shortLabel: 'Opslag',
        empty: 'Geen gekoppelde opslaglocatie',
      },
      warehouse: {
        label: 'Magazijn',
        empty: 'Geen gekoppeld magazijn',
      },
      batchUseds: {
        label: 'Hoofdproduct',
        empty: 'Dit product heeft geen verdere Hoofdproducten.',
      },
      batchUsings: {
        label: 'Subassemblages',
        empty: 'Dit product heeft geen subassemblages.',
      },
      details: {
        label: 'Details',
        empty: 'Dit product heeft geen details.',
        bestBeforeDate: { shortLabel: 'Tht' },
      },
      performances: {
        label: 'Uitgevoerde Stappen',
        empty: 'Dit product heeft geen uitgevoerde stappen.',
        description: '{{step}} uitgevoerd door {{operator}} op {{createdAt}}',
        printedDocument: 'Gedrukt document',
      },
      scrapReason: { label: 'Scrapreden' },
      serialNumber: { label: 'Serienummer' },
      finished: { label: 'Klaar' },
      finalizedAt: { label: 'Afgemaakt Op' },
      scrapped: { label: 'Afgekeurd' },
      loadCarrier: { label: 'Ladingdrager' },
      metavalues: { label: 'Metawaardes' },
      quantityRemaining: { label: 'Aantal Over', shortLabel: 'Aantal' },
      createdAt: { label: 'Gemaakt op' },
      quantity: { label: 'Aantal' },
    },
    button: {
      rework: { label: 'Herwerk batch' },
      batchesView: { label: 'Geschiedenisdossier' },
      export: {
        label: 'Exporteer batches',
        fileName: 'batch_export',
      },
    },
    filter: {
      dateCreated: 'Datum Gemaakt',
      dateFinished: 'Datum Klaar',
      dateReceived: 'Datum Ontvangen',
      articleTypeCode: 'Artikel Code',
      articleTypeName: 'Artikel Name',
      details: 'Details',
      bomItems: 'Stuklijst Artikelen'
    }
  },
  subassembly: {
    overview: {
      title: 'Subassemblages',
      empty: 'Er zijn geen subassemblages die voldoen aan uw zoekopdracht.',
    },
    field: {
      parentBatch: { label: 'Hoofdproduct' },
      parentSubassembly: { label: 'Hoofdsubassemblage' },
      subassemblies: {
        label: 'Subassemblages',
        empty: 'Deze subassemblage heeft geen subassemblages.',
      },
      details: {
        label: 'Details',
        empty: 'Deze subassemblage heeft geen details.',
      },
      performances: {
        label: 'Uitgevoerde Stappen',
        empty: 'Deze subassemblage heeft geen uitgevoerde stappen.',
        description: '{{step}} uitgevoerd door {{operator}} op {{createdAt}}',
      },
      serialNumber: { label: 'Serienummer' },
      finished: { label: 'Klaar' },
    },
  },
  progress: {
    title: 'Voortgangsoverzicht',
    titleNests: 'Voortgangsoverzicht ({{open}} open) ({{inProgress}} bezig)',
    empty: 'Er zijn nog geen productieopdrachten voor deze week.',
    processType: {
      label: 'Process Type',
      value: {
        make: 'Hoofdproces Maak',
        buy: 'Subproces Ontvangst',
        sell: 'Subproces Verzamel',
        transfer_line: 'Subproces Verplaatsingsregel',
        stock_count: 'Hoofdproces Voorraadtelling',
        transfer: 'Hoofdproces Verplaatsing',
        pick_order: 'Hoofdproces Verzamel',
        receive_order: 'Hoofdproces Ontvangst',
      },
    },
    productionLine: {
      stat: {
        todo: 'Te Doen',
        inProgress: 'Bezig',
        done: 'Klaar',
        operators_one: 'Operator',
        operators_other: 'Operators',
      },
    },
    weekPicker: 'Week',
    monthPicker: 'Maand',
    dayPicker: 'Dag',
    processVersion: 'v{{version}}',
    groupBy: {
      label: 'Groeperen Op',
      value: {
        workStation: 'Werkplek',
        step: 'Stap',
      },
    },
    bulkActionButton: {
      choose: 'Kies bulkactie ({{count}})'
    },
    nestingStepType: {
      label: 'Export/Import Stap',
      export: 'Export stap',
      import: 'Import stap',
    },
    plannedOnInformation: {
      start: 'Start',
      actual: 'Werkelijk',
      duration: 'Tijdsduur',
      delivery: 'Bezorging',
    },
    workingOperators: {
      none: 'Er zijn geen operators aan het werk aan deze order',
      working: 'Operator(s) {{operatorNames}} zijn aan het werk aan deze order'
    }
  },
  detail: {
    field: {
      value: { label: 'Waarde' },
      reason: { label: 'Reden' },
    },
  },
  targetInfoModal: {
    title: 'Geschiedenisdossier',
    scrapped: 'Afgekeurd: {{reason}}',
    variableQuantity: 'Variabele Grootte',
    reprint: {
      title: 'Herprint',
      quantity: 'Aantal Kopieën',
      printButton: 'Print',
    },
    materialPlanTaskField: {
      performed: 'Uitgevoerd',
      amountRequired: 'Hoeveelheid nodig',
      description: 'Beschrijving',
      usedBatches: 'Gebruikte Batches',
      quantity: 'Gebruikt/Verwacht',
    },
    loadCarrier: { label: 'Gescande ladingdrager' },
  },
  serialNumberFormat: {
    part: {
      text: {
        label: 'Tekst',
        content: 'Inhoud',
      },
      date: {
        label: 'Datum',
        part: {
          label: 'Onderdeel',
          year: { label: 'Jaar' },
          month: {
            label: 'Maand',
            text: 'Tekst',
            names: {
              january: {
                label: 'Januari',
                value: 'JAN',
              },
              february: {
                label: 'Februari',
                value: 'FEB',
              },
              march: {
                label: 'Maart',
                value: 'MAA',
              },
              april: {
                label: 'April',
                value: 'APR',
              },
              may: {
                label: 'Mei',
                value: 'MEI',
              },
              june: {
                label: 'Juni',
                value: 'JUN',
              },
              july: {
                label: 'Juli',
                value: 'JUL',
              },
              august: {
                label: 'August',
                value: 'AUG',
              },
              september: {
                label: 'September',
                value: 'SEP',
              },
              october: {
                label: 'Oktober',
                value: 'OKT',
              },
              november: {
                label: 'November',
                value: 'NOV',
              },
              december: {
                label: 'December',
                value: 'DEC',
              },
            },
          },
          day: { label: 'Dag' },
          isoyear: { label: 'ISO-Jaar' },
          isoweek: { label: 'ISO-Week' },
          isoweekday: {
            label: 'ISO-Weekdag',
            text: 'Tekst',
            names: {
              monday: {
                label: 'Maandag',
                value: 'MA',
              },
              tuesday: {
                label: 'Dinsdag',
                value: 'DI',
              },
              wednesday: {
                label: 'Woensdag',
                value: 'WO',
              },
              thursday: {
                label: 'Donderdag',
                value: 'DO',
              },
              friday: {
                label: 'Vrijdag',
                value: 'VR',
              },
              saturday: {
                label: 'Zaterdag',
                value: 'ZA',
              },
              sunday: {
                label: 'Zondag',
                value: 'ZO',
              },
            },
          },
        },
        format: 'Format',
      },
      code: {
        label: 'Code',
        alphabet: 'Alfabet',
        digits: 'Aantal',
        expand: 'Uitbreiden',
        defaultAlphabets: {
          decimal: 'Dec',
          binary: 'Bin',
          hexadecimalLower: 'Hex (a)',
          hexadecimalUpper: 'Hex (A)',
          octal: 'Oct',
          alphanumericLower: 'Alfanum (a)',
          alphanumericUpper: 'Alfanum (A)',
          alphabeticLower: 'Alfa (a)',
          alphabeticUpper: 'Alfa (A)',
          custom: 'Anders, namelijk:',
        },
      },
      anything: {
        label: 'Alles',
      },
      article_type: {
        label: 'Artikeltype Eigenschap',
        prop: {
          label: 'Eigenschap',
          code: 'Code',
          barcode: 'Barcode',
          metafield: 'Metaveld',
        },
      },
    },
  },
  performanceResetModal: {
    title: 'Product Terugzetten',
    reason: 'Reden',
    resetButton: 'Terugzetten',
    createNewBatch: 'Maak nieuwe batch',
    printButton: 'Terugzetten en label printen',
    carrierWarning: 'Als je deze batch terugzet, worden verzendlabels verwijderd in Tracy. De gerelateerde aanvragen moeten handmatig in de ladingdrager teruggezet worden.',
  },
  textPart: {
    field: {
      text: {
        placeholder: 'Geen tekst ingevuld.',
      },
    },
  },
  imagePart: {
    field: {
      subtitle: {
        placeholder: 'Geen ondertitel ingevuld.',
      },
    },
  },
  metaPart: {
    field: {
      metafield: { label: 'Metaveld' },
    },
  },
  printerSetup: {
    connectionError: 'Error tijdens het verbinden met de printer, ',
    connectionNameError: 'Error tijdens het verbinden met de printer, deze printer naam is niet bekend',
    connectionPdfError: 'Error tijdens het ophalen van het verstuur label',
    title: 'Printer Setup',
    installQzTray: {
      beforeLink: 'Installeer ',
      link: 'QZ Tray',
      afterLink: '.',
    },
    downloadCertificate: {
      beforeLink: 'Download ',
      link: 'dit bestand',
      beforeLocation: ' en sla het op als ',
      afterLocation: '.',
    },
    editProperties: {
      beforeLocation: 'Voeg de volgende regel toe aan eind van het bestand ',
      afterLocation: ':',
    },
    testPrinter: {
      beforeButtons: 'Test of de printer werkt:',
      printer: {
        altec: 'Altec',
        zebra: 'Zebra',
      },
    },
    printerModal: {
      title: 'Test Print',
      selectPrinter: 'Selecteer Printer',
      noPrintersAvailable: 'Geen Printers Beschikbaar',
      printButton: 'Print',
    },
  },
  workSlot: {
    field: {
      monday: { label: 'Ma' },
      tuesday: { label: 'Di' },
      wednesday: { label: 'Wo' },
      thursday: { label: 'Do' },
      friday: { label: 'Vr' },
      saturday: { label: 'Za' },
      sunday: { label: 'Zo' },
      isBreak: { label: 'Is Pauze' },
    },
  },
  workCalendar: {
    scope: {
      type: {
        week: { label: 'Week' },
        month: { label: 'Maand' },
      },
    },
    slot: {
      planned: 'Ingepland',
      overtime: 'Overgewerkt',
      absent: 'Afwezig',
      break: 'Pauze',
    },
    leaveSlotModal: {
      title: {
        add: 'Verlof Toevoegen',
        edit: 'Verlof Aanpassen',
      },
    },
    workTimesModal: {
      title: 'Werktijden {{scope}}',
    },
    total: {
      planned: '{{time}} gepland',
      actual: '{{time}} gewerkt',
      overtime: '{{time}} overgewerkt',
      absence: '{{time}} afwezig',
    },
    timeBalance: {
      title: 'Tijdsbalans',
    },
  },
  leaveSlot: {
    field: {
      window: { label: 'Periode' },
      fullDay: { label: 'Volledige Dag' },
      name: { label: 'Reden' },
      user: { label: 'Gebruiker' },
      operator: { label: 'Operator' },
      type: {
        label: 'Type',
        value: {
          vacation: 'Vakantie',
          rwh: 'ADV',
          special: 'Bijzonder',
          exchange: 'Tijd voor tijd',
        },
        valueShort: {
          vacation: 'VAK',
          rwh: 'ADV',
          special: 'BIJ',
          exchange: 'TVT',
        },
        valueMinutes: {
          vacation: 'Vakantie',
          rwh: 'ADV',
          special: 'Bijzonder verlof',
          exchange: 'Tijd voor tijd',
        },
      },
      status: {
        label: 'Status',
        value: {
          approved: 'Goedgekeurd',
          pending: 'In afwachting',
          rejected: 'Afgekeurd',
        },
      },
      startDate: { label: 'Startdatum' },
      endDate: { label: 'Einddatum' },
    },
    overview: {
      title: 'Verlofaanvragen',
      noneFound: 'Er zijn geen verlofaanvragen gevonden.',
      noneSelected: 'Er is nog geen verlofaanvraag geselecteerd.',
      hasComments: 'Heeft opmerkingen',
      availabilityHeader: 'Beschikbare Operator-uren',
      statusAction: {
        approved: 'Goedkeuren',
        pending: 'In behandeling nemen',
        rejected: 'Afkeuren',
      },
    },
    edit: {
      comments: {
        empty: 'Er zijn nog geen opmerkingen geplaatst.',
      },
      stats: {
        allowed: 'Totaal',
        used: 'Gebruikt',
        available: 'Beschikbaar',
        requested: 'Aangevraagd',
      },
      days: {
        legend: {
          full: 'Volledige Beschikbaarheid',
          approved: 'Indien Goedgekeurd',
          rejected: 'Indien Afgekeurd',
        },
      },
      legal: 'Wettelijk',
      extra: 'Bovenwettelijk',
    },
  },
  leaveCalendar: {
    overview: {
      title: 'Verlofkalenders',
      empty: 'Er zijn geen verlofkalenders die voldoen aan uw zoekopdracht.',
      addButton: 'Verlofkalender',
    },
    create: {
      title: 'Verlofkalender Aanmaken',
    },
    edit: {
      title: 'Verlofkalender Aanpassen',
      saveBeforeLeaveSlot: 'Je moet de verlofkalender eerst opslaan voordat je verlof toe kan voegen.',
    },
    field: {
      name: { label: 'Naam' },
      users: { label: 'Users' },
      operators: { label: 'Operators' },
      leaveSlots: { label: 'Verlof' },
    },
  },
  operatorCapability: {
    field: {
      level: {
        label: 'Level',
        value: {
          1: 'Niet vaardig',
          2: 'Lerend',
          3: 'Vaardig',
          4: 'Expert',
        },
      },
    },
  },
  process: {
    overview: {
      title: 'Processen',
      addButton: 'Proces',
      empty: 'Er zijn geen processen die voldoen aan uw zoekopdracht.',
    },
    edit: {
      newBatch: {
        label: 'Nieuwe Batch',
        info: 'Deze stap uitvoeren zal een nieuwe batch aanmaken, deze velden geven enkele details over deze batch.',
      },
      versioned: {
        label: '(versies)',
        info: 'Deze waarde is alleen van toepassing op de huidig geselecteerde versie.',
      },
      baseFields: {
        label: 'Basisvelden',
        info: 'Deze velden behoren niet tot een versie maar zijn toepasselijk op elke versie.',
      },
      batchSize: {
        label: 'Batchgrootte',
        value: {
          productionRequest: 'Ordergrootte',
          unit: 'Eenheid',
          batch: 'Batch van {{count}}',
        },
        error: {
          notSmaller: 'Batchgrootte moet kleiner zijn dan de voorgaande.',
          notFinal: 'Batchgrootte moet gelijk zijn aan {{expected}}.',
        },
      },
      title: 'Proces Aanpassen',
      version: 'Versie {{version}}',
      finalized: {
        label: 'Afgerond op: {{date}}',
        by: 'Afgerond door: {{user}}',
      },
      updated: {
        label: 'Bijgewerkt op: {{date}}',
        by: 'Bijgewerkt door: {{user}}',
      },
      noUpdateInfo: 'Geen informatie beschikbaar over wanneer deze versie voor het laatst is bijgewerkt.',
      draft: 'Concept',
      finalizeButton: 'Afronden',
      nextVersionButton: 'Volgende Versie',
      noStepSelected: 'Geen stap geselecteerd',
      noBatchTypeSelected: 'Geen proces geselecteerd',
      workStationsModal: {
        title: 'Selecteer Werkplekken',
      },
      tabs: {
        steps: 'Stappen',
        instructions: 'Instructies',
        articleTypes: 'Artikeltypes',
      },
      addArticleTypeModal: {
        title: 'Artikeltypes Toevoegen',
        addButton: 'Toevoegen ({{count}})',
      },
      harvestDay: {
        monday: 'Ma',
        tuesday: 'Di',
        wednesday: 'Wo',
        thursday: 'Do',
        friday: 'Vr',
        saturday: 'Za',
        sunday: 'Zo',
      },
    },
    create: {
      title: 'Proces Aanmaken',
    },
    field: {
      name: { label: 'Name' },
      newestVersion: { label: 'Nieuwste Versie' },
    },
  },
  processVersion: {
    field: {
      productionLineVersion: { label: 'Productielijn' },
      batchType: { label: 'Proces' },
      version: { label: 'Versie' },
      newest: { label: 'Nieuwste' },
      factory: { label: 'Fabriek' },
    },
  },
  dataSource: {
    edit: {
      title: 'Databron Aanpassen',
    },
    create: {
      title: 'Databron Aanmaken',
    },
    overview: {
      title: 'Databronnen',
      empty: 'Er hebben zich nog geen databronnen aangemeld.',
    },
    field: {
      uuid: { label: 'UUID' },
      identifier: { label: 'Identificatiecode' },
      name: { label: 'Naam' },
      deviceName: { label: 'Apparaatnaam' },
      description: { label: 'Omschrijving' },
      workStations: { label: 'Gekoppelde werkplekken' },
      data: {
        label: 'Data',
        name: 'Naam',
        type: 'Type',
      },
    },
  },
  exactShopOrder: {
    field: {
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          released: 'Vrijgegeven',
          in_progress: 'In Bewerking',
          finalized: 'Afgerond',
          complete: 'Compleet',
        },
      },
    },
  },
  loadCarrier: {
    overview: {
      title: 'Ladingdragers',
      empty: 'Er zijn geen ladingdragers die voldoen aan uw zoekopdracht.',
      addButton: 'Ladingdrager',
      batchAddButton: 'Batch Toevoegen',
      bulkEditModal: {
        title: 'Stel Toegestane Artikeltypen in',
        selectButton_one: 'Stel {{count}} Artikeltype in als Toegestaan',
        selectButton_other: 'Stel {{count}} Artikeltypes als Toegestaan',
        selectAllButton: 'Stel alle {{count}} Artikeltypes als Toegestaan',
        notAllowedFailures: 'De volgende ladingdragers bevatten een batch met niet toegestane artikel types: "{{serials}}".',
      }
    },
    create: {
      title: 'Ladingdrager Aanmaken',
    },
    edit: {
      title: 'Ladingdrager Aanpassen',
      weighing: '{{weight}}{{unit}}, gewogen door {{user}} op {{time}}.',
      unknownUser: 'onbekend',
      batchErrors: {
        notFound: 'Geen batch gevonden met dit serienummer.',
        alreadyUsed: 'De batch met dit serienummer wordt door een andere ladingdrager gebruikt, met serienummer "{{serial_number}}".',
        deleted: 'Deze ladingdrager is verwijderd',
        articleTypeNotAllowed: 'Batch moet een toegestane artikeltype hebben voor deze ladingdrager',
      },
    },
    batchAdd: {
      title: 'Ladingdragers Batch Toevoegen',
      quantity: 'Aantal',
      serialNumberFormat: 'Serienummer Format',
    },
    field: {
      type: {
        label: 'Type',
        value: {
          pallet_euro: 'Europallet',
          pallet_pool: 'Poolpallet',
          container: 'Container',
          gitterbox: 'Gitterbox',
          carton_box: 'Kartonnen Doos',
          stackbox: 'Stapel Doos',
        },
      },
      serialNumber: { label: 'Serienummer' },
      name: { label: 'Naam' },
      description: { label: 'Beschrijving' },
      weight: { label: 'Gewicht' },
      width: { label: 'Breedte' },
      height: { label: 'Hoogte' },
      length: { label: 'Lengte' },
      volume: { label: 'Volume' },
      picture: { label: 'Foto' },
      batch: { label: 'Batch' },
      hasBatch: { label: 'Heeft Batch' },
      batchCreatedAt: { label: 'Batch Gemaakt Op' },
      weighings: { label: 'Wegingen' },
      reweighDays: {
        label: 'Weginginterval',
        info: 'Hier kun je aangeven hoe vaak een ladingdrager gewogen moet worden. In het ladingdrager overzicht kun je dan zien wanneer een ladingdrager opnieuw gewogen moet worden en filteren op ladingdragers die gewogen moeten worden.',
        suffix: ' dagen',
      },
      reweighDate: { label: 'Herweegdatum' },
      needsReweighing: { label: 'Moet Herwogen Worden' },
      linkedOrders: {
        label: 'Gekoppelde orders',
        labelErp: 'Gekoppelde ERP orders',
      },
      deliveryDetails: { label: 'Bezorgdetails' },
      allowedArticleTypes: {
        label: 'Toegestane Artikeltypes',
        info: 'Alleen batches met een van deze artikelen mag op de ladingdrager geladen worden. Als er geen artikelen geselecteerd zijn, mag ieder artikel op de ladingdrager geladen worden.',
        value_one: 'Toegestaan voor {{count}} artikeltype.',
        value_other: 'Toegestaan voor {{count}} artikeltype.',
      },
      allowLoadingSameProcessType: {
        label: 'Laden van batch met zelfde type is toegestaan',
        info: 'Als dit uit staat, mag de ladingdrager geen batch van hetzelfde type laden als de batch die al op de ladingdrager staat.',
      },
    },
  },
  login: {
    workstation: 'Werkstationcode',
    barcode: 'Artikel-barcode',
    title: 'Tracy Picker',
    username: 'Gebruiker',
    batchcode: 'Batchcode',
    notAllowed: 'Dit is geen geldige werkstationcode',
    password: 'Wachtwoord',
    forgotPassword: 'Wachtwoord vergeten?',
    clearusername: 'Wissen gebruiker',
    clearworkstation: 'Wissen werkstationcode',
    clearprinter: 'Printergegevens wissen',
    forgotMailSend: 'Email verstuurd',
    successful: 'Succesvol ingelogd',
    logIn: 'Log In',
    error: 'Fout bij inloggen',
    wrong_credentials: 'Geen geldige badgecode',
  },
  shipping: {
    picture: 'Foto van de lading',
    picture_accept: 'Accepteer foto',
    picture_decline: 'Foto opnieuw nemen',
    packed: 'Verpakken afronden',
    start_send: 'Start verzendproces',
    start_receive: 'Start ontvangstproces',
    send_shipment: 'Verstuur de levering',
    receive_shipment: 'Ontvang levering',
  },
  articleInfo: {
    clearBarcode: 'Barcode wissen',
    search: 'Zoek naar barcode',
    wrongCode: 'Kon barcode niet vinden in het system',
    notAllowed: 'Deze gebruiker mag dit product niet bekijken',
    error: 'Fout tijdens het zoeken naar de barcode',
  },
  shipment: {
    articleInfo: {
      performanceLine:
        '{{stepLabel}} van process {{productionLineName}} uitgevoerd om {{performanceTime}} door {{performanceBy}}',
      batchType: 'Batchtype',
      batchDescription: 'Batchomschrijving',
      batchSize: 'Batchgrootte',
      articleNameAndCode: 'Artikelnaam en Exactcode',
      scannedBarcode: 'Gescande Barcode',
    },
    picker: {
      default: 'Selecteer een waarde',
    },
    shipmentInfo: 'Orderinformatie',
    status: {
      picking: 'Picking',
      printing: 'Printen',
      sending: 'Versturen',
      sent: 'Verzonden',
      toReceive: 'Te Ontvangen',
      received: 'Ontvangen en verwerkt',
    },
    field: {
      salesOrder: {
        label: 'Verkooporder',
      },
      purchaseOrder: { label: 'Inkooporder' },
      receiveDate: { label: 'Geplande leverdatum' },
      receivedTime: { label: 'Levertijd' },
      shippingDate: {
        label: 'Verstuur datum',
      },
      freightType: {
        label: 'Vrachtsoort',
        value: {
          road: 'Land',
        },
      },
      freightCompany: {
        label: 'Transportbedrijf',
      },
      shippingName: {
        label: 'Naam Chauffeur',
      },
      shippingLicense: {
        label: 'Kenteken Vrachtwagen',
      },
      shippingTime: {
        label: 'Tijd van Versturen',
      },
      quantity: {
        label: 'Aantal',
      },
      batchSize: {
        label: 'Batchgrootte',
      },
    },
    outbound: {
      pickitem: 'Verzamelartikelen',
      printreceipt: 'Afdrukken pakbon',
      package: 'Verpakken',
      printshipmentlabel: 'Afdrukken verzendetiket',
      send: 'Versturen',
    },
    inbound: {
      receiveItem: 'Ontvang bestelling',
      barcodeCreation: 'Identificeren producten',
      warehousePlacement: 'Plaatsing in het magazijn',
    },
    heading: {
      cmrLines: 'CMR LINES',
    },
    titleInbound: 'Ontvangstorder {{id}}',
    titleOutbound: 'Verzendorder {{id}}',
    confirmShipment: 'Weet je zeker dat je {{custName}} een zending wilt sturen met ordernummer {{trackingNumber}}?',
    confirmReceive:
      'Weet je zeker dat je van {{custName}} een zending wilt accepteren met ordernummer {{trackingNumber}}?',
    sendShipmentTitle: 'Versturen',
    receiveShipmentTitle: 'Ontvangen',
    scan: 'Scan',
    leftToPick: 'Te picken',
    actualyPicked: 'Picked',
    documents: {
      label: 'Documenten',
      packingListRequired: 'Pakbon',
      invoiceRequired: 'Factuur',
      eur1Required: 'EUR1',
      cvoRequired: 'C/O',
    },
    tab: {
      shipmentLines: 'Artikelen',
      info: 'Info',
    },
    noCmrLines: 'Deze verzending heeft geen verzendregels.',
    box: 'Doos {{id}}',
    buyBox: 'Doos',
    hiddenBoxes: '{{count}} Dozen',
    error: {
      wrongArticle: 'Verkeerd artikel.',
      alreadyScanned: 'Doos al gescand.',
      alreadyShipped: 'Doos al gebruikt in andere order.',
      tooManyProducts: 'Deze doos bevat teveel producten.',
      invalidQuantity: 'Niet bruikbare hoeveelheid voor dit aantal.',
      wrongLastStep: 'Verkeerde laatste stap.',
    },
  },
  common: {
    article: {
      field: {
        code: { label: 'Code' },
        name: { label: 'Naam' },
      },
    },
    shipmentLine: {
      field: {
        quantity: { label: 'Aantal' },
      },
    },
    shipment: {
      field: {
        salesOrder: { label: 'Verkooporder' },
        purchaseOrder: { label: 'Inkooporder' },
        shippingDate: { label: 'Verzenddatum' },
        receiveDate: { label: 'Geplande Leverdatum' },
        shippingName: { label: 'Naam' },
        shippingTime: { label: 'Verzendtijd' },
        receivedTime: { label: 'Ontvangsttijd' },
        shippingLicense: { label: 'Kenteken' },
        freight: {
          label: 'Vrachtsoort',
          value: {
            road: 'Weg',
            air: 'Lucht',
            sea: 'Zee',
            parcel: 'Express',
          },
        },
        freightCompany: { label: 'Transporteur' },
      },
    },
    cmrLine: {
      field: {
        mark: { label: 'Regel' },
        number: { label: 'Nummmer' },
        method: { label: 'Methode' },
        nature: { label: 'Aard' },
        weight: { label: 'Gewicht' },
        cubage: { label: 'Inhoud' },
      },
    },
    loading: 'Laden...',
    error: {
      somethingWentWrong: 'Er ging iets verkeerd.',
    },
  },
  workTime: {
    field: {
      date: { label: 'Datum' },
      startTime: { label: 'Begintijd' },
      endTime: { label: 'Eindtijd' },
      automaticallyClockedOut: { label: 'Automatisch uitgeklokt' },
    },
  },
  softdeleted: {
    label: 'Verwijderde items tonen',
    value: {
      true: 'ja',
      false: 'nee',
      only: 'alleen',
    },
  },
  logistics: {
    erpCounts: {
      header: {
        date: 'Datum',
        quantity: '#',
        description: 'Omschrijving',
      },
    },
  },
  extraLeaveBalance: {
    field: {
      year: { label: 'Van toepassing op (jaar)' },
      legalLeaveMinutes: { label: 'Wettelijk' },
      extraLeaveMinutes: { label: 'Bovenwettelijk' },
      rwhMinutes: { label: 'ADV' },
      remarks: { label: 'Opmerking' },
    },
  },
  extraTimeBalance: {
    field: {
      year: { label: 'Van toepassing op (jaar)' },
      balance: { label: 'Saldo' },
      isAbsence: { label: 'Is afwezigheid?' },
      remarks: { label: 'Opmerking' },
    },
  },
  formField: {
    label: { placeholder: 'Field Title' },
    name: { label: 'Formulier Velden' },
    field: {
      slug: { label: 'Printslug' },
      articleType: {
        self: '(Dynamisch)',
        selfInfo: 'Hierbij wordt automatisch het artikeltype ingevuld waar deze template gebruikt wordt.',
      },
      articleTypeAcceptBlocked: { label: 'Alleen geblokkeerde batches accepteren?' },
      type: {
        quantityInfo: 'Dit type kan gebruikt worden om een precieze grootte te zetten op een variabele batch. Je kunt ook een gewicht per eenheid ingeven, in dit geval zal de grootte berekend worden aan de hand van een gewicht dat de operator invoert.',
        bestBeforePeriodInfo: 'Dit type kan gebruikt worden om de tenminste houdbaar tot datum te specificeren. De tenminste houdbaar tot datum wordt de productie datum plus het aantal dagen gespecificeerd in de "Ten minste houdbaar tot periode".',
      },
      measureMin: { label: 'Minimum' },
      measureMax: { label: 'Maximum' },
      quantityUnitWeight: { label: 'Gewicht per eenheid' },
      articleTypeBatchSize: { label: 'Batchmaat?' },
      required: { label: 'Vereist' },
      scanConstraints: {
        add: 'Voeg een scan voorwaarde toe.',
      },
      storageLocation: {
        addRule: 'Voeg een voorgestelde locatieregel toe',
        finalOrdering: {
          direction: {
            ascending: { label: 'Oplopend' },
            descending: { label: 'Descending' },
          },
          free_capacity: { label: 'Beschikbare Ruimte' },
          sequence_number: { label: 'Volgnummer' },
        },
        rule: {
          placeholder: 'Selecteer Regel',
          label: 'Regel\xa0#{{number}}',
          fits: { label: 'Batch moet op de locatie passen' },
          preferred: { label: 'De locatie moet de voorkeur zijn van het artikeltype' },
          capacity: { label: 'Deze locatie moet een gedefinieerde capaciteit hebben' },
          enough_stock: { label: 'Deze locatie heeft voldoende voorraad voor deze batch' },
        },
      },
      bestBefore: {
        period: 'Ten minste houdbaar tot periode',
        date: 'Ten minste houdbaar tot datum',
        remaining: 'Ten minste houdbaar tot resterende dagen',
      },
      materialPlanTaskIsTimed: {
        label: 'Houd tijd bij voor deze taaklijst.',
      },
    },
  },
  scanConstraint: {
    field: {
      operator: {
        value: {
          eq: 'Is gelijk aan',
          neq: 'Is niet gelijk aan',
          lt: 'Minder dan',
          lte: 'Minder dan of gelijk aan',
          gt: 'Groter dan',
          gte: 'Groter dan of gelijk aan',
        },
      },
    },
  },
  integration: {
    overview: {
      title: 'Integraties',
      empty: 'Geen integraties gevonden.',
      clearSyncErrorButton: 'Verwijder synchronisatiefouten',
      xmlUploadButton: 'Upload assembly bom als xml-bestand',
      addButton: 'Integratie',
      inactive: 'Deze integratie is niet actief.',
      XmlUploadModal: {
        title: 'Upload assemblybom als xml-bestand',
        confirmButton: 'Upload',
        allowRevertBack: 'Verander artikelen die niet in de xml zitten terug naar normaal?',
      },
      SyncFromScratchModal: {
        title: 'Synchroniseer helemaal opnieuw met Exact',
        warningHead: 'Weet u zeker dat u de volgende topics helemaal opnieuw wilt synchroniseren:',
        warningTail: 'Zorg dat je alleen de topics selecteert die je echt volledig opnieuw wilt synchroniseren. Het synchroniseren van topics die veel data kosten van Exact Online, zorgen voor een hoge syncrequest gebruik.',
        confirmButton: 'Synchroniseer',
      },
      pushWebhookUpdatesButton: 'Push Webhookupdates Nu',
    },
    create: {
      title: 'Integratie Aanmaken',
    },
    edit: {
      title: 'Integratie Aanpassen',
    },
    field: {
      type: {
        label: 'Type',
        value: {
          label: 'Soort integratie',
          exact: 'Exact Online',
          unit4: 'Unit 4',
          navision: 'Navision',
          exact_globe: 'Excat Globe',
          transmission: 'TransMission',
          jan_krediet: 'Jan Krediet',
          ups: 'UPS',
          radan: 'Radan',
          postnl: 'PostNL',
        },
      },
      id: { label: 'ID' },
      name: { label: 'Naam' },
      apiRequestsRemaining: { label: 'Resterende API-verzoeken' },
      apiRequestsRemainingDay: { label: 'vandaag' },
      apiRequestsRemainingMinute: { label: 'deze minuut' },
      lastPerformedAt: { label: 'Laatst uitgevoerd op' },
      pendingPerformances: { label: 'Onafgeronde performances' },
      syncErrorCount: { label: 'Synchronisatie fouten' },
      webhookBacklogCount: { label: 'Achterstallige webhook-updates' },
    },
    syncmodal: {
      title: 'Synchroniseer',
      syncButton: 'Sync',
      pending: 'In wachtrij',
      from_db: 'Cached',
      from_inline: 'Gedeeltelijk',
      created: 'Aangemaakt',
      updated: 'Bijgewerkt',
      requested: 'Aangevraagd',
      dependencies: 'Gelinkte objecten',
      measureMin: { label: 'Min' },
      measureMax: { label: 'Max' },
      quantityUnitWeight: { label: 'Gewicht per Eenheid' },
    },
    syncers: {
      exact_account_syncer: 'Accounts',
      exact_address_syncer: 'Addressen',
      exact_item_syncer: 'Items',
      exact_item_extra_field_syncer: 'Item Extra Velden',
      exact_project_syncer: 'Projecten',
      exact_purchase_order_syncer: 'Inkooporders',
      exact_sales_order_syncer: 'Verkooporders',
      exact_warehouse_syncer: 'Magazijnen',
      exact_storage_location_syncer: 'Opslaglocaties',
      exact_item_warehouse_syncer: 'Voorraden (per magazijn)',
      exact_item_warehouse_location_syncer: 'Voorraden (per locatie)',
      exact_goods_receipt_syncer: 'Inkoopbonnen',
      exact_warehouse_transfer_syncer: 'Magazijnverplaatsingen',
      exact_stock_position_syncer: 'Voorraadupdate',
      exact_shipping_method_syncer: 'Verzendmethodes',
      exact_document_syncer: 'Documenten',
    },
  },
  navisionIntegration: {
    field: {
      company: { label: 'Entiteit' },
      customer: { label: 'Klanten' },
      item: { label: 'Items' },
      jobs: { label: 'Jobs' },
      manageEndpointsHeader: { label: 'Configuratie' },
      productionOrder: { label: 'Productieorders' },
      purchaseOrder: { label: 'Inkooporders' },
      salesOrder: { label: 'Verkooporders' }
    },
    purchaseOrderLine: {
      field: {
        requestedReceiptDate: 'Gevraagde datum',
        promisedReceiptDate: 'Beloofde datum',
      }
    },
    overview: {
      syncButton: 'Sync'
    }
  },
  exactIntegration: {
    overview: {
      authButton: 'Activeer Exactintegratie',
      authResult: {
        ok: 'Exactintegratie succesvol geactiveerd.',
        error: {
          invalid_request: 'Onjuist verzoek verzonden naar Exact.',
          invalid_client: 'Onjuiste client verzonden naar Exact.',
          invalid_grant: 'Onjuiste grant verzonden naar Exact.',
          invalid_scope: 'Onjuiste scope verzonden naar Exact. ',
          unauthorized_client: 'Ongeauthorizeerde client verzonden naar Exact.',
          unsupported_grant_type: 'Niet ondersteund grant type verzonden naar Exact.',
        },
      },
      syncButton: 'Synchroniseer met Exact',
      syncFromScratchButton: 'Synchroniseer helemaal opnieuw',
      syncResult: {
        authFailure: { notification: 'Authenticatie met Exact gefaald.' },
        success: {
          notification: 'Succesvol gesynchroniseerd met Exact',
          count: {
            address: {
              tracy: {
                created_one: '{{count}} Adres in Tracy aangemaakt.',
                created_other: '{{count}} Adressen in Tracy aangemaakt.',
                updated_one: '{{count}} Adres in Tracy bijgewerkt.',
                updated_other: '{{count}} Adressen in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Adres in Exact aangemaakt.',
                created_other: '{{count}} Adressen in Exact aangemaakt.',
                updated_one: '{{count}} Adres in Exact bijgewerkt.',
                updated_other: '{{count}} Adressen in Exact bijgewerkt.',
              },
            },
            item: {
              tracy: {
                created_one: '{{count}} Artikel in Tracy aangemaakt.',
                created_other: '{{count}} Artikelen in Tracy aangemaakt.',
                updated_one: '{{count}} Artikel in Tracy bijgewerkt.',
                updated_other: '{{count}} Artikelen in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Artikel in Exact aangemaakt.',
                created_other: '{{count}} Artikelen in Exact aangemaakt.',
                updated_one: '{{count}} Artikel in Exact bijgewerkt.',
                updated_other: '{{count}} Artikelen in Exact bijgewerkt.',
              },
            },
            project: {
              tracy: {
                created_one: '{{count}} Project in Tracy aangemaakt.',
                created_other: '{{count}} Projecten in Tracy aangemaakt.',
                updated_one: '{{count}} Project in Tracy bijgewerkt.',
                updated_other: '{{count}} Projecten in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Project in Exact aangemaakt.',
                created_other: '{{count}} Projecten in Exact aangemaakt.',
                updated_one: '{{count}} Project in Exact bijgewerkt.',
                updated_other: '{{count}} Projecten in Exact bijgewerkt.',
              },
            },
            storage_location_stock_position: {
              tracy: {
                created_one: '{{count}} Opslaglocatie in Tracy aangemaakt.',
                created_other: '{{count}} Opslaglocaties in Tracy aangemaakt.',
                updated_one: '{{count}} Opslaglocatie in Tracy bijgewerkt.',
                updated_other: '{{count}} Opslaglocaties in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Opslaglocatie in Exact aangemaakt.',
                created_other: '{{count}} Opslaglocaties in Exact aangemaakt.',
                updated_one: '{{count}} Opslaglocatie in Exact bijgewerkt.',
                updated_other: '{{count}} Opslaglocaties in Exact bijgewerkt.',
              },
            },
            bill_of_material: {
              tracy: {
                created_one: '{{count}} Stuklijst in Tracy aangemaakt.',
                created_other: '{{count}} Stuklijsten in Tracy aangemaakt.',
                updated_one: '{{count}} Stuklijst in Tracy bijgewerkt.',
                updated_other: '{{count}} Stuklijsten in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Stuklijst in Exact aangemaakt.',
                created_other: '{{count}} Stuklijsten in Exact aangemaakt.',
                updated_one: '{{count}} Stuklijst in Exact bijgewerkt.',
                updated_other: '{{count}} Stuklijsten in Exact bijgewerkt.',
              },
            },
            bill_of_material_item: {
              tracy: {
                created_one: '{{count}} Stuklijstartikel in Tracy aangemaakt.',
                created_other: '{{count}} Stuklijstartikelen in Tracy aangemaakt.',
                updated_one: '{{count}} Stuklijstartikel in Tracy bijgewerkt.',
                updated_other: '{{count}} Stuklijstartikelen in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Stuklijstartikel in Exact aangemaakt.',
                created_other: '{{count}} Stuklijstartikelen in Exact aangemaakt.',
                updated_one: '{{count}} Stuklijstartikel in Exact bijgewerkt.',
                updated_other: '{{count}} Stuklijstartikelen in Exact bijgewerkt.',
              },
            },
            account: {
              tracy: {
                created_one: '{{count}} Relatie in Tracy aangemaakt.',
                created_other: '{{count}} Relaties in Tracy aangemaakt.',
                updated_one: '{{count}} Relatie in Tracy bijgewerkt.',
                updated_other: '{{count}} Relaties in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Relatie in Exact aangemaakt.',
                created_other: '{{count}} Relaties in Exact aangemaakt.',
                updated_one: '{{count}} Relatie in Exact bijgewerkt.',
                updated_other: '{{count}} Relaties in Exact bijgewerkt.',
              },
            },
            sales_order: {
              tracy: {
                created_one: '{{count}} Verkooporder in Tracy aangemaakt.',
                created_other: '{{count}} Verkooporders in Tracy aangemaakt.',
                updated_one: '{{count}} Verkooporder in Tracy bijgewerkt.',
                updated_other: '{{count}} Verkooporders in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Verkooporder in Exact aangemaakt.',
                created_other: '{{count}} Verkooporders in Exact aangemaakt.',
                updated_one: '{{count}} Verkooporder in Exact bijgewerkt.',
                updated_other: '{{count}} Verkooporders in Exact bijgewerkt.',
              },
            },
            purchase_order: {
              tracy: {
                created_one: '{{count}} Inkooporder in Tracy aangemaakt.',
                created_other: '{{count}} Inkooporders in Tracy aangemaakt.',
                updated_one: '{{count}} Inkooporder in Tracy bijgewerkt.',
                updated_other: '{{count}} Inkooporders in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Inkooporder in Exact aangemaakt.',
                created_other: '{{count}} Inkooporders in Exact aangemaakt.',
                updated_one: '{{count}} Inkooporder in Exact bijgewerkt.',
                updated_other: '{{count}} Inkooporders in Exact bijgewerkt.',
              },
            },
            shop_order: {
              tracy: {
                created_one: '{{count}} Productieorder in Tracy aangemaakt.',
                created_other: '{{count}} Productieorders in Tracy aangemaakt.',
                updated_one: '{{count}} Productieorder in Tracy bijgewerkt.',
                updated_other: '{{count}} Productieorders in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Productieorder in Exact aangemaakt.',
                created_other: '{{count}} Productieorders in Exact aangemaakt.',
                updated_one: '{{count}} Productieorder in Exact bijgewerkt.',
                updated_other: '{{count}} Productieorders in Exact bijgewerkt.',
              },
            },
            shop_order_material_plan: {
              tracy: {
                created_one: '{{count}} Productieorder Materiaal Plan in Tracy aangemaakt.',
                created_other: '{{count}} Productieorder Materiaal Plans in Tracy aangemaakt.',
                updated_one: '{{count}} Productieorder Materiaal Plan in Tracy bijgewerkt.',
                updated_other: '{{count}} Productieorder Materiaal Plans in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Productieorder Materiaal Plan in Exact aangemaakt.',
                created_other: '{{count}} Productieorder Materiaal Plans in Exact aangemaakt.',
                updated_one: '{{count}} Productieorder Materiaal Plan in Exact bijgewerkt.',
                updated_other: '{{count}} Productieorder Materiaal Plans in Exact bijgewerkt.',
              },
            },
            shop_order_receipt: {
              tracy: {
                created_one: '{{count}} Productieorder Ontvangst in Tracy aangemaakt.',
                created_other: '{{count}} Productieorder Ontvangsten in Tracy aangemaakt.',
                updated_one: '{{count}} Productieorder Ontvangst in Tracy bijgewerkt.',
                updated_other: '{{count}} Productieorder Ontvangsten in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Productieorder Ontvangst in Exact aangemaakt.',
                created_other: '{{count}} Productieorder Ontvangsten in Exact aangemaakt.',
                updated_one: '{{count}} Productieorder Ontvangst in Exact bijgewerkt.',
                updated_other: '{{count}} Productieorder Ontvangsten in Exact bijgewerkt.',
              },
            },
            shop_order_reversal: {
              tracy: {
                created_one: '{{count}} Productieorder Herroeping in Tracy aangemaakt.',
                created_other: '{{count}} Productieorder Herroepingen in Tracy aangemaakt.',
                updated_one: '{{count}} Productieorder Herroeping in Tracy bijgewerkt.',
                updated_other: '{{count}} Productieorder Herroepingen in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Productieorder Herroeping in Exact aangemaakt.',
                created_other: '{{count}} Productieorder Herroepingen in Exact aangemaakt.',
                updated_one: '{{count}} Productieorder Herroeping in Exact bijgewerkt.',
                updated_other: '{{count}} Productieorder Herroepingen in Exact bijgewerkt.',
              },
            },
            opportunity: {
              tracy: {
                created_one: '{{count}} Verkoopkans in Tracy aangemaakt.',
                created_other: '{{count}} Verkoopkansen in Tracy aangemaakt.',
                updated_one: '{{count}} Verkoopkans in Tracy bijgewerkt.',
                updated_other: '{{count}} Verkoopkansen in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Verkoopkans in Exact aangemaakt.',
                created_other: '{{count}} Verkoopkansen in Exact aangemaakt.',
                updated_one: '{{count}} Verkoopkans in Exact bijgewerkt.',
                updated_other: '{{count}} Verkoopkansen in Exact bijgewerkt.',
              },
            },
            quotation: {
              tracy: {
                created_one: '{{count}} Offerte in Tracy aangemaakt.',
                created_other: '{{count}} Offertes in Tracy aangemaakt.',
                updated_one: '{{count}} Offerte in Tracy bijgewerkt.',
                updated_other: '{{count}} Offertes in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Offerte in Exact aangemaakt.',
                created_other: '{{count}} Offertes in Exact aangemaakt.',
                updated_one: '{{count}} Offerte in Exact bijgewerkt.',
                updated_other: '{{count}} Offertes in Exact bijgewerkt.',
              },
            },
            goods_receipt: {
              tracy: {
                created_one: '{{count}} Goederen Ontvangst in Tracy aangemaakt.',
                created_other: '{{count}} Goederen Ontvangsten in Tracy aangemaakt.',
                updated_one: '{{count}} Goederen Ontvangst in Tracy bijgewerkt.',
                updated_other: '{{count}} Goederen Ontvangsten in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Goederenontvangst in Exact aangemaakt.',
                created_other: '{{count}} Goederenontvangsten in Exact aangemaakt.',
                updated_one: '{{count}} Goederenontvangst in Exact bijgewerkt.',
                updated_other: '{{count}} Goederenontvangsten in Exact bijgewerkt.',
              },
            },
            goods_delivery: {
              tracy: {
                created_one: '{{count}} Goederenlevering in Tracy aangemaakt.',
                created_other: '{{count}} Goederenleveringen in Tracy aangemaakt.',
                updated_one: '{{count}} Goederenlevering in Tracy bijgewerkt.',
                updated_other: '{{count}} Goederenleveringen in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Goederenlevering in Exact aangemaakt.',
                created_other: '{{count}} Goederenleveringen in Exact aangemaakt.',
                updated_one: '{{count}} Goederenlevering in Exact bijgewerkt.',
                updated_other: '{{count}} Goederenleveringen in Exact bijgewerkt.',
              },
            },
            sales_return: {
              tracy: {
                created_one: '{{count}} Verkoopretour in Tracy aangemaakt.',
                created_other: '{{count}} Verkoopretouren in Tracy aangemaakt.',
                updated_one: '{{count}} Verkoopretour in Tracy bijgewerkt.',
                updated_other: '{{count}} Verkoopretouren in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Verkoopretour in Exact aangemaakt.',
                created_other: '{{count}} Verkoopretouren in Exact aangemaakt.',
                updated_one: '{{count}} Verkoopretour in Exact bijgewerkt.',
                updated_other: '{{count}} Verkoopretouren in Exact bijgewerkt.',
              },
            },
            warehouse_transfer: {
              tracy: {
                created_one: '{{count}} Voorraadverplaatsing in Tracy aangemaakt.',
                created_other: '{{count}} Voorraadverplaatsingen in Tracy aangemaakt.',
                updated_one: '{{count}} Voorraadverplaatsing in Tracy bijgewerkt.',
                updated_other: '{{count}} Voorraadverplaatsingen in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Voorraadverplaatsing in Exact aangemaakt.',
                created_other: '{{count}} Voorraadverplaatsingen in Exact aangemaakt.',
                updated_one: '{{count}} Voorraadverplaatsing in Exact bijgewerkt.',
                updated_other: '{{count}} Voorraadverplaatsingen in Exact bijgewerkt.',
              },
            },
            stock_count: {
              exact: {
                created_one: '{{count}} Voorraadtelling in Exact aangemaakt.',
                created_other: '{{count}} Voorraadtellingen in Exact aangemaakt.',
              },
            },
            shipping_method: {
              tracy: {
                created_one: '{{count}} verzendmethode in Tracy aangemaakt.',
                created_other: '{{count}} voorraad verzendmethode in Tracy aangemaakt.',
                updated_one: '{{count}} verzendmethode in Tracy bijgewerkt.',
                updated_other: '{{count}} voorraad verzendmethode in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} verzendmethode in Exact aangemaakt.',
                created_other: '{{count}} voorraad verzendmethode in Exact aangemaakt.',
                updated_one: '{{count}} verzendmethode in Exact bijgewerkt.',
                updated_other: '{{count}} voorraad verzendmethode in Exact bijgewerkt.',
              },
            },
            document: {
              exact: {
                created_one: 'Created {{count}} document in Exact aangemaakt.',
                created_other: 'Created {{count}} documents in Exact aangemaakt.',
                updated_one: 'Updated {{count}} document in Exact aangemaakt.',
                updated_other: 'Updated {{count}} documents in Exact aangemaakt.',
              },
            },
          },
        },
      },
    },
    field: {
      division: { label: 'Divisie' },
      webhooks: { label: 'Webhooks' },
      manageEndpointsHeader: { label: 'Beheer Exact API-eindpunten' },
      manageWebhooksHeader: { label: 'Exact Webhooks-onderwerpen beheren' },
      address: { label: 'Adres' },
      item: { label: 'Item' },
      itemAssortment: { label: 'Item Assortment' },
      itemExtraField: { label: 'Item Extra Veld' },
      account: { label: 'Account' },
      opportunity: { label: 'Mogelijkheid' },
      salesOrder: { label: 'Verkooporder' },
      purchaseOrder: { label: 'Bestelling' },
      purchaseReturn: { label: 'Bestellingretour' },
      project: { label: 'Project' },
      billOfMaterial: { label: 'Stuklijst' },
      billOfMaterialItem: { label: 'Stuklijstitem' },
      assemblyBom: { label: 'Assemblage Stuklijst' },
      shopOrder: { label: 'Werkorder' },
      shopOrderReceipt: { label: 'Productieorder Ontvangst' },
      shopOrderReversal: { label: 'Productieorder Herroeping' },
      salesReturn: { label: 'Verkoopretour' },
      warehouse: { label: 'Magazijn' },
      storageLocationStockPosition: { label: 'Voorraadpositie Opslaglocatie' },
      itemWarehouse: { label: 'Magazijnartikel' },
      goodsReceipt: { label: 'Goederenontvangst' },
      goodsDelivery: { label: 'Goederenaflevering' },
      warehouseTransfer: { label: 'Voorraadverplaatsing' },
      stockCount: { label: 'Voorraadtelling' },
      stockCountReversal: { label: 'Omkering Voorraadtelling' },
      stockTopic: { label: 'Voorraad' },
      stockSerialNumber: { label: 'Serienummer voorraad' },
      shippingMethod: { label: 'Verzendmethode' },
      document: { label: 'Document' },
      documentAttachment: { label: 'Document Bijlage' },
      salesOrderWebhookTopic: { label: 'Verkooporder Webhook' },
      dropShipment: { label: 'Drop Shipment' },
      byproductReceipt: { label: 'Bijproductontvangst' },
      byproductReversal: { label: 'Terugdraaien bijproductontvanst' },
      workcenter: { label: 'Werkcenter' },
      clientId: { label: 'Client ID' },
      clientSecret: { label: 'Client geheim nummer' },
    },
    edit: {
      topics: {
        title: 'Beheer Exact API-eindpunten',
        subtitle: {
          webhooks: 'Webhooks',
          general: 'Algemeen',
          production: 'Productie',
          warehouse: 'Magazijn',
        },
      },
    },
  },
  exactGlobeIntegration: {
    overview: {
      authButton: 'Activeer Exact Globeintegratie',
      authResult: {
        ok: 'Exact Globeintegratie succesvol geactiveerd.',
        error: {
          invalid_request: 'Onjuist verzoek verzonden naar Exact Globe.',
          invalid_client: 'Onjuiste client verzonden naar Exact Globe.',
          invalid_grant: 'Onjuiste grant verzonden naar Exact Globe.',
          invalid_scope: 'Onjuiste scope verzonden naar Exact Globe. ',
          unauthorized_client: 'Ongeauthorizeerde client verzonden naar Exact Globe.',
          unsupported_grant_type: 'Niet ondersteund grant type verzonden naar Exact Globe.',
        },
      },
      syncButton: 'Synchroniseer met Exact Globe',
      syncResult: {
        authFailure: { notification: 'Authenticatie met Exact Globe gefaald.' },
        success: {
          notification: 'Succesvol gesynchroniseerd met Exact Globe',
          count: {
            item: {
              tracy: {
                created_one: '{{count}} Artikel in Tracy aangemaakt.',
                created_other: '{{count}} Artikelen in Tracy aangemaakt.',
                updated_one: '{{count}} Artikel in Tracy bijgewerkt.',
                updated_other: '{{count}} Artikelen in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Artikel in Exact Globe aangemaakt.',
                created_other: '{{count}} Artikelen in Exact Globe aangemaakt.',
                updated_one: '{{count}} Artikel in Exact Globe bijgewerkt.',
                updated_other: '{{count}} Artikelen in Exact Globe bijgewerkt.',
              },
            },
            account: {
              tracy: {
                created_one: '{{count}} Relatie in Tracy aangemaakt.',
                created_other: '{{count}} Relaties in Tracy aangemaakt.',
                updated_one: '{{count}} Relatie in Tracy bijgewerkt.',
                updated_other: '{{count}} Relaties in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Relatie in Exact Globe aangemaakt.',
                created_other: '{{count}} Relaties in Exact Globe aangemaakt.',
                updated_one: '{{count}} Relatie in Exact Globe bijgewerkt.',
                updated_other: '{{count}} Relaties in Exact Globe bijgewerkt.',
              },
            },
            sales_order: {
              tracy: {
                created_one: '{{count}} Verkooporder in Tracy aangemaakt.',
                created_other: '{{count}} Verkooporders in Tracy aangemaakt.',
                updated_one: '{{count}} Verkooporder in Tracy bijgewerkt.',
                updated_other: '{{count}} Verkooporders in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Verkooporder in Exact Globe aangemaakt.',
                created_other: '{{count}} Verkooporders in Exact Globe aangemaakt.',
                updated_one: '{{count}} Verkooporder in Exact Globe bijgewerkt.',
                updated_other: '{{count}} Verkooporders in Exact Globe bijgewerkt.',
              },
            },
            purchase_order: {
              tracy: {
                created_one: '{{count}} Inkooporder in Tracy aangemaakt.',
                created_other: '{{count}} Inkooporders in Tracy aangemaakt.',
                updated_one: '{{count}} Inkooporder in Tracy bijgewerkt.',
                updated_other: '{{count}} Inkooporders in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Inkooporder in Exact Globe aangemaakt.',
                created_other: '{{count}} Inkooporders in Exact Globe aangemaakt.',
                updated_one: '{{count}} Inkooporder in Exact Globe bijgewerkt.',
                updated_other: '{{count}} Inkooporders in Exact Globe bijgewerkt.',
              },
            },
            warehouse: {
              tracy: {
                created_one: '{{count}} Voorraadverplaatsing in Tracy aangemaakt.',
                created_other: '{{count}} Voorraadverplaatsingen in Tracy aangemaakt.',
                updated_one: '{{count}} Voorraadverplaatsing in Tracy bijgewerkt.',
                updated_other: '{{count}} Voorraadverplaatsingen in Tracy bijgewerkt.',
              },
              exact: {
                created_one: '{{count}} Voorraadverplaatsing in Exact Globe aangemaakt.',
                created_other: '{{count}} Voorraadverplaatsingen in Exact Globe aangemaakt.',
                updated_one: '{{count}} Voorraadverplaatsing in Exact Globe bijgewerkt.',
                updated_other: '{{count}} Voorraadverplaatsingen in Exact Globe bijgewerkt.',
              },
            },
          },
        },
      },
    },
    field: {
      baseUrl: { label: 'Baseurl' },
      serverName: { label: 'Servernaam' },
      databaseName: { label: 'Databasenaam' },
      username: { label: 'Gebruikersnaam' },
      password: { label: 'Wachtwoord' },
      manageEndpointsHeader: { label: 'Manage Exact Globe APIEndpoints' },

      item: { label: 'Item' },
      account: { label: 'Account' },
      salesOrder: { label: 'Verkooporder' },
      purchaseOrder: { label: 'Inkoopoprder' },
      warehouse: { label: 'Warehouse' },
      production: { label: 'Productieorder' },
      contactPerson: { label: 'Contact persoon' },
      document: { label: 'Documenten' },
      project: { label: 'Project' },
      resource: { label: 'Resource' },
      warehouseStock: { label: 'Magazijnvoorraad' },
      locationStock: { label: 'Opslaglocatie Voorraad' },
      stockCount: { label: 'Voorraad tellen' },
      warehouseTransfer: { label: 'Magazijnoverdracht' },
      productionOrderReceipt: { label: 'Productiebon' },
      purchaseOrderReceipt: { label: 'Aankoopbon' },
      assemblyBom: { label: 'Assemblage Stuklijst' },
      goodsDelivery: { label: 'Goederenaflevering' },
      syncStartDate: { label: 'Start datum voor sync' },
    },
  },
  unit4Integration: {
    overview: {
      authButton: 'Activeer Unit4-Integratie',
      authResult: {
        ok: 'Unit4-integratie succesvol geactiveerd.',
        error: {
          invalid_request: 'Onjuist verzoek verzonden naar Unit4.',
          invalid_client: 'Onjuiste client verzonden naar Unit4.',
          invalid_grant: 'Onjuiste grant verzonden naar Unit4.',
          invalid_scope: 'Onjuiste scope verzonden naar Unit4. ',
          unauthorized_client: 'Ongeauthorizeerde client verzonden naar Unit4.',
          unsupported_grant_type: 'Niet ondersteund grant type verzonden naar Unit4.',
        },
      },
      syncButton: 'Synchroniseer met Unit4',
      syncResult: {
        authFailure: { notification: 'Authenticatie met Exact gefaald.' },
        success: {
          notification: 'Succesvol gesynchroniseerd met Unit4',
        },
      },
    },
    field: {
      administration: { label: 'Administratiecode' },
      baseUrl: { label: 'Unit 4 server basis-URL' },
      manageEndpointsHeader: { label: 'Beheer Unit4 API-eindpunten' },
      goodsReceipt: { label: 'Goederenontvangst' },
      product: { label: 'Product' },
      productDetails: { label: 'Productdetails' },
      supplier: { label: 'Leverancier' },
      purchaseOrder: { label: 'Verkooporder' },
      customer: { label: 'Klant' },
      order: { label: 'Inkooporder' },
    },
  },
  syncError: {
    overview: {
      title: 'Synchronisatiefouten',
      empty: 'Geen synchronisatiefouten gevonden.',
    },
    field: {
      id: { label: 'ID' },
      message: { label: 'Melding' },
      createdAt: { label: 'Gestart Op' },
    },
  },
  exactSyncRequest: {
    finishButton: 'Zet status handmatig op Uitgevoerd',
    retryButton: 'Opnieuw uitvoeren',
    overview: {
      title: 'Exact Integraties',
      empty: 'Geen Integraties gevonden',
      exportButton: 'Exporteer synchronisatieverzoeken'
    },
    field: {
      id: {
        label: 'ID'
      },
      order: { label: 'Order' },
      status: { label: 'Status' },
      syncer: { label: 'Syncer' },
      payload: { label: 'Payload' },
      createdAt: { label: 'Gestart Op' },
      failedAt: { label: 'Gefaald Op' },
      performedAt: { label: 'Uitgevoerd Op' },
      errorReason: { label: 'Fout Reden' },
      erpId: { label: 'ERP ID' },
    },
    export: {
      file: { name: 'exact_sync_request_export' },
    },
  },
  exactGlobeSyncRequest: {
    overview: {
      title: 'Exact Globe Integraties laatst uitgevoerd om',
      empty: 'Geen Integraties gevonden',
      exportButton: 'Exporteer synchronisatieverzoeken'
    },
    field: {
      id: {
        label: 'ID'
      },
      order: { label: 'Order' },
      status: { label: 'Status' },
      syncer: { label: 'Syncer' },
      payload: { label: 'Payload' },
      createdAt: { label: 'Gestart Op' },
      performedAt: { label: 'Uitgevoerd Op' },
      errorReason: { label: 'Fout Reden' },
      erpId: { label: 'ERP ID' },
    },
  },
  integrationsMonitor: {
    title: 'Integraties',
    viewAll: 'Bekijk alles',
    integrationLabel: 'Integratie',
    status: {
      active: 'actief',
      inactive: 'inactief',
      activityTimestamp: {
        active: 'Sync service actief op {{timestamp}}',
        inactive: 'Sync service niet actief op {{timestamp}}',
      },
    },
    syncServiceStatus: {
      label: 'Sync service status',
      lastSync: 'Laatste sync',
      neverSynced: 'Nooit',
    },
    apiRequests: {
      usedToday: 'API verzoeken gebruikt vandaag',
      almostOutOfRequests: 'Bijna geen API verzoeken meer over',
      outOfRequests: 'Geen API verzoeken meer over',
      outOfRequestsHelp: 'Geen lees- of schrijfverzoeken kunnen worden gedaan naar Exact Online.',
    },
  },
  project: {
    overview: {
      title: 'Projecten',
      empty: 'Er zijn geen projecten die voldoen aan uw zoekopdracht.',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      description: { label: 'Beschrijving' },
      notes: { label: 'Notities' },
      startDate: { label: 'Startdatum' },
      endDate: { label: 'Einddatum' },
      deleted: { label: 'Verwijderd' },
    },
    filter: {
      code: 'Code',
      description: 'Beschrijving',
      startDate: 'Startdatum',
      endDate: 'Einddatum',
    },
  },
  janKredietIntegration: {
    field: {
      apiKey: {
        label: 'Api sleutel',
      },
      shippingLabelTemplate: {
        label: 'Verzend label sjabloon',
      },
      contentLabelTemplate: {
        label: 'Content label sjabloon',
      },
      senderName: {
        label: 'Naam van verstuurder',
      },
      senderAddress: {
        label: 'Adres van verstuurder',
      },
      senderHouseNumber: {
        label: 'Huisnummer van verstuurder',
      },
      senderCountryCode: {
        label: 'Land code van verstuurder',
      },
      senderZipCode: {
        label: 'postcode van verstuurder',
      },
      senderCity: {
        label: 'Stad van vestuurder',
      },
    },
  },
  upsIntegration: {
    field: {
      cliendId: {
        label: 'Client ID',
      },
      clientSecret: {
        label: 'Geheim nummer',
      },
      apiKey: {
        label: 'Api sleutel',
      },
      shippingLabelTemplate: {
        label: 'Verzend label sjabloon',
      },
      contentLabelTemplate: {
        label: 'Content label sjabloon',
      },
      username: {
        label: 'Gebruikersnaam'
      },
      password: {
        label: 'Wachtwoord'
      },
      accountNumber: {
        label: 'Account Nummer'
      },
      senderName: {
        label: 'Naam van verstuurder',
      },
      senderAddress: {
        label: 'Adres van verstuurder',
      },
      senderHouseNumber: {
        label: 'Huisnummer van verstuurder',
      },
      senderCountryCode: {
        label: 'Land code van verstuurder',
      },
      senderZipCode: {
        label: 'postcode van verstuurder',
      },
      senderCity: {
        label: 'Stad van vestuurder',
      },
      senderPhoneNumber: {
        label: 'Telefoon nummer van verstuurder',
      },
      userFormsEnabled: {
        label: 'UPS Paperless integratie',
      },
    },
  },
  transmissionIntegration: {
    field: {
      username: {
        label: 'username'
      },
      password: {
        label: 'password'
      },
      depot: {
        label: 'depot number'
      },
      shipper: {
        label: 'shipper number'
      },
      shippingLabelTemplate: {
        label: 'Verzend label sjabloon'
      },
      contentLabelTemplate: {
        label: 'Content label sjabloon',
      },
    },
    shippingUnits: {
      shippingUnits: 'Verzendeenheden',
      shippingUnit: 'Verzendeenheid',
      code: 'Code',
      description: 'Omschrijving',
      length: 'Lengte',
      width: 'Breedte',
      height: 'Hoogte',
      weight: 'Gewicht',
    },
  },
  postnlIntegration: {
    field: {
      apiKey: {
        label: 'API Sleutel',
      },
      customerCode: {
        label: 'Klant code',
      },
      customerNumber: {
        label: 'Klant nummer',
      },
      labelSize: {
        label: 'Label grootte',
      },
      senderName: {
        label: 'Naam van verstuurder',
      },
      senderStreet: {
        label: 'Straat van verstuurder',
      },
      senderHouseNumber: {
        label: 'Huisnummer van verstuurder',
      },
      senderCountryCode: {
        label: 'Land code van verstuurder',
      },
      senderZipCode: {
        label: 'postcode van verstuurder',
      },
      senderCity: {
        label: 'Stad van vestuurder',
      },
      contentLabelTemplate: {
        label: 'Content label sjabloon',
      },
    },
  },
  billOfMaterialVersion: {
    edit: {
      title: 'Bekijk stuklijst',
    },
    field: {
      version: {
        label: 'Versie',
      },
      status: {
        label: 'Status',
      },
      description: {
        label: 'Omschrijving',
      },
      default: {
        label: 'Standaard',
      },
    },
    disableBom: 'Schakelt alle stuklijsten van dit artikeltype uit. Dit heeft alleen invloed op toekomstige bestellingen',
    enableBom: 'Schakelt de nieuwste stuklijstversie van dit artikeltype in. Dit alleen invloed op toekomstige bestellingen',
    noMaterials: 'Geen materialen in stuklijst',
  },
  billOfMaterialItem: {
    field: {
      articleType: { label: 'Artikel' },
      description: { label: 'Omschrijving' },
      quantity: { label: 'Aantal' },
      required: { label: 'Benodigd' },
      assigned: { label: 'Toegekend' },
      backflush: { label: 'Backflush' },
      number: { label: 'Regel.' },
      warehouse: { label: 'Magazijn' },
      storageLocations: { label: 'Opslaglocaties' },
      type: { label: 'Type' },
    },
  },
  assemblyBomVersion: {
    edit: {
      title: 'Assemblage Stuklijst'
    },
    field: {
      useExplosion: { label: 'Explosie bij levering gebruiken?' },
      active: { label: 'Actief?' },
    },
  },
  productionOrder: {
    field: {
      billOfMaterialVersion: { label: 'Stuklijstversie' },
      warehouse: { label: 'Magazijn' },
      description: { label: 'Description' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          released: 'Vrijgegeven',
          finished: 'Klaar',
          draft: 'Concept'
        },
      },
    },
    billOfMaterialVersionPlaceholder: 'Geen stuklijst',
    procesPlaceholder: 'Geen maakproces',
    edit: {
      title: 'Productieorder Aanpassen',
    },
    create: {
      title: 'Productieorder Aanmaken',
      productionMessageWarning: 'Er zijn productie berichten',
    },
  },
  script: {
    overview: {
      title: 'Scripts',
      empty: 'Er zijn geen scripts die voldoen aan uw zoekopdracht.',
      addButton: 'Script',
    },
    create: {
      title: 'Script Aanmaken',
    },
    edit: {
      title: 'Script Aanpassen',
      schedule: 'Dag Schema',
      copyPaste: {
        copyButton: 'Kopiëren',
        pasteButton: 'Plakken',
        copied: 'Succesvol gekopieerd',
        pasted: 'Succesvol geplakt',
        pasteError: 'Niks om te plakken.',
        pasteTypeMismatch: 'Klembord bevat een script van een ander type.',
      },
    },
    field: {
      notes: { label: 'Notities' },
      status: {
        label: 'Status',
        value: {
          concept: 'Concept',
          test: 'Test',
          production: 'Productie',
          archived: 'Gearchiveerd',
        },
      },
      type: {
        label: 'Type',
        value: {
          environment: 'Klimaat',
          light: 'Licht',
          water: 'Water',
          nutrition: 'Voeding',
        },
      },
      name: { label: 'Naam' },
    },
  },
  workStationSession: {
    overview: {
      title: 'Werkpleksessies',
      empty: 'Geen werkpleksessies gevonden.',
      viewPrintJobsButton: 'Bekijk Printopdrachten',
    },
    field: {
      id: { label: 'ID' },
      type: {
        label: 'Type',
        value: {
          desktop: 'Desktop',
          mobile: 'Mobiel',
        },
      },
      printers: {
        label: 'Printers',
        empty: 'Geen printers beschikbaar.',
      },
      workStation: { label: 'Werkplek' },
      appVersion: { label: 'Appversie' },
      isActive: { label: 'Is Actief' },
      createdAt: { label: 'Gestart Op' },
      updatedAt: { label: 'Laatste Activiteit' },
      printJobs: { label: 'Printopdrachten' },
    },
  },
  printJob: {
    field: {
      printer: {
        label: 'Printer',
      },
      status: {
        label: 'Status',
        value: {
          todo: 'Te Doen',
          done: 'Afgerond',
          failed: 'Mislukt',
        },
      },
      content: {
        label: 'Inhoud',
      },
      copies: {
        label: 'Kopieën',
      },
      createdAt: {
        label: 'Toegevoegd Op',
      },
    }
  },
  stockTransaction: {
    from: 'Van',
    to: 'Naar',
    transactionType: {
      stockCount: 'Voorraadtelling',
      inShipmentLine: 'Ontvangstregel',
      outShipmentLine: 'Verzamel Orderregel',
      productionOrder: 'Productieorder',
      warehouseTransferLine: 'Magazijnverplaatsingsregels',
      byproduct: 'Byproduct',
      assemblyBom: 'Assemblagestuk',
      materialPlanBackflushOn: 'Materiaalplan Backflush On',
      materialPlanBackflushOff: 'Materiaalplan Backflush Off',
    },
    overview: {
      title: 'Voorraadtransacties',
      empty: 'Geen Voorraadtransacties Gevonden'
    },
    field: {
      transactionType: { label: 'Transactietype' },
      id: { label: 'ID' },
      articleType: { label: 'Artikeltype' },
      batch: { label: 'Batch', filter: 'Batch Serienummer' },
      transactionTrigger: { label: 'Transactie Trekker' },
      createdAt: { label: 'Gemaakt op' },
      executedAt: { label: 'Uitgevoerd op', filter: 'Uitgevoerd' },
      rolledbackAt: { label: 'Teruggedraaid Bij', filter: 'Teruggedraaid' },
      transaction: { label: 'Transactie' },
      warehouseFrom: { label: 'Magazijn Van' },
      warehouseTo: { label: 'Magazijn Naar' },
      storageLocationFrom: { label: 'Opslaglocatie Van' },
      storageLocationTo: { label: 'Opslaglocatie Naar' },
    },
  },
  warehouseTransfer: {
    stockWarning: {
      content: 'Waarschuwing. Sommige locaties hebben niet voldoende voorraad voor deze overdracht. Doorgaan?',
    },
    overview: {
      title: 'Magazijnverplaatsing',
      empty: 'Geen Magazijnverplaatsingen',
      viewLinesButton: 'Bekijk Magazijnverplaatsingsregels',
    },
    edit: {
      title: 'Magazijnverplaatsing bewerken',
    },
    create: {
      title: 'Magazijnverplaatsing toevoegen',
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Beschrijving' },
      entryDate: { label: 'Invoerdatum' },
      plannedDeliveryDate: { label: 'Geplande Verplaatsingsdatum' },
      completedOnDate: { label: 'Voltooid Op Datum' },
      status: {
        label: 'Status',
        value: {
          draft: 'Concept',
          processed: 'Verwerkt',
        },
      },
      source: {
        label: 'Bron',
        value: {
          manual_entry: 'Handmatige Invoer',
          import: 'Importeren',
          transfer_advice: 'Verplaatsingsadvies',
          web_service: 'Webservice',
          production_request: 'Werkorder'
        },
      },
      warehouseFrom: {
        label: 'Magazijn Van',
      },
      warehouseTo: {
        label: 'Magazijn Naar',
      },
      type: {
        label: 'Overdrachtstype',
        value: {
          true: 'Locatie',
          false: 'Magazijn',
        }
      },
      productionRequest: {
        label: 'Werkorder',
        isNull: 'Zonder werkorders',
      },
      transferLines: {
        label: 'Magazijnverplaatsingsregels',
        value: 'Magazijnverplaatsingsregel {{id}}',
        empty: 'Er zijn geen Magazijnverplaatsingsregels toegevoegd.',
      },
    },
  },
  warehouseTransferLine: {
    empty: 'Geen Magazijnverplaatsingsregels.',
    field: {
      id: { label: 'ID' },
      lineNumber: { label: 'Nummer' },
      createdDate: { label: 'Aanmaakdatum' },
      quantity: { label: 'Aantal stuks' },
      articleType: { label: 'Artikeltype' },
      availableStock: { label: 'Voorraad' },
      noAvailableStock: { label: 'Geen vooraad voor geselecteerd Artikeltype.' },
      storageLocation: {
        id: { label: 'ID' },
        warehouse: { label: 'Magazijn' },
        code: { label: 'Code' },
        stock: { label: 'Voorraad' },
      },
      storageLocationFrom: {
        label: 'Van Opslaglocatie',
      },
      storageLocationTo: {
        label: 'Naar Opslaglocatie',
      },
      warehouseTransfer: {
        label: 'Voorraadverplaatsing',
      },
    },
    create: {
      title: 'Bekijk Voorraadverplaatsingsregels',
    },
  },
  detailImageModal: {
    title: { label: 'Geüploade afbeeldingen' },
    popup: { label: 'Bekijk geüploade afbeeldingen' }
  },
  stockCount: {
    stockExport: {
      button: { label: 'Magazijnvoorraad vs batch export' },
      file: { name: 'Magazijnvoorraad vs batches' },
    },
    field: {
      warehouse: { label: 'Magazijn' },
      defaultStorageLocation: { label: 'Opslaglocatie' },
    },
    edit: {
      title: 'Voorraadtelling aanpassen'
    },
    title: { label: 'Geüploade afbeeldingen' },
    popup: { label: 'Bekijk geüploade afbeeldingen' },
    missingStockCountProcess: 'Ontbrekend Voorraadtellingsproces aanmaken',
  },
  rampUpTarget: {
    field: {
      date: { label: 'Datum' },
      processes: { label: 'Processen' },
      capacity: { label: 'Max Capaciteit' },
      maxOperators: { label: 'Max Operators' },
      maxHarvest: { label: 'Max Dagoogst' },
    },
  },
  rampUpAutomation: {
    field: {
      workStation: { label: 'Werkstation' },
      date: { label: 'Automatiseringsdatum' },
    },
  },
  carrierStep: {
    carrier: 'koerier',
  },
  rampUp: {
    overview: {
      title: 'Scenarios',
      empty: 'Geen scenarios gevonden.',
      addButton: 'Scenario',
    },
    create: {
      title: 'Scenario Toevoegen',
    },
    edit: {
      title: 'Scenario Bewerken',
      schedule: 'Oogstschema',
      automation: 'Automatiseringsdatums',
      noLimit: 'Geen Limiet',
      graph: {
        label: 'Grafiek',
        value: {
          capacity: 'Capaciteit',
          operators: 'Operators',
          harvest: 'Oogst',
        },
      },
      copyPaste: {
        copyButton: 'Kopiëren',
        pasteButton: 'Plakken',
        copied: 'Succesvol gekopieerd',
        pasted: 'Succesvol geplakt',
        pasteError: 'Niks om te plakken',
      },
    },
    field: {
      name: { label: 'Naam' },
      articles: {
        label: 'Artikelen',
        empty: 'Nog geen artikelen toegevoegd',
        add: 'Artikel Toevoegen',
      },
      capacity: { label: 'Totaal Containers' },
      monday: { label: 'Maandag' },
      tuesday: { label: 'Dinsdag' },
      wednesday: { label: 'Woensdag' },
      thursday: { label: 'Donderdag' },
      friday: { label: 'Vrijdag' },
      saturday: { label: 'Zaterdag' },
      sunday: { label: 'Zondag' },
    },
  },
  operatorAssignment: {
    field: {
      workStation: { label: 'Werkstatoin' },
      operator: { label: 'Operator' },
      startAt: { label: 'Start' },
      endAt: { label: 'Einde' },
    },
  },
  articleTypeStorageLocation: {
    changeStockModal: {
      title: 'Verander voorraad {{storageLocationCode}}'
    },
    field: {
      isDefault: { label: 'Standaard' },
      stock: { label: '# Beschikbaar' },
      assigned: { label: '# Toegekend' },
      warehouse: { label: 'Magazijn' },
    },
  },
  storageLocation: {
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      name: { label: 'Name' },
      deleted: { label: 'Verwijderd?' },
      used: { label: 'Bezette ruimte' },
      capacity: { label: 'Capaciteit' },
      usage: { label: 'Gebruik' },
      sequenceNumber: { label: 'Volgorde Nummer' },
      preferredArticles: {
        label: 'Voorkeursartikeltype',
        value_one: 'Voorkeur van {{count}} artikel',
        value_other: 'Voorkeur van {{count}} artikelen',
      }
    },
    edit: {
      title: 'Bewerk Opslag Locatie'
    },
    create: {
      title: 'Maak Opslag Locatie Aan'
    },
    suggestedLocation: 'Voorgestelde Locatie:',
  },
  waterScript: {
    field: {
      nutritionScript: { label: 'Voeding' },
      ec: { label: 'EC' },
      ecMin: { label: 'EC min' },
      ecMax: { label: 'EC max' },
      ecT: { label: 'EC t' },
      ph: { label: 'pH' },
      phMin: { label: 'pH min' },
      phMax: { label: 'pH max' },
      phT: { label: 'pH t' },
      pulse: { label: 'Puls' },
      cycle: { label: 'Cyclus' },
      flowMin: { label: 'Flow min' },
      flowMax: { label: 'Flow max' },
    },
  },
  nutritionScript: {
    field: {
      nitrogen: { label: 'Stikstof (N)' },
      phosphorus: { label: 'Fosfor (P)' },
      potassium: { label: 'Kalium (K)' },
      calcium: { label: 'Calcium (Ca)' },
      magnesium: { label: 'Magnesium (Mg)' },
      sulphur: { label: 'Zwavel (S)' },
    },
  },
  lightScript: {
    field: {
      intensity: { label: 'Intensiteit' },
      startTime: { label: 'Tijd' },
    },
  },
  lightScriptSchedule: {
    field: {
      intensity: { label: 'Intensiteit' },
      startTime: { label: 'Tijd' },
    },
  },
  factory: {
    overview: {
      title: 'Fabrieken',
      empty: 'Geen fabrieken gevonden.',
      addButton: 'Fabriek',
    },
    create: {
      title: 'Fabriek Toevoegen',
    },
    edit: {
      title: 'Fabriek Aanpassen',
    },
    field: {
      name: { label: 'Locatie' },
      timezone: { label: 'Tijdzone' },
      currentTime: { label: 'Huidige Tijd' },
    },
  },
  packStation: {
    overview: {
      title: 'Packstations',
      empty: 'Geen pakstations gevonden.',
      addButton: 'Packstation',
    },
    create: {
      title: 'Pakketstation Toevoegen',
    },
    edit: {
      title: 'Packstation Aanpassen',
    },
    field: {
      name: { label: 'Naam' },
      serialNumber: { label: 'Serienummer' },
      size: { label: 'Size' },
      outShipments: { label: 'Verzamelorder' },
    },
  },
  packStationBox: {
    overview: {
      title: 'Pack station boxes',
      empty: 'Geen pack station boxes gevonden.',
      addButton: 'Pack station box',
    },
    create: {
      title: 'Pack station box Toevoegen',
    },
    edit: {
      title: 'Pack station box Aanpassen',
    },
    field: {
      serialNumber: { label: 'Serienummer' },
      size: { label: 'Size' },
      outShipments: { label: 'Verzamelorder' },
    },
  },
  environmentScript: {
    field: {
      direction: {
        label: 'Richting',
        value: {
          a_to_b: 'A naar B',
          b_to_a: 'B naar A',
        },
      },
      speed: { label: 'Snelheid' },
      tIn: { label: 'T in' },
      deltaT: { label: 'ΔT' },
      deltaX: { label: 'ΔX' },
      co2In: { label: 'CO² in' },
      startTime: { label: 'Tijd' },
    },
  },
  environmentScriptSchedule: {
    field: {
      direction: {
        label: 'Richting',
        value: {
          a_to_b: 'A naar B',
          b_to_a: 'B naar A',
        },
      },
      speed: { label: 'Snelheid' },
      tIn: { label: 'T in' },
      deltaT: { label: 'ΔT' },
      deltaX: { label: 'ΔX' },
      co2In: { label: 'CO² in' },
      startTime: { label: 'Tijd' },
    },
  },
  businessRelation: {
    field: {
      name: { label: 'Klantnaam' },
    },
  },
  unit4Customer: {
    field: {
      customerId: { label: 'Klant-ID' }
    }
  },
  navisionCustomer: {
    field: {
      customerId: { label: 'Klant-ID' },
    },
  },
  unit4Order: {
    field: {
      orderDate: { label: 'Verzenddatum' }
    }
  },
  navisionSalesOrder: {
    field: {
      orderDate: { label: 'Orderdatum' },
      requestedDeliveryDate: { label: 'Aangevraagd' },
      promisedDeliveryDate: { label: 'Beloofd' },
      lastDatetimeModified: { label: 'Laatst Aangepast' },
      deliveryAddress: { label: 'Adresregel 1' },
      deliveryAddress2: { label: 'Adresregel 2' },
      deliveryCity: { label: 'Stad' },
      deliveryPostcode: { label: 'Postcode' },
      deliveryCountryRegionCode: { label: 'Regio' },
      sellToAddress: { label: 'Adresregel 1' },
      sellToAddress2: { label: 'Adresregel 2' },
      sellToCity: { label: 'Stad' },
      sellToPostcode: { label: 'Postcode' },
    },
  },
  resourceAllocation: {
    field: {
      id: { label: 'ID' },
      source: { label: 'Bron' },
      target: { label: 'Doel' },
      erpId: { label: 'ERP ID' },
      productionOrder: { label: 'Productieorder' },
      supplier: { label: 'Leverancier' },
      reference: { label: 'Referentie' },
      expectedDate: { label: 'Verwachte datum' },
      otherSalesOrders: { label: 'Andere Verkooporders' },
      quantity: { label: 'Aantal allocated' },
      orderQuantity: { label: 'Order hoeveelheid' },
      description: { label: 'Beschrijving' },
      producedQuantity: { label: 'Geproduceerde hoeveelheid' },
      salesOrderLine: { label: 'Verkooporderregel' },
      purchaseOrderLine: {
        label: 'Inkooporderregel',
        filterIsFullyReceivedErp: 'Inkooporderregel geleverd'
      },
    },
    overview: {
      title: 'Voorraadallocaties',
      empty: 'Geen Voorraadallocaties gevonden.',
      bulkActionButton: {
        choose: 'Kies Bulkactie ({{count}})',
        delete: 'Verwijder Voorraadallocaties ({{count}})',
      },
      confirmDeleteModal: {
        title: 'Bevestig verwijderen',
        content: 'Weet je zeker dat je {{count}} Voorraadallocaties wilt verwijderen?',
      },
      currentAllocations: 'Huidige allocatie',
      allocateFromFuture: 'Alloceer van toekomstige voorraad'
    },
    statusPopup: {
      title: 'Allocatie status',
      allocatedFromStock: 'Gealloceerd uit voorraad',
      allocatedFromExpectedStock: 'Gealloceerd uit verwachtte voorraad',
      totalAllocated: 'Totaal gealloceerd',
      notAllocated: 'Niet gealloceerd',
    },
  },
  salesOrderResponsibility: {
    field: {
      name: { label: 'Naam' },
      company: { label: 'Bedrijf' },
      role: { label: 'Rol' },
      email: { label: 'Email' },
      phoneNumber: { label: 'Telefoonnummer' },
    },
  },
  exactAccount: {
    field: {
      number: { label: 'Exactnummer' },
    },
  },
  exactSalesOrder: {
    field: {
      id: { label: 'ID' },
      number: { label: 'Nummer' },
      deliverAt: { label: 'Leveren Op' },
      orderedAt: { label: 'Besteld Op' },
      modified: { label: 'Aangepast Op' },
    },
  },
  exactAddress: {
    field: {
      addressLine1: { label: 'Adresregel 1' },
      addressLine2: { label: 'Adresregel 2' },
      addressLine3: { label: 'Adresregel 3' },
      city: { label: 'Stad' },
      postcode: { label: 'Postcode' },
      countryName: { label: 'Land' },
    },
  },
  printModal: {
    title: 'Print {{item}}',
    selectPrinter: 'Selecteer Printer',
    noPrintersAvailable: 'Geen Printers Beschikbaar',
    printButton: 'Print',
    copies: 'Aantal Kopieën',
  },
  metafield: {
    overview: {
      title: 'Metavelden',
      empty: 'Geen metavelden gevonden.',
      addButton: 'Metavelden',
      typesHeader: 'Metaveld types',
    },
    importMetavalueModal: {
      title: 'Importeer Metawaardes',
      fileNamePlaceholder: 'Upload .csv',
      explanation: {
        main: 'Upload metawaardes in een CSV. Het veld scheidingsteken moet ; zijn en het text scheidingsteken ". Het bestand moet gedecodeerd zijn in UTF-8, en alle kolommen zijn hoofdletter gevoelig. Alle kolommen, behalve de eerste, moeten de naam zijn van een metaveld. Deze namen zijn ook hoofdletter gevoelig.',
        partOne: 'Upload metawaardes voor ',
        partTwo: ' Voor specifiek {{model}} ',
        entryLevel: {
          doNote: 'Belangrijke notitie',
          classification: 'De CSV moet een kolom bevatten genaamd: "Article Group Name", waar elke code een unieke artikel groep naam is.',
          article_type: 'De CSV moet een kolom bevatten genaamd: "Article Type Code", waar elke code een unieke artikeltype code is.',
          production_request: 'De CSV moet een kolom bevatten genaamd: "Work Order ID", waar elke code een unieke werkorder ID (WO...) is.',
          load_carrier: 'De CSV moet een kolom bevatten genaamd: "Load Carrier Name", waar elke code een unieke ladingdrager naam is.',
        },
      },
      options: {
        entryLevel: {
          label: 'Invoerniveau',
          value: {
            article_type: 'Artikeltypes',
            load_carrier: 'Ladingdragers',
            production_request: 'Werkorders',
            batch: 'Batches',
            classification: 'Artikelgroepen',
          },
        },
      },
      crud: {
        created: '{{count}} metwaarde(s) gecreerd ',
        updated: '{{count}} metwaarde(s) geupdate ',
        objectNotFound: 'Kon {{entry}}(s) met {{definition}} "{{definitionObject}}" niet vinden: {{objects}}.',
        fieldNotFound: 'Metaveld(en) met naam(en) "{{column}}" niet gevonden voor huidige configuratie, maar de kolom was in uw csv.',
        notEnabled: 'Metaveld "{{metafield}}" is niet aangezet voor artikelgroep "{{classification}}".',
        notSupported: 'Metaveld(en) met type {{type}} worden niet ondersteund.',

      },
    },
    allocateMetafieldModal: {
      title: 'Beheer metaveld allocaties',
      isAllocatedToParentLevel: {
        label: {
          classification: 'Is gealloceerd aan artikelgroep?',
          article_type: 'Is gealloceerd aan artikeltype?',
          undefined: 'Is gealloceerd aan overkoepelend niveau?',
        },
      },
      unAllocateButton: {
        label: {
          classification: 'Onalloceer metaveld van {{count}} artikelgroepen',
          article_type: 'Onalloceer metaveld van {{count}} artikeltypes',
        },
      },
      allocateButton: {
        label: {
          classification: 'Alloceer metaveld aan {{count}} artikelgroepen',
          article_type: 'Alloceer metaveld aan {{count}} artikeltypes',
        },
      },
      openModalButton: {
        label: 'Beheer metaveld allocaties',
      }
    },
    allocations: {
      label: 'Getoond',
      classification: {
        value_one: 'Getoond in {{count}} artikelgroep.',
        value_other: 'Getoond in {{count}} artikelgroepen.',
      },
      article_type: {
        value_one: 'Getoond in {{count}} artikeltypes.',
        value_other: 'Getoond in {{count}} artikeltypes.',
      },
      noAllocations: 'Dit metaveld type wordt altijd getoond',
    },
    create: {
      title: 'Metaveld Aanmaken',
    },
    edit: {
      title: 'Metaveld Aanpassen',
      new: 'Nieuw Metaveld',
      noneDefined: 'Geen metavelden gedefinieerd.',
      entryLevel: {
        article_type: 'Artikeltype Metavelden',
        load_carrier: 'Ladingdrager Metavelden',
        production_request: 'Werkorder Metavelden',
      },
    },
    field: {
      name: { label: 'Naam' },
      slug: { label: 'Slug' },
      entryLevel: {
        label: 'Invoerniveau',
        value: {
          article_type: 'Artikeltype',
          load_carrier: 'Ladingdrager',
          production_request: 'Werkorder',
          classification: 'Artikelgroep',
        },
        description: {
          article_type: 'Artikeltype metavelden',
          load_carrier: 'Ladingdrager metavelden',
          production_request: 'Werkorder metavelden',
          classification: 'Artikelgroep metavelden',
        }
      },
      type: {
        label: 'Type',
        value: {
          text: 'Tekst',
          check: 'Check',
          image: 'Afbeelding',
          choice: 'Keuze',
          measure: 'Meting',
          format: 'Formaat',
        },
      },
      textLong: { label: 'Lang' },
      choiceOptions: { label: 'Opties' },
      choiceMultiple: { label: 'Meerdere' },
      choiceAllowAdditions: { label: 'Sta Toevoegingen Toe' },
      measureMin: { label: 'Minimumwaarde' },
      measureMax: { label: 'Maximumwaarde' },
      formatFormat: { label: 'Formaat' },
      default: { label: 'Standaard' },
    },
  },
  classification: {
    overview: {
      title: 'Artikelgroepen',
      empty: 'Er zijn geen artikelgroepen die voldoen aan uw zoekopdracht.',
      editTrackBatchUsage: 'Geselecteerde artikelgroepen aanpassen',
      editModal: {
        title: 'Geselecteerde artikelgroepen aanpassen',
        resetTrackBatchUsageInfo: 'Stel "Volg groep" in voor alle artikeltypen binnen de geselecteerde groepen. Dit heeft invloed op {{affect}} van in totaal {{total}} artikeltype(s).',
        setTrackBatchUsage: 'Stel "Volg groep" in voor alle geselecteerde artikelgroepen. Dit heeft invloed op {{affect}} artikelgroepen.',
        resetButton: 'Stel "Volg groep" in voor alle artikeltypen',
        confirmButton: 'Artikelgroep aanpassen',
      },
    },
    edit: {
      title: 'Artikelgroep Aanpassen',
      resetTrackBatchUsageButtonHelp: 'Stel "Volg groep" in voor alle artikeltypen binnen deze groep. Dit heeft invloed op {{affect}} van in totaal {{total}} artikeltype(s).',
      sidebar: {
        header: {
          groupSettings: 'Artikelgroep instellingen',
          groupMetafields: 'Artikelgroep metavelden',
        },
      },
    },
    field: {
      name: { label: 'Naam' },
      articleTypeCount: { label: 'Artikeltypes' },
      trackBatchUsage: { label: 'Batchgebruik bijhouden' },
      deleted: { label: 'Verwijderd' },
    },
  },
  nest: {
    overview: {
      title: 'Nesttypes',
      empty: 'Geen nesttypes beschikbaar',
      addButton: 'Toevoegen',
    },
    field: {
      nestType: {
        label: 'Nesttype'
      }
    }
  },
  nestRequest: {
    label: 'Nest details',
    details: {
      thickness: 'Dikte',
      dimensions: 'Afmeting',
      runTime: 'Snijtijd',
      material: 'Materiaal',
    }
  },
  nesting: {
    title: 'Nesting',
    empty: 'Geen werkorders om te nesten'
  },
  nestType: {
    field: {
      name: {
        label: 'Naam'
      },
      articleType: {
        label: 'Artikeltype'
      }
    },
    create: {
      title: 'Nesttype aanmaken'
    },
    edit: {
      title: 'Nesttype bewerken'
    }
  },
  capacity: {
    overview: {
      title: 'Operatorcapaciteit',
      empty: 'Geen operators beschikbaar'
    }
  },
  operatorAllocation: {
    field: {
      allDay: {
        label: 'Hele dag'
      },
      productionLine: {
        label: 'Productieomgeving'
      },
      notes: {
        label: 'Notities'
      }
    },
    dateRange: {
      label: 'Duur'
    },
    timeRange: {
      label: 'Tijd'
    },
    create: {
      title: 'Wijs operator toe aan productieomgeving'
    },
    vacation: {
      title: 'Vakantie',
      allDay: 'Hele dag',
      schedule: {
        warning: 'Inplannen op een vrije dag?',
        confirm: 'Ja',
        cancel: 'Sluiten'
      }
    }
  },
  materialPlan: {
    noMaterials: 'Geen materialen in materiaalplan',
    item: 'Materiaalplan artikel',
    quantityRequired: {
      label: 'Vereist',
      info: 'Hoeveelheid nodig voor ieder artikel van deze order.',
    },
    projected: {
      label: 'Geprojecteerd',
      info: 'Geprojecteerde voorraad voor dit item, in elk magazijn.',
    },
    warehouse: 'Magazijn',
    storageLocationCode: 'Opslaglocatiecode',
    stock: {
      label: 'Voorraad',
      info: 'Beschikbare voorraad in het magazijn gerelateerd aan deze order.',
    },
    warehouseStock: {
      label: 'Ander',
      info: 'Beschikbare voorraad in alle andere magazijnen niet gerelateerd aan deze order.',
    },
    message: {
      noStock: 'Geen voorraad.',
      handledByErp: 'Verwerkt door ERP',
    },
  },
  radan: {
    export: 'Exporteer naar radan',
    import: {
      choose: 'Kies bestand voor import van radan',
      upload: 'Upload',
      file: {
        label: 'Radan export bewstand (.drg)'
      },
      select: 'Selecteer bestanden',
      validate: 'Valideer bestanden',
      someInvalid: 'Kan niet uploaden: sommige bestanden zijn niet geldig',
      valid: 'Geldig bestand',
      invalid: 'Ongeldig bestand',
      needsValidation: 'Validatie nodig',
    }
  },
  radanIntegration: {
    uploadModal: {
      title: 'Radan Import "{{name}}"',
      chooseFiles: 'Selecteer bestanden om te uploaden.',
      trigger: 'Radan import "{{name}}"',
    },
    field: {
      symbolMetafield: {
        label: 'Symboolmetafield'
      }
    }
  },
  radanIntegrationInbox: {
    retryTooltip: 'Opnieuw importeren',
    field: {
      id: { label: 'ID' },
      status: {
        label: 'Status',
        value: {
          pending: 'Pending',
          processing: 'Processing',
          error: 'Error',
          success: 'Success',
        },
      },
      originalFilename: { label: 'Bestandsnaam' },
      errors: { label: 'Fouten' },
      nests: { label: 'Nests' },
      performances: { label: 'Uitgevoerde Stappen' },
    }
  },
  planning: {
    counts: {
      open: 'ERP klaar',
      released: 'Vrijgegeven',
      flagged: 'Gevlagd',
      inbound: 'Inkooporder te laat',
      outbound: 'Planning te laat',
      capacity: 'Capaciteitsproblemen',
      materialPlan: 'Voorraadproblemen'
    },
    trafficLight: {
      green: 'Groen',
      yellow: 'Geel',
      red: 'Rood',
      grey: 'Grijs',
      none: 'Alles'
    }
  },
  color: {
    red: 'Rood',
    orange: 'Oranje',
    yellow: 'Geel',
    olive: 'Olijfgroen',
    green: 'Groen',
    teal: 'Turquoise',
    blue: 'Blauw',
    violet: 'Violet',
    purple: 'Paars',
    pink: 'Roze',
    brown: 'Bruin',
    grey: 'Grijs',
    black: 'Zwart',
    none: 'Geen kleur',
  },
  bulkActions: {
    markColor: {
      red: '(De)markeer rood',
      blue: '(De)markeer blauw',
      yellow: '(De)markeer geel',
      violet: '(De)markeer violet',
      grey: '(De)markeer grijs',
      black: '(De)markeer zwart',
      custom: '(De)markeer {{text}}',
    },
  },
  history: {
    title: {
      label: 'History',
      empty: 'No history found.',
    },
    dueTo: ' due to reason:',
    at: 'at',
    field: {
      user: {
        label: 'User'
      },
      time: {
        label: 'Time'
      },
      model: {
        label: 'Model'
      },
      field: {
        label: 'Field'
      },
      old: {
        label: 'Old'
      },
      new: {
        label: 'New'
      },
      id: {
        label: 'Id'
      }
    }
  },
  storageLocationTable: {
    fields: {
      warehouse: 'Magazijn',
      storageLocationCode: 'Opslaglocatiecode',
      count: '#',
      countPerUnit: '#/stuk',
      code: 'Code',
      description: 'Beschrijving',
    }
  },
}
