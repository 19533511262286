import { getViewStore } from './viewStore';

/**
 * Determines if the current user has access to special powers. An example is extra buttons are shown that can make life
 * easier to configure Tracy such as regenerating prints on performances.
 *
 * The global viewStore has an observable `viewStore.isPowerUser` which is used to render the current power user status.
 * For all other purposes, use `isPowerUser()`.
 *
 * @return {boolean} Returns true if the user is a power user, otherwise false.
 */
export function isPowerUser(): boolean {
    return localStorage.getItem('isPowerUser') === 'true'
}

export function setPowerUser(isPowerUser: boolean) {
    localStorage.setItem('isPowerUser', isPowerUser ? 'true' : 'false')

    getViewStore().isPowerUser = isPowerUser
}

export function configurePowerUser(viewStore) {
    viewStore.isPowerUser = isPowerUser()
}
