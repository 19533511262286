import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Operator } from './Operator'
import { ProductionRequest } from './ProductionRequest'
import { ArticleType } from './ArticleType'

export class ProductionMessage extends Model {
  static backendResourceName = 'production_message'

  @observable id = null
  @observable message = ''
  @observable category = ''

  relations() {
    return {
        operator: Operator,
        productionRequest: ProductionRequest,
        articleType: ArticleType,
    }
  }
}

export class ProductionMessageStore extends Store {
  static backendResourceName = 'production_message'
  Model = ProductionMessage
}
