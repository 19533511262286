import React from 'react'
import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ProductionRequest } from './ProductionRequest'
import { PerformanceStore } from './Performance'
import { Step } from './Step'
import { Detail } from './Detail'
import { LoadCarrier } from './LoadCarrier'
import { BatchType, TYPE_COMPONENT } from './BatchType'
import { BatchUsageStore } from './BatchUsage'
import { ExactGoodsDeliveryLineStore } from './ExactGoodsDeliveryLine'
import { ExactGoodsReceiptLineStore } from './ExactGoodsReceiptLine'
import { MetavalueStore } from './Metavalue'
import { StorageLocation } from './StorageLocation'
import { ProductionRequestLinkStore } from './ProductionRequestLink'
import { Warehouse } from './Warehouse'
import Decimal from 'decimal.js'
import { ExactSyncRequestStore } from 'store/ExactSyncRequest'
import { Popup } from 'semantic-ui-react'
import { BatchIconBlocked } from 'component/Batch/IconBlocked'

// model relations needed to use batch.getRelatedLinks()
export const RELATED_LINK_RELATIONS = [
'productionRequest.inShipment.purchaseOrders.exactPurchaseOrder',
'productionRequest.inShipmentLine.inShipment.purchaseOrders.exactPurchaseOrder',
'productionRequest.inShipment.purchaseOrders.supplier',
'productionRequest.inShipmentLine.inShipment.purchaseOrders.supplier',

'productionRequest.outShipment.salesOrder.customer',
'productionRequest.outShipmentLine.outShipment.salesOrder.customer',
'productionRequest.outShipment.salesOrder.exactSalesOrder',
'productionRequest.outShipmentLine.outShipment.salesOrder.exactSalesOrder',

'productionRequest.warehouseTransfer.productionRequest.exactSyncRequests',
'productionRequest.warehouseTransferLine.warehouseTransfer',
'productionRequest.productionOrder.exactShopOrder',
'productionRequest.stockCount',
]

export class Batch extends Model {
  static backendResourceName = 'batch'
  static omitFields = ['scrappable', 'reworkDeletesCarrierRows']

  @observable id = null
  @observable serialNumber = ''
  @observable scrapReason = null
  @observable quantity = Decimal(1)
  @observable variableQuantity = false
  @observable expectedQuantity = 1
  @observable resettable = false
  @observable finalizedAt = null
  @observable deleted = false
  @observable createdAt = null

  // Annotations
  @observable bestBeforeDate = null
  @observable scrappable = false
  @observable finished = false
  @observable quantityRemaining = Decimal(1)
  @observable lastPerformance = null
  @observable reworkDeletesCarrierRows = false
  @observable blocked = false
  @observable blockReason = ''

  @computed
  get isSubassembly() {
    return this.batchType?.type === TYPE_COMPONENT
  }

  toBackend(...args) {
    const res = super.toBackend(...args)
    delete res.finished
    delete res.quantity_remaining
    delete res.resettable
    delete res.last_performance
    delete res.warehouse_id
    return res
  }

  relations() {
    return {
      productionRequest: ProductionRequest,
      lastStep: Step,
      performances: PerformanceStore,

      batchType: BatchType,
      detail: Detail,
      batchUsings: BatchUsageStore,
      batchUseds: BatchUsageStore,

      exactGoodsDeliveryLines: ExactGoodsDeliveryLineStore,
      exactGoodsReceiptLines: ExactGoodsReceiptLineStore,
      loadCarrier: LoadCarrier,

      metavalues: MetavalueStore,
      flatMetavalues: MetavalueStore,

      warehouse: Warehouse,
      storageLocation: StorageLocation,
      subProductionRequestBatchLinks: ProductionRequestLinkStore,

      componentBatches: BatchStore,
      sourceBatch: Batch,

      exactSyncRequests: ExactSyncRequestStore,
    }
  }

  casts() {
    return {
      quantity: Casts.decimal,
      quantityRemaining: Casts.decimal,
      finalizedAt: Casts.datetime,
      lastPerformance: Casts.datetime,
      bestBeforeDate: Casts.date,
      createdAt: Casts.datetime,
    }
  }

  rework(reason, storageLocation = null, createNewBatch = false) {
    return this.wrapPendingRequestCount(this.api.post(
      `${this.url}rework/`, {
        reason: reason,
        storage_location: storageLocation,
        create_new_batch: createNewBatch,
      }
    ))
  }

  getBlockedLabel() {
    return (
      <Popup
        content="This batch is blocked"
        trigger={<BatchIconBlocked />}
      />
    )
  }

  getRelatedLinks() {
    const inShipment = (
      !this.productionRequest.inShipment.isNew
        ? this.productionRequest.inShipment
        : this.productionRequest.inShipmentLine.inShipment
    )
    const outShipment = (
      !this.productionRequest.outShipment.isNew
        ? this.productionRequest.outShipment
        : this.productionRequest.outShipmentLine.outShipment
    )
    const warehouseTransfer = (
      !this.productionRequest.warehouseTransfer.isNew
        ? this.productionRequest.warehouseTransfer
        : this.productionRequest.warehouseTransferLine.warehouseTransfer
    )
    const productionOrder = this.productionRequest.productionOrder
    const stockCount = this.productionRequest.stockCount

    const purchaseOrders = inShipment.purchaseOrders
    const salesOrder = outShipment.salesOrder

    return <>
      {!inShipment.isNew && inShipment.getLink()}
      {purchaseOrders.map((purchaseOrder) => !purchaseOrder.isNew && purchaseOrder.getLink(purchaseOrder.supplier && {
        hover: (
          <>
            <b>{t('purchaseOrder.field.supplier.label')}:</b>{' '}
            {purchaseOrder.supplier.name}
          </>
        ),
      }))}
      {!outShipment.isNew && outShipment.getLink()}
      {!salesOrder.isNew && salesOrder.getLink(salesOrder.customer && {
        hover: (
          <>
            <b>{t('salesOrder.field.customer.label')}:</b>{' '}
            {salesOrder.customer.name}
          </>
        ),
      })}
      {!warehouseTransfer.isNew && warehouseTransfer.getLink()}
      {!productionOrder.isNew && productionOrder.getLink()}
      {!stockCount.isNew && stockCount.getLink()}
      {!salesOrder.isNew && salesOrder.getErpLinks()}
      {purchaseOrders.map((purchaseOrder) => !purchaseOrder.isNew && purchaseOrder.getErpLinks())}
      {!productionOrder.isNew && productionOrder.getErpLinks()}
    </>
  }

}

export class BatchStore extends Store {
  Model = Batch
  static backendResourceName = 'batch'
}
