import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleTypeStore } from './ArticleType'
import { MetafieldStore } from './Metafield'
import { MetavalueStore } from './Metavalue'

export class Classification extends Model {
  static backendResourceName = 'classification'

  @observable id = null
  @observable name = ''
  @observable trackBatchUsage = true
  @observable articleTypeCount = 0
  @observable articleTypeWithOverrideTrackBatchUsageCount = 0
  @observable deleted = false
  @observable explodeAtDeliveryBehaviour = null

  static omitFields = ['articleTypeCount', 'articleTypeWithOverrideTrackBatchUsageCount']

  static idColor = 'pink'
  static idPrefix = true  // needed for admin overview check

  get _labelContent() {
    return this.name
  }

  getIdIcon() {
    return  'folder'
  }

  getIdPrefix() {
    return  ''
  }

  relations() {
    return {
      articleTypes: ArticleTypeStore,
      metafields: MetafieldStore,
      metavalues: MetavalueStore,
    }
  }

  toBackendAll({ nestedRelations: { metavalues, ...nestedRelations } = {}, ...options } = {}) {
    const { data: [data], relations } =  super.toBackendAll({ nestedRelations, ...options })

    if (metavalues !== undefined) {
      delete data.metavalues

      // eslint-disable-next-line
      for (const metavalue of this.metavalues.models) {
        const { data: [subdata], relations: subrelations } = metavalue.toBackendAll({ nestedRelations: metavalues })
        delete subdata.metafield
        data[`metafield(${metavalue.metafield.id})`] = subdata

        // eslint-disable-next-line
        for (const [rel, reldata] of Object.entries(subrelations)) {
          if (relations[rel] === undefined) {
            relations[rel] = []
          }
          relations[rel].push(...reldata)
        }
      }
    }
    return { data: [data], relations }
  }

  resetTrackBatchUsageFollowGroup() {
    return this.wrapPendingRequestCount(
      this.api.put(this.url + 'reset_track_batch_usage_follow_group/').then(() => {
        this.articleTypeWithOverrideTrackBatchUsageCount = 0;
      })
    )
  }
}

export class ClassificationStore extends Store {
  Model = Classification
  static backendResourceName = 'classification'
}
