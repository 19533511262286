import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { TIME_UNITS, TIME_UNITS_ABBREVIATIONS } from '../../../../store/Step';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { TargetNumberInput } from '@code-yellow/spider';


const OverlayDropdown = styled(Dropdown)`
    width: 67px;
    margin-left: -54px !important;
`;

@observer
export default class TargetTimeWithUnitInput extends Component {
    static propTypes = {
        onChangeUnit: PropTypes.func.isRequired,
        unitValue: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
    }

    static defaultProps = {
        disabled: false,
    }

    render() {
        const { onChangeUnit, disabled, unitValue, name, ...props } = this.props

        return <TargetNumberInput data-test-time-with-unit-input={name}
            {...props}
            name={name}
            disabled={disabled}
            contentProps={{
                labelPosition: 'right',
                label: (
                    <OverlayDropdown data-test-unit-select={name}
                        value={unitValue}
                        onChange={onChangeUnit}
                        options={TIME_UNITS.map((value) => ({ value, text: TIME_UNITS_ABBREVIATIONS[value] }))}
                        disabled={disabled}
                    />
                ),
            }}
        />
    }
}
