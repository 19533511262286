import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from './ArticleType'
import Decimal from 'decimal.js';
import { AssemblyBomItemStore } from './AssemblyBomItem';

export class AssemblyBomVersion extends Model {
  static backendResourceName = 'assembly_bom_version'
  static omitFields = ['erpQuantityRemaining']

  @observable id = null
  @observable description = ''
  @observable notes = ''
  @observable version = 1
  @observable active = false
  @observable batchQuantity = Decimal(0)
  @observable useExplosion = false


  relations() {
    return {
      articleType: ArticleType,
      items: AssemblyBomItemStore,
    }
  }

}

export class AssemblyBomVersionStore extends Store {
  Model = AssemblyBomVersion
  static backendResourceName = 'assembly_bom_version'

  getMaxVersion = () => {
    if (this.models.length === 0) {
      return null
    }
    return this.models.reduce((model1, model2) => {
      return model1.version > model2.version ? model1 : model2
    })
  }

  getActiveVersion = () => {
    if (this.models.length === 0) {
      return null
    }
    // There should only be 1 active version
    return this.models.find((v) => v.active)
  }
}
