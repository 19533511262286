import styled from 'styled-components';
export const SidebarTabItem = styled.div<{
  active?: boolean;
}>`
  position: relative;
  margin: 0 -25px !important;
  padding: 1em 25px 1em 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    margin-top: 0 !important;
  }

  border-right: 6px solid transparent;

  cursor: pointer;
  transition: background-color 150ms;

  &:hover {
    background-color: #e6e7e8;
  }

  ${({
  active
}) => active && `
    background-color: #e0e1e2 !important;
    border-right-color: #424242;
  `}
`;
export const SidebarSeparator = styled.div<{
  noBottomMargin?: boolean;
}>`
  margin: 1.5rem -25px 1em;
  padding: 0.75em 19px 0.75em 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  font-weight: bold;

  ${({
  noBottomMargin
}) => noBottomMargin && `
    margin-bottom: 0;
  `}
`;