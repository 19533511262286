import { ProductionRequest, ProductionRequestStore } from '../store/ProductionRequest';
import { WorkStation } from '../store/WorkStation';
import { Step, StepStore } from '../store/Step';
import { Batch } from '../store/Batch';
import { ProcessVersion } from '../store/ProcessVersion';
import { ExactShopOrder } from '../store/ExactShopOrder';
import { ExactGlobeProductionOrder } from '../store/ExactGlobe/ProductionOrder';

export function getArticleTypeName(productionRequest: ProductionRequest) {
    // @ts-ignore
    if ((productionRequest?.outShipmentLine?.salesOrderLine?.id && productionRequest?.outShipmentLine?.salesOrderLine.articleType.id !== productionRequest.articleType.id) &&
        // @ts-ignore
         (productionRequest?.outShipmentLine?.salesOrderLine.decsription === undefined || productionRequest?.outShipmentLine?.salesOrderLine.decsription === productionRequest.outShipmentLine.salesOrderLine.articleType.name)){
            // @ts-ignore
            return t('productionRequest.overview.assemblyAtDelivery', { article: productionRequest.outShipmentLine.salesOrderLine.articleType.name })
        }

    // @ts-ignore
    return productionRequest?.outShipmentLine?.salesOrderLine?.description || productionRequest?.inShipmentLine?.purchaseOrderLine?.description || productionRequest.processVersion.batchType.articleType.name;
}

/**
 * For a production request, return all the steps at the front of the process
 *
 * Steps only have a next step. So we get all the next_Steps, and then check which steps are not referenced
 * @param productionRequest
 */
export function getFirstStepsOfProductionRequests(productionRequest: ProductionRequest): Step[] {
    // @ts-ignore
    const stepIdsWithPreviousStep: number[] = productionRequest.processVersion.steps.map(
        step => {
            return step?.nextStep?.id;
        }
    )


    // @ts-ignore
    return productionRequest.processVersion.steps.filter(
        step => {
            return !stepIdsWithPreviousStep.includes(step.id)
        }
    )
}


/**
 * Create a map from step id, to the workstation id that performs the step
 *
 * @param processVersion
 */
export function stepToWorkStationMap(processVersions: ProcessVersion[]): Map<number, number> {
    const stepToWorkStationMap = new Map<number, number>();

    // @ts-ignore
    processVersions.forEach(processVersion => {
        // @ts-ignore
        processVersion.steps.each(
            (step: Step) => {
                // @ts-ignore
                const workStation: WorkStation = step.workStation;

                if (!workStation.id) {
                    throw Error('No workstations attached to the step!')
                }
                // @ts-ignore
                stepToWorkStationMap.set(step.id, workStation.id)
            }
        )
    }
    )

    return stepToWorkStationMap
}

/**
 * For a particular batch, check what the next steps are for the batch
 *
 * Note that for one batch, it is possible that there are multiple first steps that can be performed
 *
 * @param productionRequest
 * @param batch
 */
export function getNextStepsOfBatch(productionRequest: ProductionRequest, batch: Batch): Step[] {
    // @ts-ignore
    const lastStep: Step = batch.lastStep;

    // @ts-ignore
    const nextStep: Step | null = lastStep?.nextStep;
    if (nextStep !== null && nextStep.id !== null) {
        return [nextStep];
    }

    return getFirstStepsOfProductionRequests(productionRequest);
}


/**
 * For this workstation, create a mapping of each steps, and which production request have a step ready to be performed
 * for this workstation
 *
 * Note that one production request can belong various steps, if there are multiple batches
 *
 * Note that the steps in the map are always the step from the process version, with all the related data
 */
export function sortProductionRequestsForWorkStation(
    workStation: WorkStation, productionRequests: ProductionRequestStore
): Map<Step, ProductionRequest[]> {
    // Note that steps are linked by id, and not by reference, so, have another map to
    // link steps with the same id
    const stepMap = new Map<number, Step>();
    const result = new Map<Step, ProductionRequest[]>();

    // Link all the steps in the step map to the step in the process version, such that we have all the relations of this steps
    // @ts-ignore
    productionRequests.forEach((productionRequest: ProductionRequest) => {
        // @ts-ignore
        const steps: StepStore = productionRequest.processVersion.steps;
        // @ts-ignore
        steps.forEach((step: Step) => {
            // @ts-ignore
            if (stepMap.has(step.id)) {
                return;
            }
            // @ts-ignore
            stepMap.set(step.id, step)
            result.set(step, [])
        })
    })

    // @ts-ignore
    const stepToWorkStation = stepToWorkStationMap(productionRequests.map((productionRequest: ProductionRequest) => {
        // @ts-ignore
        return productionRequest.processVersion;
    }))

    // @ts-ignore
    productionRequests.forEach((productionRequest: ProductionRequest) => {

        // @ts-ignore
        productionRequest.batches.forEach((batch: Batch) => {

            const steps = getNextStepsOfBatch(productionRequest, batch);
            steps.forEach((step: Step) => {
                // Another work station, ignore
                // @ts-ignore
                if (stepToWorkStation.get(step.id) !== workStation.id) {
                    return
                }


                // Add this step to the map if it is not there
                // @ts-ignore
                if (!stepMap.has(step.id)) {
                    // @ts-ignore
                    stepMap.set(step.id, step)
                    result.set(step, []);
                }

                // @ts-ignore
                const linkedStep = stepMap.get(step.id)

                // Add to the map

                // @ts-ignore
                result.get(linkedStep).push(productionRequest)
            })


        });
    })


    /**
     * Delete empty results, since they are not needed anymore
     */
    result.forEach((requests, step) => {
        if (requests.length === 0) {
            result.delete(step)
        }
    })

    return result;
}

/**
 * For a set of production requests, return all the batches which are in a current step
 * @param step
 * @param productionRequests
 */
export function getBatchesForStep(step: Step, productionRequests: ProductionRequest[]): Batch[] {
    const result: Batch[] = [];
    productionRequests.forEach((productionRequest: ProductionRequest) => {
        // @ts-ignore
        productionRequest.batches.forEach((batch: Batch) => {
            const steps = getNextStepsOfBatch(productionRequest, batch);

            steps.forEach((nextStep: Step) => {
                if (nextStep.id === step.id) {
                    result.push(batch)
                }
            })


        })

    })

    return result;
}

export function mergeWheres(...wheress) {
    const merged = {}

    // eslint-disable-next-line
    for (const wheres of wheress) {
        // eslint-disable-next-line
        for (const [rel, params] of Object.entries(wheres)) {
            if (merged[rel] === undefined) {
                merged[rel] = {}
            }
            Object.assign(merged[rel], params)
        }
    }
    return merged
}

export function prefixWheres(prefix, wheres) {
    return Object.fromEntries(
        Object.entries(wheres)
            .map(([rel, params]) => [prefix + rel, params])
    )
}


export function getSuperProductionRequests(productionRequest: ProductionRequest): ProductionRequest[] {
    // @ts-ignore
    return productionRequest.superProductionRequestLinks.map((link: ProductionRequestLink) => link.superProductionRequestBatch.productionRequest);
}

export function getExactShopOrders(productionRequest: ProductionRequest): ExactShopOrder[] {
    const orders: ExactShopOrder[] = [];

    // @ts-ignore
    if (!productionRequest.productionOrder.exactShopOrder.isNew) {
        // @ts-ignore
        orders.push(productionRequest.productionOrder.exactShopOrder);
    }

    for (const superProductionRequest of getSuperProductionRequests(productionRequest)) {
        // @ts-ignore
        if (!superProductionRequest.productionOrder.exactShopOrder.isNew) {
            // @ts-ignore
            orders.push(superProductionRequest.productionOrder.exactShopOrder);
        }
    }

    return orders;
}

export function getExactGlobeProductionOrders(productionRequest: ProductionRequest): ExactGlobeProductionOrder[] {
    const orders: ExactGlobeProductionOrder[] = [];

    // @ts-ignore
    if (!productionRequest.productionOrder.exactGlobeProductionOrder.isNew) {
        // @ts-ignore
        orders.push(productionRequest.productionOrder.exactGlobeProductionOrder);
    }

    for (const superProductionRequest of getSuperProductionRequests(productionRequest)) {
        // @ts-ignore
        if (!superProductionRequest.productionOrder.exactGlobeProductionOrder.isNew) {
            // @ts-ignore
            orders.push(superProductionRequest.productionOrder.exactGlobeProductionOrder);
        }
    }

    return orders;
}
