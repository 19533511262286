import React, { Component } from 'react'
import { Icon, IconGroup } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const MessageIconGroup = styled(IconGroup)`
  .top.right.textholder {
    text-shadow: none;
    color: white;
  }
  .top.right.circle {
    color: black;
  }
`

export default class MessageIcon extends Component {
    static propTypes = {
        count: PropTypes.number.isRequired,
    }

    render() {
        const { count, ...props } = this.props
        return <MessageIconGroup {...props} data-test-message-icon-group>
            <Icon data-test-production-messages name='comment outline' />
            {count > 0 && <>
                <Icon corner='top right' name='circle'/>
                <Icon corner='top right' name='textholder'>
                    {count}
                </Icon>
            </>}
        </MessageIconGroup>
    }
}
