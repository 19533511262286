import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Button, Modal } from 'semantic-ui-react'
import { CancelButton, IconButton, RightDivider } from '@code-yellow/spider'
import { Performance } from 'store/Performance'
import { observable } from 'mobx'
import { isPowerUser } from '../helpers/isPowerUser'
import { showSaveNotification } from '../helpers/notification'

interface ZplModalProps {
  performance: Performance,
}

/**
 * ZplModal Component
 *
 * This component represents a modal dialog for viewing and regenerating ZPL (Zebra Programming Language) label
 * templates.
 *
 * Power Tools:
 * - Regenerate `performance.printed_template` button
 *
 * TODO:
 * - Currently hardcoded label size of 4x6 inch and image size of 500x500.
 */
@observer
export default class ZplModal extends Component<ZplModalProps> {
  @observable printVersion = 1
  @observable isOpen = false;
  open = () => this.isOpen = true;
  close = () => this.isOpen = false;


  render() {
    const { performance } = this.props;

    return (
      <Modal
        open={this.isOpen}
        trigger={<IconButton name="eye" data-test-view-printed-template onClick={this.open} />}
        onClose={this.close}
      >
        <Modal.Header>Label</Modal.Header>
        <Modal.Content scrolling>
          <img style={{ maxHeight: '500px', maxWidth: '500px' }} src={`https://api.labelary.com/v1/printers/8dpmm/labels/4x6/0/${performance.printedTemplate}/?version=${this.printVersion}`}/>
        </Modal.Content>
        <Modal.Actions>
          <CancelButton onClick={this.close} />
          <RightDivider />
          {isPowerUser() && (
            <Button primary data-test-regenerate-print-button
              loading={performance.isLoading}
              icon="refresh"
              content="Regenerate using latest process version"
              onClick={() => performance.regeneratePrint()
                .then(() => performance.fetch())
                .then(showSaveNotification)
                .then(() => this.printVersion += 1)
              }
            />
          )}
        </Modal.Actions>
      </Modal>
    )
  }
}
