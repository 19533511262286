import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { action, computed, observable, reaction } from 'mobx';
import { observer } from 'mobx-react'
import { Checkbox, Icon, Table, Confirm, Popup } from 'semantic-ui-react'
import styled from 'styled-components';
import { ErrorLabel } from '@code-yellow/spider';
import Decimal from 'decimal.js'
import Batches from 'container/Perform/Step/Form/Batches'
import { SerialNumber } from '../TargetInfoModal/TargetDescription'
import { FullWidthTable } from './FormStep'

// components
import TargetDecimalPlusMinusInput from './DecimalPlusMinusInput';
// end components

// helpers
import { getViewStore } from 'helpers/viewStore'
import { humanReadable } from '../../helpers/decimal';
import { isMyWorkstation, isUnknownWorkstation } from './helpers'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
// end helpers

// stores
import { Step } from 'store/Step';
import { BillOfMaterialVersion } from 'store/BillOfMaterialVersion';
import { ProductionRequest } from 'store/ProductionRequest'
// end stores

const BatchInfo = styled.div`
  display: flex;
  padding: 5px;
`

const DefaultLocationText = styled.i`
  color: gray; 
  padding-left: 1em;
  vertical-align: top;
`


/**
 * Contains the actual table of the material plan to be executed
 */
@observer
export class MaterialPlanMaterialsTable extends Component {
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest),
    materialPlan: PropTypes.instanceOf(BillOfMaterialVersion).isRequired,
    step: PropTypes.instanceOf(Step).isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.number.isRequired,
    quantityTodo: PropTypes.number.isRequired,
    value: PropTypes.object.isRequired,
    targetProps: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    getErrors: PropTypes.func.isRequired,
    variableQuantity: PropTypes.func.isRequired,
    generalErrors: PropTypes.array,
    batchSize: PropTypes.number,
    fieldRequired: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    generalErrors: [],
    getErrors: (bomItemId) => [],
    batchSize: 1,
  }

  @observable showDeletionConfirm = null
  @observable scannedUnsavedBatches = {}
  @observable DECIMAL_ROUND_PRECISION = 3
  @observable warehouseManagerFlag
  @observable productionRequest = new ProductionRequest({
    id: this.props.productionRequest.id,
  }, {
    relations: [
      'batches.lastStep',
      'batches.batchType',
      'batches.componentBatches.batchType.articleType',
      'batches.componentBatches.batchUsings.usedBatch.storageLocation',
      'productionOrder.warehouse.defaultToTransferLocation',
      'materialIssues.articleType',
    ],
  })

  @observable batchRequirements = {}
  @observable defaultStorageLocations = {}

  constructor(...args) {
    super(...args)
    this.DECIMAL_ROUND_PRECISION = getViewStore().globalDecimalRounding
    this.warehouseManagerFlag = isFeatureFlagEnabled('warehouse_manager')

    const { materialPlan } = this.props;
    this.renderMaterialPlan = this.renderMaterialPlan.bind(this)
    this.setBatchRequired = this.setBatchRequired.bind(this)

    // eslint-disable-next-line no-unused-vars
    for (const item of materialPlan.items.filter(item => item.type === 'material')) {
      this.batchRequirements[item.id] = this.calculateBatchRequires(item)
      // maintain here for intermediate save
      item.taskProgress = this.batchRequirements[item.id]
    }
  }

  calculateBatchRequires(item) {
    const { productionRequest, batchSize } = this.props;
    let value = 0
    if (item.requiredQuantity) {
      value = Decimal(item.requiredQuantity / productionRequest.quantity)
    } else {
      value = Decimal(item.quantityBatch ?? item.quantity)
    }
    // 1 is the rounding mode, in this case ROUND_DOWN
    // check out https://mikemcl.github.io/decimal.js/#modes
    return Decimal(value * batchSize).toDecimalPlaces(6, 1)
  }

  compareStorageLocationCodes(a, b) {
    const a_location = this.defaultStorageLocations[a.articleType.id]
    const b_location = this.defaultStorageLocations[b.articleType.id]
    if(!a_location){
      return 1
    }
    if(!b_location){
      return -1
    }
    const stock_a = a.articleType.storageLocations.find(asl => asl.storageLocation.id === a_location.id).stock
    const stock_b = b.articleType.storageLocations.find(asl => asl.storageLocation.id === b_location.id).stock
    const noLocation_a = this.batchRequirements[a.id]>stock_a || a.handledByErp
    const noLocation_b = this.batchRequirements[b.id]>stock_b || b.handledByErp

    if (noLocation_a && noLocation_b){
      return 0
    }
    if (noLocation_a){
      return 1
    }
    if (noLocation_b){
      return -1
    }

    return this.defaultStorageLocations[a.articleType.id].code.localeCompare(this.defaultStorageLocations[b.articleType.id].code)
  }

  componentDidMount() {
    const { fieldRequired, materialPlan } = this.props

    this.productionRequest.fetch().then(() => {
      // Calculate the default storage locations for each article type
      // eslint-disable-next-line no-unused-vars
      for (const item of materialPlan.items.filter(item => item.type === 'material')) {
        this.defaultStorageLocations[item.articleType.id] = this.getDefaultLocation(item.articleType)
      }
    })
    this.productionRequestQuantityReaction = reaction(
      () => this.props.variableQuantity,
      action((quantity) => {
        // Should change 'required for this batch' if quantity formfield (this.props.variableQuantity) is changed
        const { materialPlan } = this.props;

        materialPlan.items.filter(item => item.type === 'material').forEach((item) => {
          this.batchRequirements[item.id] = this.calculateBatchRequires(item)
          this.setBatchRequired(item, this.batchRequirements[item.id])
        })
      }),
    )

    if(fieldRequired){
      materialPlan.items.forEach((item) => {
        item.forceField = true
      })
    }
  }

  componentWillUnmount() {
    this.productionRequestQuantityReaction()
  }

  batchesScanOnChange(value, materialPlanItem) {
    const { onChange } = this.props;
    onChange(materialPlanItem, value, null, null)
    this.scannedUnsavedBatches[materialPlanItem.id] = value
  }

  getScannedBatches(item) {
    // collect all batches that are SAVED and CONFIRMED
    // We check this by looking for component batches connected to this PR
    const batches = []
    this.productionRequest.batches.forEach((batch) =>{
        batch.componentBatches.forEach((component) => {
          if (component.deleted === false && component.scrapReason === null && component.batchType.articleType.id === item.articleType.id){
            const usedBatch = component.batchUsings.at(0).usedBatch
            const sl = this.getStorageLocation(item.articleType.storageLocations, usedBatch)
            batches.push({ 'batch': component, 'serialNumber': usedBatch.serialNumber, 'quantity': component.quantity, 'storageLocation': sl, 'usedBatch': usedBatch })
          }
        })
    })
    return batches
  }

  getScannedBatchesQuantity(item) {
    const batches = this.getScannedBatches(item)
    return (
      batches.reduce((total, { quantity }) => total.add(quantity), Decimal(0))
    )
  }

  @computed get batchCollected() {
    const { materialPlan } = this.props;
    const batchCollected = {}

    // eslint-disable-next-line
    for (const item of materialPlan.items.filter(item => item.type === 'material')) {
      let unsavedSum
      if (this.scannedUnsavedBatches[item.id]) {
        unsavedSum = this.scannedUnsavedBatches[item.id].reduce((total, { usage }) => total.add(usage ? usage : 0), Decimal(0))
      } else {
        unsavedSum = Decimal(0)
      }

      // collect all batches CONFIRMED to this line, and sum the batch usages
      const savedSum = this.getScannedBatchesQuantity(item)

      batchCollected[item.id] = {
        unsavedSum: unsavedSum,
        savedSum: savedSum,
        totalSum: unsavedSum.add(savedSum),
      }
    }

    return batchCollected
  }


  renderBatchesScan(materialPlanItem, quantity) {
    const { value, onConfirm, getErrors, targetProps } = this.props;

    const currentWarehouse = this.productionRequest.productionOrder.warehouse
    return (
      // We use the key to force a re-render of the Batches component when the default storage location changes
      <Batches key={this.defaultStorageLocations[materialPlanItem.articleType.id]}
        data-test-batches-item={materialPlanItem.id}
        {...targetProps}
        articleType={materialPlanItem.articleType}
        quantity={quantity}
        value={value}
        errors={getErrors(materialPlanItem.id.toString())}
        onChange={(value) => this.batchesScanOnChange(value, materialPlanItem)}
        onConfirm={(value) => onConfirm(materialPlanItem)}
        allowStorageLocationSelection={!materialPlanItem?.backflush && currentWarehouse.useStorageLocations}
        currentWarehouse={currentWarehouse}
        rounding={this.DECIMAL_ROUND_PRECISION}
        showWarehouse={materialPlanItem?.backflush}
        defaultStorageLocation={this.defaultStorageLocations[materialPlanItem.articleType.id]}
      />
    )
  }

  getDefaultLocation(articleType){
    const currentWarehouse = this.productionRequest.productionOrder.warehouse
    if (!currentWarehouse || !currentWarehouse.useStorageLocations){
      return null
    }

    let storageLocation = null

    if (this.warehouseManagerFlag){
      storageLocation = currentWarehouse.defaultToTransferLocation
    }

    else {
      const atwh = articleType.warehouses.models.find((atwh) => atwh.warehouse.id === currentWarehouse.id)

      storageLocation = atwh?.defaultStorageLocation
    }
    return(storageLocation)
  }

  getDefaultLocatonCode(articleType) {
    const storageLocation = this.getDefaultLocation(articleType)
    if(!storageLocation){
      return
    }

    return(
      <DefaultLocationText>
        {t('formStepField.field.materialPlan.defaultLocation', { 'locationCode':storageLocation.code })}
      </DefaultLocationText>
    )
  }

  renderPerformMaterial(materialPlanItem, quantity) {
    const scannedBatches = this.getScannedBatches(materialPlanItem)
    if (scannedBatches.length > 0) {
      return this.renderScannedBatches(materialPlanItem, scannedBatches)
    }
    if (this.props.fieldRequired || !materialPlanItem.backflush || materialPlanItem.forceField) {
      return this.renderBatchesScan(materialPlanItem, quantity)
    }

    return (
      <div >
        <Checkbox style={{ verticalAlign: 'top' }} toggle data-test-materials-item={ materialPlanItem.id }
          checked={ materialPlanItem.forceField }
          onChange={(e, { checked }) => {
            materialPlanItem.forceField = true
            // Without this the field doesn't get rendered since materialPlanItem is not an observable
            this.forceUpdate()
          }}
        />
        {this.getDefaultLocatonCode(materialPlanItem.articleType)}
      </div>
    )
  }

  getRequiredByBatch(item) {
    const { batchSize } = this.props;
    return Decimal(
      (item.requiredQuantity ?
        (item.requiredQuantity / this.productionRequest.quantity) :
        (item.quantityBatch ?? item.quantity)
      ) * batchSize,
    )
  }

  getSerialNumber(batch) {
    if (batch.id) {
      // Once a batch is intermediate_saved we can get it by id
      return batch.serialNumber
    }
    return batch['serial_number']
  }

  getQuantityUsed(batch) {
    if (batch.id) {
      // Once a batch is intermediate_saved we can get it by id
      return batch.quantity //batch.batchUseds.models.reduce((total, { quantity }) => total.add(quantity), Decimal(0))
    }
    return batch['usage'] ? batch['usage'] : 0
  }

  getStorageLocation(articleTypeStorageLocations, batch) {
    // Once a batch is intermediate_saved we can get it by id
    const ats = articleTypeStorageLocations.find(asl => asl.storageLocation.id === batch.storageLocation?.id)
    const stockSuffix = ats ? ` (${humanReadable(ats.stock, this.DECIMAL_ROUND_PRECISION)})` : ''
    return batch.storageLocation?.code + stockSuffix
  }

  getPRWarehouseStock(articleType) {
    if (articleType?.storageLocations?.models && this.productionRequest?.productionOrder?.warehouse) {
      const storageLocations = articleType.storageLocations.models.filter(sl => sl.warehouse.code === this.productionRequest.productionOrder.warehouse.code)
      const stock = storageLocations.reduce((sum, sl) => sum.add(sl.stock), Decimal(0))
      return stock
    }
    else return Decimal(0)
  }

  async onRemoveScannedBatch(batch) {
    if (batch.batch) {
      batch.batch.scrapReason = `Deleted in Material Plan of production request ${this.productionRequest.id}`
      await batch.batch.rework(`Deleted in Material Plan of production request ${this.productionRequest.id}`, null, false)
    }
  }

  renderBatch(materialPlanItem, batch) {
    const serialNumber = batch.serialNumber
    const quantityUsed = humanReadable(batch.quantity, this.DECIMAL_ROUND_PRECISION)
    const storageLocation = batch.storageLocation
    return (
      <BatchInfo data-test-scanned-batches>
        <SerialNumber data-test-serial-number={serialNumber}>
          {quantityUsed} | {serialNumber} <>(</>{humanReadable(batch.usedBatch.quantityRemaining, this.DECIMAL_ROUND_PRECISION)}/{humanReadable(batch.usedBatch.quantity, this.DECIMAL_ROUND_PRECISION)}<>)</> | {storageLocation}
        </SerialNumber>
        <label>&nbsp;</label>
        <Icon data-test-delete-material-details-scanned-batch={materialPlanItem.id}
          name="delete"
          size="large"
          style={{ cursor: 'pointer' }}
          onClick={() => this.showDeletionConfirm = materialPlanItem.id}
        />
        <Confirm
          open={this.showDeletionConfirm === materialPlanItem.id}
          header={t('formStepField.field.materialPlan.confirmDeletion.header', { description: materialPlanItem.description })}
          content={t('formStepField.field.materialPlan.confirmDeletion.content')}
          confirmButton={t('formStepField.field.materialPlan.confirmDeletion.confirmButton')}
          onCancel={() => this.showDeletionConfirm = null}
          onConfirm={action(async () => {
            this.showDeletionConfirm = null
            this.onRemoveScannedBatch(batch)
          })}
        />
      </BatchInfo>
    )
  }

  renderScannedBatches(materialPlanItem, scannedBatches) {
    const requiredQuantityForBatch = humanReadable(this.getRequiredByBatch(materialPlanItem))

    return (
      <>
        {scannedBatches.length > 0 && scannedBatches.map(batch => this.renderBatch(materialPlanItem, batch))}
        {this.renderBatchesScan(materialPlanItem, requiredQuantityForBatch)}
      </>
    )
  }

  setBatchRequired(item, val) {
    const { onChange } = this.props
    const min = Decimal(0)

    if (Decimal(val).lte(min)) {
      this.batchRequirements[item.id] = min
    } else {
      this.batchRequirements[item.id] = Decimal(val)
    }
    onChange(item, null, null, this.batchRequirements[item.id])
  }

  getRequiredQuantityForWholeOrder(item, required) {
    let requiredQuantity = Decimal(0)
    requiredQuantity = Decimal(item.requiredQuantity ?? ((item.quantityBatch ?? item.quantity) * required))

    return humanReadable(requiredQuantity, this.DECIMAL_ROUND_PRECISION)
  }

  renderWarnings(materialPlanItem) {
    const warehouseStock = this.getPRWarehouseStock(materialPlanItem.articleType) ?? Decimal(0)

    return (
      <>
        {this.batchRequirements[materialPlanItem.id].gt(warehouseStock) &&
          <Popup data-test-stock-warning-text={materialPlanItem.id}
            content={t('formStepField.field.materialPlan.stockWarning',
              {
                warehouse: this.productionRequest?.productionOrder?.warehouse?.code,
                stock: humanReadable(warehouseStock, this.DECIMAL_ROUND_PRECISION)
              })}
            trigger={<Icon data-test-stock-warning={materialPlanItem.id} color='orange' size='large' name='exclamation triangle' />} />
        }
      </>
    )
  }

  renderMaterialPlan(materialPlanItem) {
    const { required } = this.props;

    const requiredQuantityForWholeOrder = this.getRequiredQuantityForWholeOrder(materialPlanItem, required)

    const requiredQuantityForBatch = this.getRequiredByBatch(materialPlanItem)
    const issued = this.productionRequest?.materialIssues
      .filter(materialIssue => materialIssue.articleType.id === materialPlanItem.articleType.id)
      .reduce((sum, issue) => sum.plus(Decimal(issue.quantity)), Decimal(0))
    return (
      // if the item is handled by the ERP, gray out the line
      <Table.Row disabled={false} material-plan-item={materialPlanItem.id}>
        <Table.Cell collapsing>
          <b>{materialPlanItem.number + '. '}</b>
          {materialPlanItem.articleType.code && materialPlanItem.articleType.getLabel()}
          {materialPlanItem.description === materialPlanItem.articleType.name ? '' : ` ${materialPlanItem.articleType.name}`}
          {' ' + materialPlanItem.description}
        </Table.Cell>
        <Table.Cell collapsing>
          <Icon
          size="big"
          name={materialPlanItem.backflush ? 'check square' : 'square outline'}
          data-test-backflush-checkbox={materialPlanItem.backflush}
          />
        </Table.Cell>
        <Table.Cell collapsing data-test-batch-required-quantity={materialPlanItem.id}>
          <TargetDecimalPlusMinusInput
            key={`${materialPlanItem.id}-${requiredQuantityForBatch}`} // Make sure that react-text-mask re-applies props when changing capMax.
            id={materialPlanItem.id}
            capMin={0}
            capMax={requiredQuantityForBatch}
            target={materialPlanItem}
            setTarget={this.setBatchRequired}
            value={this.batchRequirements[materialPlanItem.id]}
            inputWidth={6}
            buttonWidth={4}
            disableMax={true}
            disableColors={true}
            // additional LimitedDecimalInput props
            placeholder={t('workStation.production.performModal.scan.quantity')}
            decimalSymbol="."
            thousandsSeparatorSymbol=","
            decimalLimit={this.DECIMAL_ROUND_PRECISION}
          />
        </Table.Cell>
        <Table.Cell collapsing>
        {this.renderWarnings(materialPlanItem)}
        </Table.Cell>
        <Table.Cell collapsing data-test-issued-quantity={materialPlanItem.id} colSpan={0}>
          {humanReadable(issued, this.DECIMAL_ROUND_PRECISION)}
        </Table.Cell>
        <Table.Cell data-test-item-perform={materialPlanItem.id} style={{ overflow: 'visible' }}>
          {this.renderPerformMaterial(materialPlanItem, this.batchRequirements[materialPlanItem.id])}
        </Table.Cell>
        <Table.Cell collapsing data-test-done={materialPlanItem.id}>
          {/*
          The calculation below should represent the total materials to do for the entire PR, not only the current batch
          That means that if you have to make 10 bikes each with 2 wheels, you have finished 2 bike and from the 3rd
          you have assembled 1 wheel, this indicator should show 5/20
           */}
          <h4>{`${humanReadable(this.batchCollected[materialPlanItem.id]['totalSum'], this.DECIMAL_ROUND_PRECISION)}/${requiredQuantityForWholeOrder}`}</h4>
        </Table.Cell>
      </Table.Row>
    )
  }

  state = { toggled: false }

  handleClick = (e) => {
    this.setState(prevState => ({
      toggled: !prevState.toggled
    }));
  }

  render() {
    const { materialPlan, step, generalErrors } = this.props;
    const hasItems = materialPlan.items.models.some(item => item.type === 'material' && (isUnknownWorkstation(item) || isMyWorkstation(step.workStation.code, item)))

    //Table for Material Plan Materials
    const tableHeader = (
      <Table.Row>
        <Table.HeaderCell>{t('formStepField.field.articleType.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.backflush.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.requiredForThisBatch.label')}</Table.HeaderCell>
        <Table.HeaderCell colSpan={0}></Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.issued.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.checklist.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.finished.label')}</Table.HeaderCell>
      </Table.Row>
    )

    return (
      <FullWidthTable padded basic='very'>
        { // if there are any linked items, render the header as well
          hasItems ?
            <Table.Header>{tableHeader}</Table.Header>
            : //else, display a message saying there are no linked tasks/materials
            <Table.Row>
              <Table.Cell colSpan='8'>
                {t('formStepField.field.materialPlan.noMaterials')}
              </Table.Cell>
            </Table.Row>
        }
        <tbody data-material-plan-table={'material'}>
          {materialPlan.items.models
            .filter(item => item.type === 'material' && item.isByproduct === false && (isUnknownWorkstation(item) || isMyWorkstation(step.workStation.code, item)))
            .sort((a, b) => this.compareStorageLocationCodes(a, b))
            .map(this.renderMaterialPlan)}
        </tbody>
        {generalErrors.length > 0 && (
          <ErrorLabel>
            {generalErrors.map(({ message }, i) => <div key={i}>{message}</div>)}
          </ErrorLabel>
        )}
      </FullWidthTable>
    )
  }
}

