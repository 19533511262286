import React, { Component } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { Batch } from 'store/Batch';
interface IsBlockedProps {
  batch: Batch;
}
export class BatchIconBlocked extends Component {
  render() {
    return <Label color="red" data-sentry-element="Label" data-sentry-component="BatchIconBlocked" data-sentry-source-file="IconBlocked.tsx">
                <Icon data-test-blocked-icon name="x" style={{
        scale: '1.4',
        textDecoration: 'none',
        margin: 'auto'
      }} data-sentry-element="Icon" data-sentry-source-file="IconBlocked.tsx" />
            </Label>;
  }
}
export class BatchIconAvailable extends Component {
  render() {
    return <Label color="green" data-sentry-element="Label" data-sentry-component="BatchIconAvailable" data-sentry-source-file="IconBlocked.tsx">
                <Icon data-test-available-icon name="check" style={{
        scale: '1.4',
        textDecoration: 'none',
        margin: 'auto'
      }} data-sentry-element="Icon" data-sentry-source-file="IconBlocked.tsx" />
            </Label>;
  }
}
export class BatchIconIsBlocked extends Component<IsBlockedProps> {
  render() {
    const {
      batch
    } = this.props;
    return batch.blocked ? <BatchIconBlocked /> : <BatchIconAvailable />;
  }
}