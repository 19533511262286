import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from './ArticleType'
import { AssemblyBomVersion } from './AssemblyBomVersion';
import Decimal from 'decimal.js';

export class AssemblyBomItem extends Model {
  static backendResourceName = 'assembly_bom_item'
  static omitFields = ['erpQuantityRemaining']

  @observable id = null
  @observable number = 1
  @observable quantity = Decimal(0)


  relations() {
    return {
      articleType: ArticleType,
      assemblyBomVersion: AssemblyBomVersion,
    }
  }
}

export class AssemblyBomItemStore extends Store {
  Model = AssemblyBomItem
  static backendResourceName = 'assembly_bom_item'
}
