import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { t } from '../../i18n';
import { getCurrentUser } from 'helpers/currentUser';
import { Message, Header } from 'semantic-ui-react';
import { Model } from 'store/Base';
import { HistoryStore } from 'store/History';
import styled from 'styled-components';
import { DATETIME_FORMAT, DATETIME_SEC_FORMAT, snakeToCamel } from 'helpers';
import moment from 'moment';
import { trim } from 'lodash';
import { Casts } from 'store/Base';
import Decimal from 'decimal.js';
import { humanReadable } from 'helpers/decimal'

const List = styled.li`
    list-style: none;
    padding-bottom: 1.5rem;
    border-left: 4px solid black;
    position: relative;
    padding-left: 15px;
    margin-left: 0px;
    &:last-child{
        border-left: 4px solid white;
        padding-bottom: 0;
    }
    &:before{
        content: '';
        width: 20px;
        height: 20px;
        border: 4px solid black;
        background: white;
        border-radius: 50%;
        position: absolute;
        left: -12px;
        top: 0px;
        font-size: 12px;
    }
`;

const HistoryItem = styled.div`
    top: -10px;
    font-size: 15px;
`;

const StyledHistory = styled.div`
    height: 100%;
`;

const UnorderedList = styled.ul`
    margin-top: 1rem;
    position: relative;
`;

const Name = styled.span`
    font-weight: bold;
`;
const Rest = styled.span`
    color: grey;
`;

const Values = styled.span`
    font-weight: normal;
`;

const Date = styled.span`
    color: grey;
    font-size: 14px;
`;

const StyledMessage = styled(Message)`
display: block!important;
`;

@observer
export default class HistoryFeed extends Component {
    static propTypes = {
        object: PropTypes.instanceOf(Model).isRequired,
        config: PropTypes.array.isRequired,
        history: PropTypes.instanceOf(HistoryStore).isRequired,
        reasonField: PropTypes.string,
    };

    @observable currentUser = getCurrentUser();
    @observable flatHistory = [];

    componentDidMount() {
        this._arrangeHistory();
    }

    componentDidUpdate(prevProps) {
        if (this.props.history !== prevProps.history) {
            this._arrangeHistory();
        }
    }

    _arrangeHistory() {
        const { history } = this.props;
        if (history !== null) {
            this.flatHistory = this._getFilteredHistory(history);
        }
    }

    _getFilteredHistory(history) {
        const { config, reasonField } = this.props;

        var flatHistory = [];
        var groupedHistory = history.groupedByDate();

        groupedHistory.forEach(item => {
            let reason = null
            if (reasonField){
                reason = trim(item.changes.models.find((change) => change.field===reasonField)?.after, '"')
            }
            item.changes.models.forEach(
                change => {
                    console.log(change)
                    var isTracked = config.some(conf => {
                        if (conf.tracked_field === change.field) {
                            var before = trim(change.before, '"');
                            var after = trim(change.after, '"');

                            if (conf.allow_empty === false) {
                                return before !== '' && before !== 'null' && after !== '' && after !== 'null';
                            }

                            return before !== after;
                        }

                        return false;
                    });

                if (isTracked) {
                    flatHistory.push({
                        item: item,
                        change: change,
                        reason: reason,
                    });
                }
            }
        )});
        return flatHistory;
    }

    _parse(field, value) {
        if (moment(trim(value, '"'), 'YYYY-MM-DDTHH:mm:ss.SSSSZ', true).isValid()) {
            return Casts.datetime.parse(field, trim(value, '"')).format(DATETIME_SEC_FORMAT);
        }

        if (Decimal.isDecimal(value) || value.includes('.')) {
            return humanReadable(value)
        }

        return value;
    }

    _getModelName() {
        const { object } = this.props;
        return snakeToCamel(object.constructor.backendResourceName.replace(/\//g, '_'));
    }

    _displayHistory = (historyItem, i) => {
        const { config } = this.props;

        var itemConfig = config.find(conf => conf.tracked_field === historyItem.change.field);

        var action = 'changed';
        var before = trim(historyItem.change.before, '"');
        var after = trim(historyItem.change.after, '"');
        var reason = historyItem.reason

        before = this._parse(historyItem.change.field, before);
        after = this._parse(historyItem.change.field, after);

        var afterDetailed = after;
        var fieldName = t(`${this._getModelName()}.field.${snakeToCamel(historyItem.change.field)}.label`).toLowerCase();

        if (before === '' || before === 'null') {
            action = 'added';
        } else if (after === '' || after === 'null') {
            action = 'removed';
            afterDetailed = before;
        }

        return (
            <React.Fragment key={i}>
                <List>
                    <HistoryItem data-test-history-item>
                        <Name>{historyItem.item.user.fullName} </Name>
                        <Rest>{action} {fieldName} </Rest>
                        <>
                            {itemConfig.show_as_title && (
                                <>
                                    {action !== 'added' && <>
                                        <Rest>from </Rest>
                                        <Values>'{before}' </Values>
                                        <Rest>to </Rest>
                                    </>
                                    }
                                    <Values>'{after}' </Values>
                                </>
                            )}
                        </>
                        <Date><b>{t('history.at')}</b> {historyItem.item.date.format(DATETIME_FORMAT)}</Date>
                        {reason && <>{t('history.dueTo')} '{reason}'</>}
                    </HistoryItem>
                    {!itemConfig.show_as_title && (
                        <>
                            <Message>{afterDetailed}</Message>
                        </>
                    )}
                </List>
            </React.Fragment>
        )
    }

    _renderCount() {
        if (this.flatHistory.length > 0) {
            return <span data-test-count>({this.flatHistory.length})</span>;
        }

        return null;
    }

    _renderContent() {
        if (this.flatHistory.length === 0) {
            return (
                <StyledMessage warning>
                    <p>{t('history.title.empty')}</p>
                </StyledMessage>
            )
        }

        return (
            <StyledHistory data-test-changes-list>
                <UnorderedList>
                    {this.flatHistory.length > 0 && this.flatHistory.map(this._displayHistory)}
                </UnorderedList>
            </StyledHistory>
        )
    }


    render() {
        return (
            <>
                <Header>
                    <React.Fragment>
                        {t('history.title.label')}
                        {' '}
                        {this._renderCount()}
                    </React.Fragment>
                </Header>
                {this._renderContent()}
            </>
        );
    }
}
