import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Modal, Button, Form, Message, Segment } from 'semantic-ui-react'
import { RightDivider, TargetTextInput, TargetRadioButtons, TargetSelect } from '@code-yellow/spider'
import styled from 'styled-components'

// components
import { SmallAvatar } from 'component/UserAvatar';
// end components

// stores
import { ProductionRequest, ProductionRequestStore } from '../store/ProductionRequest'
import { ArticleTypeStore } from '../store/ArticleType'
import { ProductionMessageStore, ProductionMessage } from 'store/ProductionMessage'
import { Operator } from 'store/Operator'
// end stores


// Container to contain a single message + avatar:
//  :O ['message']
//
// if message is of yourself, it should be on the right of your screen (like whatsapp)
const MessageContainer = styled.div`
    display: flex;
    justify-content: ${({ own }) => (own ? 'right' : 'left')};
    overflow: auto;
    overflow-wrap: anywhere;
    > .avatar {
        margin-top: 10px !important;
    }
`

// Contain the whole chatbox
const AllMessagesContainer = styled(Segment)`
    overflow: auto;
    max-height: 60vh;
    
    position: fixed;
`

@observer
export default class ProductionMessageModal extends Component {
    static propTypes = {
        productionRequest: PropTypes.instanceOf(ProductionRequest).isRequired,
        operator: PropTypes.instanceOf(Operator).isRequired,
        trigger: PropTypes.node.isRequired,
        categories: PropTypes.arrayOf(PropTypes.string),
    }

    static defaultProps = {
        categories: []
    }

    @observable open = false
    @observable subject = 'order'
    @observable productionRequest = null
    @observable articleType = null

    productionRequestStore = new ProductionRequestStore()
    articleTypeStore = new ArticleTypeStore()

    @observable messageStore = new ProductionMessageStore({
        relations: [
            'operator.user'
        ],
        params: {
            include_annotations: '*,operator.full_name',
        }
    })

    @observable message = new ProductionMessage({}, {
        relations: ['operator', 'productionRequest', 'articleType']
    })

    constructor(...args) {
        super(...args)
        this.onOpen = this.onOpen.bind(this)
        this.onClose = this.onClose.bind(this)
        this.onSend = this.onSend.bind(this)
    }

    onSend() {
        const { operator } = this.props

        // set operator
        this.message.setInput('operator', operator);
        // this.message.operator = operator.toJS()

        // set model based on subject
        if (this.subject === 'order') {
            this.message.setInput('productionRequest', this.productionRequestStore.models.find(pr => pr.id === this.productionRequest));
            this.message.setInput('articleType', null);
        } else if (this.subject === 'article') {
            this.message.setInput('productionRequest', null);
            this.message.setInput('articleType', this.articleTypeStore.models.find(at => at.id === this.articleType));
        }
        // send message
        this.message.save({
            onlyChanges: true,
            relations: [
                'operator',
                this.subject === 'order' ? 'productionRequest' : 'articleType',
            ]
        }).then(() => {
            this.messageStore.fetch()
            const category = this.message.category
            this.message = new ProductionMessage({}, { relations: ['operator', 'productionRequest', 'articleType'] })
            this.message.setInput('category', category)
        })
    }

    onOpen() {
        const { productionRequest } = this.props

        this.productionRequest = productionRequest.id
        this.articleType = productionRequest.articleType.id

        if (this.subject === 'article') {
            this.messageStore.params['.article_type'] = this.articleType
        } else {
            this.messageStore.params['.production_request'] = productionRequest.id
        }
        this.open = true
        this.messageStore.fetch()
    }

    onClose() {
        this.open = false
    }

    render() {
        const { trigger, categories, operator } = this.props

        return (
        <Modal data-test-production-message-modal closeIcon
            closeOnDimmerClick={false}
            trigger={trigger}
            open={this.open}
            onOpen={this.onOpen}
            onClose={this.onClose}
        >
            <Modal.Header>{t('productionMessage.modal.title')}</Modal.Header>
            <Modal.Content>
                <Form> {/* settings */}
                    <Form.Group>
                        <TargetRadioButtons width={10} data-test-select-subject-type
                            label={t('productionMessage.modal.subject.label')}
                            options={[
                                { text: t('productionMessage.modal.subject.article'), value: 'article' },
                                { text: t('productionMessage.modal.subject.order'), value: 'order' },
                            ]}
                            value={this.subject}
                            onChange={(value) => {
                                this.subject = value
                                if (this.subject === 'order' && this.productionRequest !== null) {
                                    this.messageStore.params['.production_request'] = this.productionRequest
                                    delete this.messageStore.params['.article_type']
                                    this.messageStore.fetch()
                                } else if (this.subject === 'article' && this.articleType !== null) {
                                    this.messageStore.params['.article_type'] = this.articleType
                                    delete this.messageStore.params['.production_request']
                                    this.messageStore.fetch()
                                }
                            }}
                        />
                        <TargetSelect remote width={10} data-test-select-subject
                            label={t(`productionMessage.modal.subject.${this.subject}`)}
                            store={this.subject === 'order' ? this.productionRequestStore : this.articleTypeStore}
                            value={this.subject === 'order' ? this.productionRequest : this.articleType}
                            toOption={target => ({
                                value: target.id,
                                text: <span>{target.getLabel()}</span>
                            })}
                            onChange={(value) => {
                                if (this.subject === 'order') {
                                    this.productionRequest = value
                                    this.messageStore.params['.production_request'] = value
                                    delete this.messageStore.params['.article_type']
                                } else {
                                    this.articleType = value
                                    this.messageStore.params['.article_type'] = value
                                    delete this.messageStore.params['.production_request']
                                }
                                this.messageStore.fetch()
                            }}
                        />
                        <TargetSelect width={10} clearable
                            label={t('productionMessage.field.category.label')}
                            target={this.message}
                            name='category'
                            options={categories.map((category) => ({
                                value: category,
                                text: category,
                            }))}
                            onChange={(value) => {
                                this.message.setInput('category', value)
                                this.messageStore.params['.category'] = value
                                this.messageStore.fetch()
                            }}
                        />
                    </Form.Group>
                </Form>
                <AllMessagesContainer>
                    {/* previous messages for this config */}
                    {this.messageStore.models.map((message) => {
                        // show messages. If own message, it shows up on the left
                        const ownMessage = message.operator.id === operator.id

                        return <MessageContainer own={ownMessage} data-test-message>
                            {!ownMessage && <SmallAvatar user={message.operator.user} />}
                            <Message>
                                <Message.Content>
                                <Message.Header>{message.operator.fullName}: {message.category}</Message.Header>
                                {message.message}
                                </Message.Content>
                            </Message>
                            {ownMessage && <SmallAvatar user={message.operator.user} />}
                        </MessageContainer>
                    })}
                    {this.messageStore.models.length <= 0 && t('productionMessage.modal.empty')}
                </AllMessagesContainer>
                {/* add message */}
                <Form>
                    <Form.Group>
                        <TargetTextInput noLabel width={15}
                            target={this.message}
                            name='message'
                        />
                        <Button primary icon="send" loading={false} onClick={this.onSend} data-test-send-button/>
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <RightDivider />
            </Modal.Actions>
        </Modal>
        )
    }
}


