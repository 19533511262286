import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Form, Modal, Icon } from 'semantic-ui-react'
import { RightDivider, TargetNumberInput } from '@code-yellow/spider'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import { modalColors } from 'styles'
import Decimal from 'decimal.js'

@observer
export default class QuantityModal extends Component {
  static propTypes = {
    initialQuantity: PropTypes.number,
    /**
     * When the inputted quantity is larger than `warningQuantity`, a warning is shown to the operator. The operator can
     * simply ignore this warning.
     *
     * @type {number}
     */
    warningQuantity: PropTypes.number,
    onSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }
  /**
   * Quantity Modal should do the following:
   * - Show a popup with prefilled quantity: `initialQuantity`
   * - IF selected value > `quantityWarning`, THEN show a warning and lose focus on field.
   *     user is then still able to fill it again, and proceed anyways
   */

  constructor(...args) {
    super(...args)

    this.onSubmit = this.onSubmit.bind(this)
    this.value = this.props.initialQuantity
    this.maybeShowQuantityWarning()
  }

  @observable value = 1
  @observable showWarning = false
  fieldNode = null

  onSubmit() {
    const { onSelect } = this.props
    if (this.value !== null) {
      onSelect(this.value)
    }
  }

  /**
   * Determines whether a quantity warning should be displayed based on the current value and configuration.
   *
   * This function evaluates if a warning should be shown when the entered or calculated
   * quantity exceeds the warning quantity threshold. The evaluation considers whether the
   * feature flag `allow_receipt_of_more_than_ordered` is enabled, and uses a decimal comparison
   * between the current value and the `warningQuantity` from the component's props.
   *
   * Updates the `showWarning` property with a boolean result indicating if the warning should be displayed.
   */
  maybeShowQuantityWarning = () => {
    const { warningQuantity } = this.props

    this.showWarning =
      !isFeatureFlagEnabled('allow_receipt_of_more_than_ordered') &&
      Decimal(this.value).gt(warningQuantity)
  }

  render() {
    const { onClose, warningQuantity } = this.props

    return (
      <Modal open closeIcon data-test-quantity-modal onClose={onClose} size="tiny" closeOnDimmerClick={false}>
        <Modal.Header style={{ backgroundColor:modalColors.greenYellow }}>{t('workStation.production.quantityModal.title')}</Modal.Header>
        <Modal.Content>
          {this.showWarning && (
            <div>
              <Icon style={{ color: '#EBBB12', marginRight: '0.5em' }} name={'warning sign'} />
              {this.showWarning && t('workStation.production.quantityModal.quantityWarning', { quantity: warningQuantity })}
            </div>
          )}
          <Form onSubmit={this.onSubmit}>
            <TargetNumberInput innerRef={(node) => (this.fieldNode = node)} autoFocus allowDecimal
              label={t('workStation.production.quantityModal.quantity')}
              value={this.value === null ? '' : this.value.toString()}
              onChange={(value) => {
                this.value = value === '' ? null : parseInt(value)
              }}
              afterChange={this.maybeShowQuantityWarning}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <RightDivider />
          <Button primary data-test-confirm-button
            icon="check"
            labelPosition="left"
            content={t('workStation.production.quantityModal.confirmButton')}
            onClick={this.onSubmit}
            disabled={this.value === null}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
