import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'semantic-ui-react'
import { observer } from 'mobx-react'


@observer
export class PlayPauseButton extends Component {
    static propTypes = {
        isInProgress: PropTypes.bool.isRequired,
        onPlay: PropTypes.func.isRequired,
        onPause: PropTypes.func.isRequired,
        buttonProps: PropTypes.object,
    }

    startStop() {
        const { isInProgress, onPlay, onPause } = this.props
        if (isInProgress) {
            onPause()
        } else {
            onPlay()
        }
    }

    render() {
        const { isInProgress, buttonProps } = this.props
        return (
            <ButtonGroup onClick={() => this.startStop()}>
                <Button icon='pause' {...buttonProps} negative={!isInProgress} data-test-pause/>
                <Button icon='play' {...buttonProps} positive={isInProgress} data-test-play/>
            </ButtonGroup>
        )
    }
}


